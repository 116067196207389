import React, {useEffect} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
// import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import RightPanelWrapper from '../../app/Components/RightPanelWrapper'
import ConfirmationDialog from '../../app/Components/ConfirmDialog'
import EventConfirmationDialog from '../../app/Components/EventConfirmDialog'
import AcceptReadDialog from '../../app/Components/AcceptReadDialog'
import SvjStore from '../../app/Stores/Svj'
import {ActivityDrawer, UpgradePlan} from '../partials'
import {IsFree} from './components/IsFree'
import DemoModal from '../../app/Components/Demo'
import ReactGA from 'react-ga4'

const MasterLayout: React.FC = ({children}) => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)

    const svjValue = localStorage.getItem('svj')

    if (svjValue) {
      // Odeslání hodnoty do Google Analytics jako událost
      ReactGA.event({
        category: 'User', // Kategorie události, můžete si ji upravit podle potřeby
        action: `Uzivatel z svj: ${svjValue}`, // Akce události, zde můžete zahrnout hodnotu jako součást řetězce
        label: 'svj', // Štítek události, můžete si ji upravit podle potřeby
        // Můžete také použít 'value' pole, pokud máte číselnou reprezentaci
        // value: Number(svjValue) || 0, // Pokud je hodnota číselná
      })
    }
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          {SvjStore.data && SvjStore.data.isFree ? <IsFree /> : null}
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>{children}</Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/*<RightToolbar/>*/}
      {/*<DefaultRightPanelPlace/>*/}
      <RightPanelWrapper />
      {/*<DrawerMessenger />*/}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/*<Main />*/}
      {/*<InviteUsers />*/}
      {/*<UpgradePlan />*/}
      {/* end:: Modals */}
      <ScrollTop />
      <ConfirmationDialog />
      <EventConfirmationDialog />
      <AcceptReadDialog />
      {process.env.REACT_APP_DEMO === 'true' ? <DemoModal /> : null}
    </PageDataProvider>
  )
}

export {MasterLayout}
