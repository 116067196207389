import {observer} from 'mobx-react'
import React from 'react'
export const InputLabel = observer((props: any) => {
  let labelClass = ['fs-6 fw-bold-custom form-label']

  const alignTop = props.alignTop ?? false
  let colClass = 'col-4'
  if (props.colSize) {
    colClass = props.colSize
  }
  if (props.readOnly) {
    return <label className={`${colClass} fw-bold-custom text-muted`}>{props.children}</label>
  }
  return (
    <label
      onClick={props.onClick}
      className={`d-flex ${alignTop ? 'align-items-top' : 'align-items-center'}  ${
        props.inlineLabel ? `${colClass} col-form-label fw-bold-custom fs-6` : 'mb-2 '
      }`}
    >
      <label
        className={`${labelClass.join(' ')} ${props.className ?? ''} ${
          props.required ? 'required' : ''
        }`}
      >
        {props.children}
      </label>
    </label>
  )
})
