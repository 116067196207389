import {observer} from 'mobx-react'
import React, {FC} from 'react'
import clsx from 'clsx'
import {InputLabel} from '../InputLabel'
import {isEmpty, isNumber} from 'lodash'
import {PasswordStrengthMeter} from '../InputColumn/PasswordStrengthMeter'

const InputInline: FC<{
  id?: string
  value: any
  formik?: any
  className?: string
  required?: boolean
  name: string
  placeholder?: string
  label?: string
  type?: string
  changeValue: any
  readOnly?: boolean
  onBlur?: any
  description?: string
  innerRef?: any
  groupAfterText?: string
  disabled?: boolean
  simpleTextAfter?: string
  labelSize?: string
  inputAreaSize?: string
  isInline?: boolean
  inputSize?: string
  classLabel?: string
  inputReadOnlyClass?: string
  labelAlignTop?: boolean
  readOnlyFormat?: any
  hide?: boolean
  inputBold?: boolean
  min?: number
  max?: number
  groupAfterClass?: string
  disableHeight?: boolean
  passwordBar?: boolean
  disableMarginReadOnly?: boolean
}> = observer((props: any) => {
  let labelAlignTop = props.labelAlignTop ?? false
  let inputReadOnlyClass = props.inputReadOnlyClass ?? ''
  let labelSize = props.labelSize ?? 'col-4'
  let inputAreaSize = props.inputAreaSize ?? 'col-8'
  let inputSize = props.inputSize ?? 'col-122'
  let labelClass = []
  let inputClass = ['form-control', 'form-control-lg', 'form-control-solid']
  let disabled = props.disabled ?? false
  let inputBold = props.inputBold ?? false
  let min = props.min ?? undefined
  let max = props.max ?? undefined
  let groupAfterClass = props.groupAfterClass ?? ''
  let disableHeight = props.disableHeight ?? true
  let passwordBar = props.passwordBar ?? false
  let disableMarginReadOnly = props.disableMarginReadOnly ?? false
  if (props.hide !== undefined && props.hide !== null && props.hide === true) {
    return null
  }

  if (props.required) {
    labelClass.push('required')
    inputClass.push('required')
  }

  if (props.classLabel) {
    labelClass.push(props.classLabel)
  }

  if (props.className) {
    inputClass.push(props.className)
  }

  if (props.formik) {
    inputClass.push(
      clsx(
        '',
        {'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name]},
        {'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name]}
      )
    )
  }

  const getInput = () => {
    return (
      <input
        ref={props.innerRef ?? null}
        id={props.id ?? props.name}
        disabled={disabled}
        type={props.type ?? 'text'}
        className={inputClass.join(' ')}
        placeholder={props.placeholder ?? ''}
        name={props.name}
        min={min}
        max={max}
        defaultValue={props.value}
        onChange={props.changeValue}
        onBlur={(changes) => {
          if (props.onBlur) {
            props.onBlur(changes)
          }
        }}
      />
    )
  }

  if (props.readOnly) {
    if (props.value === undefined || props.value === null || props.value === '') {
      return null
    }

    return (
      <div
        className={`row ${!disableMarginReadOnly ? 'mb-5' : null} ${
          !disableHeight ? 'h-40px' : ''
        }`}
      >
        {props.label ? (
          <InputLabel
            colSize={labelSize}
            className={`${labelClass.join(' ')} fw-semibold text-muted`}
            readOnly={props.readOnly}
            alignTop={labelAlignTop}
          >
            {props.label}
          </InputLabel>
        ) : null}
        <div className={inputAreaSize}>
          <div className={`fv-row fv-plugins-icon-container`}>
            {props.readOnlyFormat !== undefined && props.readOnlyFormat !== null ? (
              props.readOnlyFormat(props.value)
            ) : (
              <span className={`${inputBold ? 'fw-bold-custom' : ''} fs-6 ${inputReadOnlyClass}`}>
                {!isEmpty(props.value) || isNumber(props.value) ? props.value : '-'}
              </span>
            )}
          </div>
        </div>
        {/*<div className={inputAreaSize}>*/}
        {/*  <div className={'row'}>*/}
        {/*    <div className={`${inputSize} fv-row fv-plugins-icon-container`}>*/}
        {/*      <span className={`fw-semibold text-gray-800 fs-6 ${inputReadOnlyClass}`}>*/}
        {/*        {props.value ?? '-'}*/}
        {/*      </span>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    )
  }

  return (
    <>
      <div className={'row mb-6'}>
        {props.label ? (
          <InputLabel
            className={labelClass.join(' ')}
            colSize={labelSize}
            required={props.required}
            inlineLabel={true}
            alignTop={labelAlignTop}
          >
            {props.label}
          </InputLabel>
        ) : null}
        <div className={inputAreaSize}>
          <div className={'row'}>
            <div className={`${inputSize} fv-row fv-plugins-icon-container`}>
              {props.groupAfterText ? (
                <div className='input-group mb-3 w-100'>
                  {getInput()}
                  <span
                    className={`input-group-text ${groupAfterClass} border-0`}
                    id='basic-addon2'
                  >
                    {props.groupAfterText}
                  </span>
                </div>
              ) : (
                <>
                  {props.simpleTextAfter ? (
                    <div className='d-inline-flex align-items-center w-100'>
                      {getInput()} <span className='ps-2'>{props.simpleTextAfter}</span>
                    </div>
                  ) : (
                    getInput()
                  )}
                </>
              )}
            </div>
            {passwordBar ? (
              <div className={'mt-2'}>
                <PasswordStrengthMeter password={props.value || ''} />
              </div>
            ) : null}
            {props.formik && props.formik.errors[props.name] ? (
              <div className='fv-plugins-message-container invalid-feedback'>
                <div className='fv-help-block'> {props.formik.errors[props.name]}</div>
              </div>
            ) : null}
            {props.description ? <div className={'form-text'}>{props.description}</div> : null}
          </div>
        </div>
      </div>
    </>
  )

  // return (
  //   <>
  //     {props.label ? <InputLabel className={labelClass.join(' ')}>{props.label}</InputLabel> : null}
  //
  //     {props.groupAfterText ? (
  //       <div className='input-group mb-3'>
  //         {getInput()}
  //         <span className='input-group-text' id='basic-addon2'>
  //           {props.groupAfterText}
  //         </span>
  //       </div>
  //     ) : (
  //       <>
  //         {props.simpleTextAfter ? (
  //           <div className='d-inline-flex align-items-center '>
  //             {getInput()} <span className='ps-2'>{props.simpleTextAfter}</span>
  //           </div>
  //         ) : (
  //           getInput()
  //         )}
  //       </>
  //     )}
  //
  //     {props.formik && props.formik.touched[props.name] && props.formik.errors[props.name] && (
  //       <div className='fv-plugins-message-container invalid-feedback'>
  //         <div className='fv-help-block'> {props.formik.errors[props.name]}</div>
  //       </div>
  //     )}
  //     {props.description ? <div className={'form-text'}>{props.description}</div> : null}
  //   </>
  // )
})

export default InputInline
