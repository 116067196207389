export const checkRoutesParams = (
  paramName: string,
  defaultValue: string | null,
  history: any,
  location: any
) => {
  const params = getAllRoutesParams(location)

  if (!params.has(paramName) && defaultValue) {
    params.append(paramName, defaultValue)
    history.replace({search: params.toString()})
    return defaultValue
  }
  return params.get(paramName) || defaultValue
}

export const setRoutesParam = (paramName: string, value: string, history: any, location: any) => {
  const params = getAllRoutesParams(location)
  params.set(paramName, value)
  history.replace({search: params.toString()})
}

export const setRoutesParamArray = (valueArray: any[], history: any, location: any) => {
  const params = getAllRoutesParams(location)
  for (const value of valueArray) {
    params.set(value.name, value.value)
  }
  history.replace({search: params.toString()})
}

export const getRoutesParam = (paramName: string, history: any, location: any) => {
  const params = getAllRoutesParams(location)
  return params.get(paramName)
}

export const getAllRoutesParams = (location: any) => {
  return new URLSearchParams(location.search)
}

export const getRouterParamsString = (location: any) => {
  return location.search
}

export const navigateToWithParams = (history: any, pathname: any, backUrlParams: any = {}) => {
  history.push({
    pathname: pathname,
    state: {search: backUrlParams},
  })
}
