import ReactGA from 'react-ga4'

export const initGA = () => {
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID
  if (trackingId) {
    ReactGA.initialize(trackingId)
  } else {
    console.error('Google Analytics tracking ID is not set')
  }
}

export const logPageView = () => {
  ReactGA.set({page: window.location.pathname})
  ReactGA.pageview(window.location.pathname)
}
