import {observer} from 'mobx-react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'
import {getRandomKey} from '../../Helpers/Default'
import React from 'react'
export const TooltipDefault = observer((props: any) => {
  let position = 'top'

  if (props.position) {
    position = props.position
  }

  if (!props.tooltip) {
    return props.children
  }

  return (
    <OverlayTrigger
      key={getRandomKey()}
      // @ts-ignore
      placement={position}
      overlay={<Tooltip id={getRandomKey()}>{props.tooltip}</Tooltip>}
    >
      {props.children}
    </OverlayTrigger>
  )
})
