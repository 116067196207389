import {observer} from 'mobx-react'
import React, {FC} from 'react'
import clsx from 'clsx'
import {Switch} from '@mui/material'
import moment from 'moment/moment'
import {DEFAULT_DATE_TIME_FORMAT} from '../../Constants/Defatul'
import {InputLabel} from '../InputLabel'

const SwitchInline: FC<{
  formik?: any
  className?: string
  required?: boolean
  name: string
  placeholder?: string
  label?: any
  type?: string
  changeValue: any
  value: any
  readOnly: boolean
  texts?: any
  description?: string
  elementClassName?: string
  columnLabel: boolean
  labelSize?: string
  inputAreaSize?: string
  inputSize?: string
}> = observer((props: any) => {
  let labelClass = []
  let labelSize = props.labelSize ?? 'col-4'
  let inputAreaSize = props.inputAreaSize ?? 'col-8'
  let inputSize = props.inputSize ?? 'col-12'
  let elementClassName = ['mb-5']
  let inputClass = [
    'form-check-input',
    // 'form-control-solid',
  ]

  if (props.required) {
    labelClass.push('required')
    inputClass.push('required')
  }

  if (props.className) {
    inputClass.push(props.className)
  }

  if (props.labelClassName) {
    labelClass.push(props.labelClassName)
  }

  if (props.elementClassName) {
    elementClassName.push(props.elementClassName)
  }

  if (props.formik) {
    inputClass.push(
      clsx(
        '',
        {'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name]},
        {'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name]}
      )
    )
  }

  const getSwitch = (showLabels = true) => {
    return (
      <>
        <Switch
          name={props.name}
          checked={props.value}
          disabled={props.readOnly}
          onChange={(e: any) => {
            props.changeValue({
              target: {
                value: e.target.checked,
                name: props.name,
              },
            })
          }}
          value={props.name}
          inputProps={{'aria-label': 'secondary checkbox'}}
          // className={inputClass.join(' ')}
        />
        {showLabels ? (
          <label className='form-check-label'>
            {props.texts ? <>{props.value ? props.texts.select : props.texts.unselect}</> : null}
            {props.description ? props.description : null}
          </label>
        ) : null}
      </>
    )
  }

  if (props.readOnly) {
    return (
      <div className={'row mb-6'}>
        <InputLabel
          colSize={labelSize}
          className={`${labelClass.join(' ')} fw-semibold text-muted`}
          readOnly={props.readOnly}
        >
          {props.label}
        </InputLabel>
        <div className={inputAreaSize}>
          <div className={'row'}>
            <div className={`${inputSize} fv-row fv-plugins-icon-container`}>
              <span className='fw-semibold text-gray-800 fs-6'>
                {props.texts ? (
                  <>{props.value ? props.texts.select : props.texts.unselect}</>
                ) : null}
                {props.description ? props.description : null}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={'row mb-6'}>
        {props.label ? (
          <InputLabel
            className={labelClass.join(' ')}
            colSize={labelSize}
            required={props.required}
            inlineLabel={true}
          >
            {props.label}
          </InputLabel>
        ) : null}
        <div className={inputAreaSize}>
          <div className={'row'}>
            <div className={`${inputSize} fv-row fv-plugins-icon-container`}>
              <div className='switch-area'>
                {props.label && !props.columnLabel ? (
                  <InputLabel className={`${labelClass.join(' ')} me-2`}>{props.label}</InputLabel>
                ) : null}
                {getSwitch()}
              </div>
            </div>
            {props.formik && props.formik.touched[props.name] && props.formik.errors[props.name] && (
              <div className={`col-12 fv-row fv-plugins-icon-container`}>
                <div className='fv-plugins-message-container invalid-feedback'>
                  <div className='fv-help-block'> {props.formik.errors[props.name]}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
})

export default SwitchInline
