import moment from 'moment/moment'
import {FULL_DAY} from '../../Constants/Messages'
import {FormattedMessage} from 'react-intl'
import {DEFAULT_DATE_FORMAT} from '../../Constants/Defatul'
import {TooltipDefault} from '../../Components/Tooltip'

export function renderPeriod(startDate: any, endDate: any, allDay = false, longTerm = false) {
  let startYear = moment(startDate).format('YY')
  let endYear = moment(endDate).format('YY')
  let endYearFull = moment(endDate).format('YYYY')
  let startMonth = moment(startDate).format('MM')
  let startDay = moment(startDate).format('DD')
  let endMonth = moment(endDate).format('MM')
  let endDay = moment(endDate).format('DD')

  if (moment(startDate).get('hour') === 23) {
    startDay = moment(startDate).add(1, 'day').format('DD')
  }

  let time,
    day = ''

  if (allDay) {
    time = <FormattedMessage id={FULL_DAY} />
  } else {
    time = moment(startDate).format('HH:mm') + ' - ' + moment(endDate).format('HH:mm')
  }

  if (startMonth === endMonth && startYear === endYear) {
    if (startDay === endDay) {
      day = moment(startDate).format(DEFAULT_DATE_FORMAT)
    } else {
      day = startDay + '. - ' + endDay + '. ' + endMonth + '. ' + endYearFull
    }
  } else if (startYear === endYear) {
    day = startDay + '. ' + startMonth + '. - ' + endDay + '. ' + endMonth + '. ' + endYearFull
  }

  if (startYear !== endYear) {
    day =
      startDay +
      '. ' +
      startMonth +
      '. ' +
      startYear +
      ' - ' +
      endDay +
      '. ' +
      endMonth +
      '. ' +
      endYear
  }

  if (endDate === null) {
    day = moment(startDate).format(DEFAULT_DATE_FORMAT)
  }

  if (longTerm) {
    day = 'nemá termín publikace'
  }

  if (day.indexOf('Invalid') > -1) {
    day = '-'
  }

  return {
    date: day,
    time: time,
  }
}

export function getLabelTranslation(type: string) {
  let translation = ''

  switch (type) {
    case 'important':
      translation = 'MESSAGES.TYPE.IMPORTANT'
      break
    case 'general':
      translation = 'MESSAGES.TYPE.GENERAL'
      break
    case 'internal':
      translation = 'MESSAGES.TYPE.INTERNAL'
      break
    case 'disorder':
      translation = 'MESSAGES.TYPE.DISORDER'
      break
    case 'repair':
      translation = 'MESSAGES.TYPE.REPAIR'
      break
    case 'maintenance':
      translation = 'MESSAGES.TYPE.MAINTENANCE'
      break
    case 'complain':
      translation = 'MESSAGES.TYPE.COMPLAIN'
      break
  }
  return translation
}

export const getMessageIcons = (message: any) => {
  const icons = []

  if (message.isPinned) {
    icons.push(getPinnedIcon())
  }

  if (message.acceptRead) {
    icons.push(getReadIcon())
  }

  if (message.attachments.length > 0 || message.files.length > 0) {
    icons.push(getAttachmentIcon())
  }

  return icons
}

export const getPinnedIcon = () => {
  return (
    <span>
      <TooltipDefault tooltip={'Zpráva je připnuta'} position={'top'}>
        <i className='fa fa-light fa-thumbtack ps-2'></i>
      </TooltipDefault>
    </span>
  )
}

export const getReadIcon = () => {
  return (
    <span>
      <TooltipDefault tooltip={'Zpráva obsahuje potvrzení o přečtení'} position={'top'}>
        <i className='fa fa-light fa-glasses ps-2'></i>
      </TooltipDefault>
    </span>
  )
}

const getAttachmentIcon = () => {
  return (
    <span>
      <TooltipDefault tooltip={'Zpráva obsahuje přílohy'} position={'top'}>
        <i className='fa fa-light fa-paperclip ps-2'></i>
      </TooltipDefault>
    </span>
  )
}
