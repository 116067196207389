import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import * as React from 'react';
import {useEffect} from "react";
import Translation from "../Translation";


export default function ConfirmationDialog() {
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [submitFunction, setSubmitFunction] = React.useState(null);
    const [cancelFunction, setCancelFunction] = React.useState(null);
    const [buttonsSettings, setButtonSettings] = React.useState({
        cancel: {
            text: "BASE.NO",
        },
        confirm: {
            text: "BASE.YES",
        }
    });

    useEffect(() => {
        const handleClick = (etc: any) => {
            setTitle(etc.detail.title || "BASE.CONFIRM.TITLE")
            setMessage(etc.detail.message || "BASE.CONFIRM.QUESTION.CREATE")
            setSubmitFunction(() => etc.detail.onConfirm)
            setCancelFunction(() => etc.detail.onCancel)
            setOpen(!open)
            return true;
        }

        document.addEventListener('confirmDialog', handleClick)

        // 👇️ remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('confirmDialog', handleClick);
        };
    }, []);

    return (<Dialog
        open={open}
        onClose={() => {
            setOpen(!open)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {(title)?
                <Translation text={title}/>
            : null}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={()=> {
                if (cancelFunction !== null) {
                    // @ts-ignore
                    cancelFunction()
                }
                setOpen(!open)
            }}><Translation text={buttonsSettings.cancel.text} /></Button>
            <Button onClick={()=> {
                if (submitFunction !== null) {
                    // @ts-ignore
                    submitFunction()
                }
                setOpen(!open)
            }} autoFocus>
                <Translation text={buttonsSettings.confirm.text} />
            </Button>
        </DialogActions>
    </Dialog>);
}