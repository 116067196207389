import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import {SearchComponent} from '../../../assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {observer} from 'mobx-react'
import RoomStore from '../../../../app/Stores/Room'
import UserStore from '../../../../app/Stores/User'
import EventStore from '../../../../app/Stores/Event'
import FolderStore from '../../../../app/Stores/Folder'
import MessagesStore from '../../../../app/Stores/Messages'
import VoteStore from '../../../../app/Stores/Vote'
import DocumentStore from '../../../../app/Stores/Document'
import ReportStore from '../../../../app/Stores/Reports'
import SurveyStore from '../../../../app/Stores/Survey'
import MeetingOfOwnerStore from '../../../../app/Stores/MeetingOfOwner'
import GalleryStore from '../../../../app/Stores/Gallery'
import ContactStore from '../../../../app/Stores/Contact'
import debounce from 'lodash.debounce'

import {getRandomKey, getTranslation} from '../../../../app/Helpers/Default'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {
  getFullNameSimple,
  getRoleName,
  getShortcutInCircle,
  renderFullNameWithIcon,
} from '../../../../app/Helpers/User'
import {renderPeriod} from '../../../../app/Helpers/Messages'
import moment from 'moment/moment'
import {DEFAULT_DATE_FORMAT, DEFAULT_DB_DATE_FORMAT} from '../../../../app/Constants/Defatul'
import {getDocumentIcon} from '../../../../app/Helpers/Document'
import {getContactName} from '../../../../app/Helpers/Contact'
const Search = observer(() => {
  const intl = useIntl()
  const [key, setKey] = useState<string>('')
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const elementInputSearch = useRef<HTMLInputElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)
  const [rooms, setRooms] = useState<any[]>([])
  const [users, setUsers] = useState<any[]>([])
  const [events, setEvents] = useState<any[]>([])
  const [folders, setFolders] = useState<any[]>([])
  const [files, setFiles] = useState<any[]>([])
  const [foundElements, setFoundElements] = useState<number>(0)
  const [messages, setMessages] = useState<any[]>([])
  const [votes, setVotes] = useState<any[]>([])
  const [reports, setReports] = useState<any[]>([])
  const [surveys, setSurveys] = useState<any[]>([])
  const [meetings, setMeetings] = useState<any[]>([])
  const [galleries, setGalleries] = useState<any[]>([])
  const [contacts, setContacts] = useState<any[]>([])
  const [searchTerm, setSearchTerm] = useState('')

  const debouncedSearch = useCallback(
    debounce(async (searchValue: any) => {
      if (searchValue.length > 0) {
        try {
          const searchPromises = [
            RoomStore.search(searchValue),
            UserStore.search(searchValue),
            EventStore.search(searchValue),
            FolderStore.search(searchValue),
            MessagesStore.search(searchValue),
            VoteStore.search(searchValue),
            DocumentStore.search(searchValue),
            ReportStore.search(searchValue),
            SurveyStore.search(searchValue),
            MeetingOfOwnerStore.search(searchValue),
            GalleryStore.search(searchValue),
            ContactStore.search(searchValue),
          ]

          const [
            roomsValue,
            usersValue,
            eventsValue,
            foldersValue,
            messagesValue,
            votesValue,
            filesValue,
            reportsValue,
            surveysValue,
            meetingsValue,
            galleriesValue,
            contactsValue,
          ] = await Promise.all(searchPromises)

          setRooms(roomsValue)
          setUsers(usersValue)
          setEvents(eventsValue)
          setFolders(foldersValue)
          setMessages(messagesValue)
          setVotes(votesValue)
          setFiles(filesValue)
          setReports(reportsValue)
          setSurveys(surveysValue)
          setMeetings(meetingsValue)
          setGalleries(galleriesValue)
          setContacts(contactsValue)
          setKey(getRandomKey())

          // const totalFound =
          //   (Array.isArray(roomsValue) ? roomsValue.length : 0) +
          //   (Array.isArray(usersValue) ? usersValue.length : 0) +
          //   (Array.isArray(eventsValue) ? eventsValue.length : 0) +
          //   (Array.isArray(foldersValue) ? foldersValue.length : 0) +
          //   (Array.isArray(messagesValue) ? messagesValue.length : 0) +
          //   (Array.isArray(votesValue) ? votesValue.length : 0) +
          //   (Array.isArray(filesValue) ? filesValue.length : 0) +
          //   (Array.isArray(reportsValue) ? reportsValue.length : 0) +
          //   (Array.isArray(surveysValue) ? surveysValue.length : 0) +
          //   (Array.isArray(meetingsValue) ? meetingsValue.length : 0) +
          //   (Array.isArray(galleriesValue) ? galleriesValue.length : 0) +
          //   (Array.isArray(contactsValue) ? contactsValue.length : 0)
          // setFoundElements(totalFound)
          //
          // if (totalFound === 0) {
          //   emptyElement.current?.classList.remove('d-none')
          //   resultsElement.current?.classList.add('d-none')
          // }
        } catch (error) {
          console.error('Error during search:', error)
          // Zde můžete zpracovat chyby, například zobrazit uživatelské upozornění
        }
      } else {
        clearArrays()
      }
    }, 500),
    []
  )

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  const renderItems = () => {
    const html: any[] = []
    if (rooms && rooms.length > 0) {
      html.push(
        <div>
          <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
            {getTranslation('MENU.ROOMS', intl)}
          </h3>

          {rooms.map((room) => {
            return (
              <Link
                className='d-flex text-dark text-hover-primary align-items-center mb-5'
                to={'/jednotky/' + room.id}
                onClick={() => {}}
              >
                <div className='symbol symbol-40px me-4'>
                  <KTSVG
                    path={'/media/icons/duotune/general/gen025.svg'}
                    className='svg-icon-2'
                    svgClassName={'mh-60px'}
                  />
                </div>

                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>{room.number}</span>
                  {room.entrance ? (
                    <span className='fs-7 fw-bold text-muted'>{room.entrance?.name}</span>
                  ) : null}
                </div>
              </Link>
            )
          })}
        </div>
      )
    }

    if (users && users.length > 0) {
      html.push(
        <div>
          <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
            {getTranslation('MENU.USERS', intl)}
          </h3>

          {users.map((user) => {
            return (
              <Link
                className='d-flex text-dark text-hover-primary align-items-center mb-5 cursor-pointer'
                to={'/sousede/' + user.id}
                onClick={() => {}}
              >
                <div className='symbol symbol-30px me-5'>{getShortcutInCircle(user, false)}</div>

                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>{getFullNameSimple(user)}</span>
                  {user.role && user.role.title ? (
                    <div>
                      <span className={'fs-8 text-muted'}>{getRoleName(user)}</span>
                    </div>
                  ) : null}
                </div>
              </Link>
            )
          })}
        </div>
      )
    }

    if (events && events.length > 0) {
      html.push(
        <div>
          <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
            {getTranslation('MENU.CALENDAR', intl)}
          </h3>

          {events.map((event) => {
            return (
              <Link
                className='d-flex text-dark text-hover-primary align-items-center mb-5 cursor-pointer'
                to={'/kalendar-udalosti/' + event.id}
                onClick={() => {}}
              >
                <div className='symbol symbol-30px me-5'>
                  <KTSVG path={'/media/icons/duotune/general/gen014.svg'} className='svg-icon-2' />
                </div>

                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>{event.title}</span>

                  <div>
                    <span className={'fs-8 text-muted'}>
                      {event.calendarId
                        ? event.calendar.name
                        : getTranslation('CALENDAR.FORM.CALENDAR.NAME.MAIN', intl)}
                    </span>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      )
    }

    if (votes && votes.length > 0) {
      html.push(
        <div>
          <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
            {getTranslation('MENU.VOTES', intl)}
          </h3>

          {votes.map((event) => {
            return (
              <Link
                className='d-flex text-dark text-hover-primary align-items-center mb-5 cursor-pointer'
                to={'/hlasovani/' + event.id}
                onClick={() => {}}
              >
                <div className='symbol symbol-30px me-5'>
                  <KTSVG path={'/media/icons/duotune/graphs/gra010.svg'} className='svg-icon-2' />
                </div>

                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>{event.name}</span>
                </div>
              </Link>
            )
          })}
        </div>
      )
    }

    if (messages && messages.length > 0) {
      html.push(
        <div>
          <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
            {getTranslation('MENU.MESSAGES', intl)}
          </h3>

          {messages.map((message) => {
            let {date} = renderPeriod(
              message.dateFrom,
              moment(message.dateTo, DEFAULT_DB_DATE_FORMAT).format(DEFAULT_DB_DATE_FORMAT),
              false,
              message.long_term
            )

            if (message.isPinned || message.concept) {
              if (message.dateFrom) {
                date = moment(message.dateFrom, DEFAULT_DB_DATE_FORMAT).format(DEFAULT_DATE_FORMAT)
              } else {
                date = '-'
              }
            }
            return (
              <Link
                className='d-flex text-dark text-hover-primary align-items-center mb-5 cursor-pointer'
                to={'/zpravy/' + message.id}
                onClick={() => {}}
              >
                <div className='symbol symbol-30px me-5'>
                  <KTSVG
                    path={'/media/icons/duotune/communication/com012.svg'}
                    className='svg-icon-2'
                  />
                </div>

                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>{message.title}</span>
                  {date !== '-' ? (
                    <div>
                      <span className={'fs-8 text-muted'}>{date}</span>
                    </div>
                  ) : null}
                </div>
              </Link>
            )
          })}
        </div>
      )
    }

    if (folders && folders.length > 0) {
      html.push(
        <div>
          <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
            {getTranslation('FOLDERS', intl)}
          </h3>

          {folders.map((folder) => {
            return (
              <Link
                className='d-flex text-dark text-hover-primary align-items-center mb-5 cursor-pointer'
                to={'/dokumenty?breadcrumb=root,' + folder.id}
                onClick={() => {}}
              >
                <div className='symbol symbol-30px me-5 menu-icon menu-link'>
                  <KTSVG path={'/media/icons/duotune/files/fil012.svg'} className='svg-icon-2' />
                </div>

                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>{folder.name}</span>

                  <div></div>
                </div>
              </Link>
            )
          })}
        </div>
      )
    }

    if (files && files.length > 0) {
      html.push(
        <div>
          <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
            {getTranslation('FILES', intl)}
          </h3>

          {files.map((file) => {
            return (
              <Link
                className='d-flex text-dark text-hover-primary align-items-center mb-5 cursor-pointer'
                to={'/dokumenty/' + file.id}
                onClick={() => {}}
              >
                <div className='symbol symbol-30px me-5 menu-icon menu-link'>
                  {getDocumentIcon(file, file.type)}
                </div>

                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>{file.name}</span>

                  <div></div>
                </div>
              </Link>
            )
          })}
        </div>
      )
    }

    if (reports && reports.length > 0) {
      html.push(
        <div>
          <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
            {getTranslation('MENU.REPORTS', intl)}
          </h3>

          {reports.map((report) => {
            return (
              <Link
                className='d-flex text-dark text-hover-primary align-items-center mb-5 cursor-pointer'
                to={'/hlaseni/' + report.id}
                onClick={() => {}}
              >
                <div className='symbol symbol-30px me-5 menu-icon menu-link'>
                  <KTSVG
                    path={'/media/icons/duotune/communication/com004.svg'}
                    className='svg-icon-2'
                  />
                </div>

                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>{report.title}</span>

                  <div></div>
                </div>
              </Link>
            )
          })}
        </div>
      )
    }

    if (surveys && surveys.length > 0) {
      html.push(
        <div>
          <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
            {getTranslation('MENU.SURVEY', intl)}
          </h3>

          {surveys.map((survey) => {
            return (
              <Link
                className='d-flex text-dark text-hover-primary align-items-center mb-5 cursor-pointer'
                to={'/ankety/' + survey.id}
                onClick={() => {}}
              >
                <div className='symbol symbol-30px me-5 menu-icon menu-link'>
                  <KTSVG path={'/media/icons/duotune/graphs/gra006.svg'} className='svg-icon-2' />
                </div>

                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>{survey.name}</span>

                  <div></div>
                </div>
              </Link>
            )
          })}
        </div>
      )
    }

    if (meetings && meetings.length > 0) {
      html.push(
        <div>
          <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
            {getTranslation('MENU.MEETING_OF_OWNERS', intl)}
          </h3>

          {meetings.map((meeting) => {
            return (
              <Link
                className='d-flex text-dark text-hover-primary align-items-center mb-5 cursor-pointer'
                to={'/shromazdeni-vlastniku/' + meeting.id}
                onClick={() => {}}
              >
                <div className='symbol symbol-30px me-5 menu-icon menu-link'>
                  <KTSVG
                    path={'/media/icons/duotune/communication/com014.svg'}
                    className='svg-icon-2'
                  />
                </div>

                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>{meeting.name}</span>

                  <div></div>
                </div>
              </Link>
            )
          })}
        </div>
      )
    }

    if (galleries && galleries.length > 0) {
      html.push(
        <div>
          <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
            {getTranslation('MENU.GALLERY', intl)}
          </h3>

          {galleries.map((gallery) => {
            return (
              <Link
                className='d-flex text-dark text-hover-primary align-items-center mb-5 cursor-pointer'
                to={'/fotogalerie/' + gallery.id}
                onClick={() => {}}
              >
                <div className='symbol symbol-30px me-5 menu-icon menu-link'>
                  <KTSVG path={'/media/icons/duotune/general/gen006.svg'} className='svg-icon-2' />
                </div>

                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>{gallery.title}</span>

                  <div></div>
                </div>
              </Link>
            )
          })}
        </div>
      )
    }

    if (contacts && contacts.length > 0) {
      html.push(
        <div>
          <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
            {getTranslation('MENU.CONTACTS_BOOK', intl)}
          </h3>

          {contacts.map((contact) => {
            return (
              <Link
                className='d-flex text-dark text-hover-primary align-items-center mb-5 cursor-pointer'
                to={'/adresar-kontaktu/' + contact.id}
                onClick={() => {}}
              >
                {/*<div className='symbol symbol-30px me-5 menu-icon menu-link'>*/}
                {/*<KTSVG path={'/media/icons/duotune/files/fil012.svg'} className='svg-icon-2' />*/}
                {/*  <img*/}
                {/*    src={toAbsoluteUrl('/media/icons/duotune/files/fil012.svg')}*/}
                {/*    alt={contact.name}*/}
                {/*  />*/}
                {/*</div>*/}

                <div className='d-flex flex-column justify-content-start fw-bold'>
                  <span className='fs-6 fw-bold'>{getContactName(contact)}</span>

                  <div></div>
                </div>
              </Link>
            )
          })}
        </div>
      )
    }

    return <div className='scroll-y mh-200px mh-lg-325px search-items'>{html}</div>
  }
  const process = (search: SearchComponent) => {
    setTimeout(function () {
      const number = Math.floor(Math.random() * 6) + 1

      // Hide empty message
      // emptyElement.current!.classList.add('d-none')
      // Hide recently viewed
      // suggestionsElement.current!.classList.add('d-none')

      // if (number === 1) {
      //   // Hide results
      //   if (resultsElement.current) {
      //     resultsElement.current!.classList.add('d-none')
      //   }
      //   // Show empty message
      //   if (emptyElement.current) {
      //     emptyElement.current!.classList.remove('d-none')
      //   }
      // } else {
      //   // Show results
      // }
      if (resultsElement.current) {
        resultsElement.current!.classList.remove('d-none')
      }
      // Hide empty message
      if (emptyElement.current) {
        emptyElement.current!.classList.add('d-none')
      }

      // Complete search
      search.complete()
    }, 1200)
  }

  const clear = (search: SearchComponent) => {
    // Show recently viewed
    // suggestionsElement.current!.classList.remove('d-none')
    // Hide results
    resultsElement.current!.classList.add('d-none')
    // Hide empty message
    emptyElement.current!.classList.remove('d-none')
  }

  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search')

    // Search handler
    searchObject!.on('kt.search.process', process)

    // Clear handler
    searchObject!.on('kt.search.clear', clear)

    clearArrays()
  }, [])

  const clearArrays = () => {
    setRooms([])
    setUsers([])
    setEvents([])
    setFolders([])
    setFiles([])
    setMessages([])
    setVotes([])
    setReports([])
    setSurveys([])
    setMeetings([])
    setGalleries([])
    setContacts([])
  }

  const onChangeSearch = async (e: any) => {
    const value = e.target.value
    setSearchTerm(value)
    debouncedSearch(value)
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (element.current && !element.current.contains(event.target)) {
        elementInputSearch.current!.value = ''
        emptyElement.current!.classList.remove('d-none')
        clearArrays()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [element])

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='1'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <form
              data-kt-search-element='form'
              className='w-100 position-relative mb-3'
              autoComplete='off'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />
              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                ref={elementInputSearch}
                placeholder={getTranslation('BASE.SEARCH.PLACEHOLDER', intl)}
                onChange={onChangeSearch}
                data-kt-search-element='input'
              />
              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>
              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 svg-icon-lg-1 me-0'
                />
              </span>
              <div
                className='position-absolute top-50 end-0 translate-middle-y'
                data-kt-search-element='toolbar'
              ></div>
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div key={key}>{renderItems()}</div>
            </div>
            <div ref={emptyElement} data-kt-search-element='empty' className='text-center'>
              <div className='pt-10 pb-10'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50'
                />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>
                  {getTranslation('BASE.SEARCH.NOT_FOUND', intl)}
                </h3>
                <div className='text-muted fs-7'>
                  {' '}
                  {getTranslation('BASE.SEARCH.ENTRY_TEXT', intl)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export {Search}
