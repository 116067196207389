import React from 'react'
import toast from 'react-hot-toast'
import './style.scss'
import {NOTIFICATION_SETTINGS, NOTIFICATION_TYPE} from '../../Constants/Notification'
import {FormattedMessage} from 'react-intl'

export function removeAllNotifications() {
  toast.remove()
}

export function showNotification(type = NOTIFICATION_TYPE.info, content: any, settings = {}) {
  settings = {...NOTIFICATION_SETTINGS, ...settings}
  return toast(
    (t) => (
      <div
        className={`alert alert-dismissible bg-light-${type} d-flex flex-column flex-sm-row p-5`}
      >
        {/*<span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'>...</span>*/}

        <div className='d-flex flex-column mb-0 pb-0' style={{lineHeight: 'auto'}}>
          <h4 className='fw-bold-custom mb-0'>
            <FormattedMessage id={content} />
          </h4>
          {/*<span>{content}</span>*/}
        </div>

        {/*<button*/}
        {/*  type='button'*/}
        {/*  className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'*/}
        {/*  data-bs-dismiss='alert'*/}
        {/*  onClick={() => toast.dismiss(t.id)}*/}
        {/*>*/}
        {/*  /!*<span className='svg-icon svg-icon-1 svg-icon-primary'>...</span>*!/*/}
        {/*</button>*/}
      </div>
      // <CAlert color={type} className='m-0 cursor-pointer' onClick={() => toast.dismiss(t.id)}>
      //   {content}
      // </CAlert>
    ),
    settings
  )
}

export function showBulkNotification(data: any, type = 'info') {
  for (let i in data) {
    showNotification(type, data[i])
  }
}

export function showResponseNotificationMessage(res: any) {
  const type = res.status === 200 ? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger
  // showBulkNotification(res.messages, type)
  showNotification(type, res.message)
}
