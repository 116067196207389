export function confirmDialog(
  title: string,
  message: string,
  onConfirm: () => void,
  onCancel?: () => void
) {
  document.dispatchEvent(
    new CustomEvent('confirmDialog', {
      detail: {
        title: title || null,
        message: message,
        onConfirm: onConfirm,
        onCancel: onCancel || null,
      },
    })
  )
}

export function eventConfirmDialog(
  title: string,
  message: string,
  allEvents: () => void,
  thisAndFutureEvents: () => void,
  thisEvent: () => void,
  onCancel?: () => void
) {
  document.dispatchEvent(
    new CustomEvent('eventConfirmDialog', {
      detail: {
        title: title || null,
        message: message,
        allEvents: allEvents,
        thisAndFutureEvents: thisAndFutureEvents,
        thisEvent: thisEvent,
        onCancel: onCancel || null,
      },
    })
  )
}
