import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {getTranslation} from '../../Helpers/Default'
import CalendarWidget from './Calendar'
import {observer} from 'mobx-react'
import MessagesWidget from './Messages'
import MainContacts from './MainContacts'
import DashboardStore from '../../Stores/Dashboard'
import SvjStore from '../../Stores/Svj'
import UserStore from '../../Stores/User'
import ContactStore from '../../Stores/Contact'
import {toJS} from 'mobx'
import {EVENTS_MODULE, MESSAGES_MODULE} from '../../Constants/Modules'
import {userHasModule} from '../../Helpers/User'
const DashboardWrapper: FC = observer(() => {
  const intl = useIntl()
  const [committee, setCommittee] = React.useState<any>([])

  useEffect(() => {
    async function getData() {
      DashboardStore.getData()
      UserStore.getAll()
      setCommittee(toJS(SvjStore.committees))
    }

    getData()
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{getTranslation('MENU.DASHBOARD', intl)}</PageTitle>
      {DashboardStore.pinnedMessages.length > 0 && userHasModule(MESSAGES_MODULE) ? (
        <div className='row mb-8'>
          <div className='col-12'>
            <MessagesWidget
              icon={'/media/icons/duotune/communication/com009.svg'}
              title={'DASHBOARD.MESSAGES.PINNED_MESSAGES'}
              link={'/zpravy?tab=pined'}
              messages={DashboardStore.pinnedMessages}
            />
          </div>
        </div>
      ) : null}
      {userHasModule(MESSAGES_MODULE) ? (
        <div className='row mb-8'>
          <div className='col-12'>
            <MessagesWidget messages={DashboardStore.messages} />
          </div>
        </div>
      ) : null}
      <div className='row'>
        {userHasModule(EVENTS_MODULE) ? (
          <div className='col-12 col-md-6 col-xl-6'>
            <CalendarWidget />
          </div>
        ) : null}

        <div className='col-12 col-md-6 col-xl-6'>
          <MainContacts users={UserStore.users} />
        </div>
      </div>
      {/*<div className='row mt-8'>*/}
      {/*  <div className='col-12'>*/}
      {/*    <ActivityTable />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  )
})

export {DashboardWrapper}
