import axios from 'axios';

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // withCredentials: false,
  headers: {
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  }
});
