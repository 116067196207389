import {action, makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {showBulkNotification, showNotification} from '../../Helpers/Notification'
import {NOTIFICATION_TYPE} from '../../Constants/Notification'
import {getLocalStorageValue, setLocalStorageValue} from '../../Helpers/LocalStorage'
import MessageStore from '../Messages'
import UserStore from '../User'
import RoomStore from '../Room'
import RolesAccessStore from '../RolesAccessStore'
import ReportStore from '../Reports'
import VoteStore from '../Vote'
import SurveyStore from '../Survey'
import MeetingStore from '../MeetingOfOwner'
import RequirementApprovalStore from '../RequirementForApproval'
import _ from 'lodash'
import {IsDemo} from '../../Helpers/Demo'

class SvjStore {
  token: any = null
  svj_list: any = []
  svj_users_list: any = []
  invoices: any = []
  selectSvj: boolean = false
  login_request: boolean = false
  updating: boolean = false
  committees: any = []
  committeesCount: number = 0
  auditCommittees: any = []
  activities: any = []
  auditCommitteesCount: number = 0
  data: any = null
  modules: any = []
  entrances: any = []
  entranceIds: any = []
  realEstate: any = []
  insolvency: any = []
  avatar: any = null
  isChanging: boolean = false
  downloading: boolean = false
  hasMayorCommittee: boolean = false
  detail: any = {
    url_hash: 'sss',
  }

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
      setSVJ: action,
    })
  }

  setSVJ(session_svj_id: string) {
    setLocalStorageValue('svj', session_svj_id)
  }

  getSVJ() {
    return getLocalStorageValue('svj')
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async getInit() {
    await this.get()
  }

  async get() {
    const res = await apiRequest(
      '/api/v2/svj',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    if (res) {
      if (res.data && res.data.svj) {
        this.setValue('data', res.data.svj)
      }

      if (res.data && res.data.entrances) {
        this.setValue('entrances', res.data.entrances)
        let entrancesIds: any = []
        for (let i in res.data.entrances) {
          const p = res.data.entrances[i]
          entrancesIds.push(p.id)
        }
        this.setValue('entranceIds', entrancesIds)
      }

      if (res.data && res.data.modules) {
        this.setValue('modules', _.uniq(res.data.modules))
      }

      if (res.data && res.data.activities) {
        this.setValue('activities', res.data.activities)
      }

      if (res.data && res.data.hasMayorCommittee) {
        this.setValue('hasMayorCommittee', res.data.hasMayorCommittee > 0)
      }

      if (res.data && res.data.committees) {
        this.setValue('committees', res.data.committees)
        this.setValue('committeeCount', res.data.committees.length)
      }

      if (res.data && res.data.auditCommittees) {
        this.setValue('auditCommittees', res.data.auditCommittees)
        this.setValue('auditCommitteesCount', res.data.auditCommittees.length)
      }

      if (res.data && res.data.roomsCount) {
        RoomStore.setValue('roomsCount', res.data.roomsCount)
      }

      if (res.data && res.data.usersCount) {
        UserStore.setValue('usersCount', res.data.usersCount)
      }

      if (res.data && res.data.messageLabels) {
        MessageStore.setValue('messageLabels', res.data.messageLabels)
      }

      if (res.data && res.data.messageDefaultLabel) {
        MessageStore.setValue('defaultLabel', res.data.messageDefaultLabel)
      }

      if (res.data && res.data.reportsCount) {
        ReportStore.setValue('newCount', res.data.reportsCount)
      }

      if (res.data && res.data.votesCount) {
        VoteStore.setValue('activeCount', res.data.votesCount)
      }

      if (res.data && res.data.surveysCount) {
        SurveyStore.setValue('activeCount', res.data.surveysCount)
      }

      if (res.data && res.data.meetingsCount) {
        MeetingStore.setValue('scheduledCount', res.data.meetingsCount)
      }

      if (res.data && res.data.requirementApprovalCount) {
        RequirementApprovalStore.setValue('waitingCount', res.data.requirementApprovalCount)
      }

      await RolesAccessStore.getAll()
      await UserStore.getAll()

      // showBulkNotification(
      //   res.message,
      //   res.status === 200 ? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger
      // )
    }
    if (res.status === 200) {
    }
  }

  async login(data: any) {
    const res = await apiRequest(
      '/api/v1/auth/login',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    if (res) {
      showBulkNotification(
        res.message,
        res.status === 200 ? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger
      )
    }
    if (res.status === 200) {
    }
  }

  async getAvatar() {
    const res = await apiRequest(
      '/api/v2/svj/avatar',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    this.setValue('avatar', res.data)
    return res.status === 200
  }

  async getInvoices() {
    const res = await apiRequest(
      '/api/v2/invoice',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    if (res.status === 200) {
      this.setValue('invoices', res.data)
    }
    return res.status === 200
  }

  async downloadProformaInvoice(id: string) {
    this.setValue('downloading', true)
    const res = await apiRequest(
      `/api/v2/invoice/downloadProformaInvoice/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    if (res.status === 200 && res.data) {
      window.open(res.data, '_blank')
    } else {
      showNotification(NOTIFICATION_TYPE.danger, 'INVOICE.PROFORMA_INVOICE_NOT_FOUND')
    }
    this.setValue('downloading', false)
    return res.status === 200
  }

  async downloadInvoice(link: string) {
    return true
  }

  async update(data: any) {
    if (IsDemo()) {
      return false
    }
    this.setValue('updating', true)
    const res = await apiRequest(
      `/api/v2/svj/${this.data.id}`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    this.setValue('updating', false)
    return res.status === 200
  }

  async getRealEstate() {
    const res = await apiRequest(
      '/api/v2/real-estate-status',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    if (res.status === 200) {
      this.setValue('realEstate', res.data)
    }
    return res.status === 200
  }

  async getInsolvency() {
    const res = await apiRequest(
      '/api/v2/insolvency',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    if (res.status === 200) {
      this.setValue('insolvency', res.data)
    }
    return res.status === 200
  }

  async deleteInsolvencyCheck(id: string) {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      `/api/v2/insolvency/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    return res.status === 200
  }

  async getAllUserSvj() {
    const res = await apiRequest(
      `/api/v2/svj/all`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    return res.data ?? []
  }

  async uploadAvatar(file: any) {
    if (IsDemo()) {
      return false
    }
    const formData = new FormData()
    formData.append('avatar', file)
    const res = await apiRequest(
      '/api/v2/svj/avatar',
      REQ_TYPE.post,
      // @ts-ignore
      formData,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    if (res.status === 200) {
      this.setValue('avatar', res.data)
    }
    return res.status === 200
  }

  async deleteAvatar() {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      '/api/v2/svj/avatar',
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    if (res.status === 200) {
      this.setValue('avatar', null)
    }
    return res.status === 200
  }

  async getCadaster(id: any, cadasterNumber: any, cadasterVillage: any, cadasterStreet: any) {
    const res = await apiRequest(
      `/api/v2/svj/${id}/getCadaster`,
      REQ_TYPE.post,
      // @ts-ignore
      {
        cadasterNumber: cadasterNumber || '',
        cadasterVillage: cadasterVillage || '',
        cadasterStreet: cadasterStreet || '',
      },
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    return res.data ?? []
  }

  async changeMayorCommittee(data: any) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isChanging', true)
    const res = await apiRequest(
      '/api/v2/svj/change-mayor-committee',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    this.setValue('isChanging', false)

    return res.status === 200
  }

  async getActivities() {
    const res = await apiRequest(
      '/api/v2/activity',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    if (res.status === 200) {
      this.setValue('activities', res.data)
    }
  }

  async checkInvoice() {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      '/api/v2/invoice/check-invoices',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    return res.status === 200
  }
}
export default new SvjStore()
