import {observer} from 'mobx-react'
import {KTSVG} from '../../../../_metronic/helpers'
import React from 'react'
import {TooltipDefault} from '../../Tooltip'

export const SectionHelper = observer((props: any) => {
  return (
    <div className={`d-inline-flex me-3 ${props.className ?? null}`}>
      <TooltipDefault tooltip={props.text} position='top'>
        {props.link ? (
          <a href={props.link} target={'_blank'} className='btn-link' rel='noreferrer'>
            <div className='icon-area'>
              <KTSVG path={'/media/icons/duotune/general/gen046.svg'} className='svg-icon-2' />
            </div>
          </a>
        ) : (
          <div className='icon-area'>
            <KTSVG path={'/media/icons/duotune/general/gen046.svg'} className='svg-icon-2' />
          </div>
        )}
      </TooltipDefault>
    </div>
  )
})
