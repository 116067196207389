export const NOTIFICATION_TYPE = {
  success: 'success',
  warning: 'warning',
  info: 'info',
  danger: 'danger',
}

export const NOTIFICATION_SETTINGS = {
  duration: 4000,
  position: 'top-center',
  className: 'notification-area cursor-pointer',
}
