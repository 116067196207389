import React, {useRef} from 'react'
import {observer} from 'mobx-react'
import {FileDrop} from 'react-file-drop'
import {useIntl} from 'react-intl'
import {getTranslation} from '../../Helpers/Default'
import {showNotification} from '../../Helpers/Notification'

export const UploadFileArea = observer((props: any) => {
  const intl = useIntl()
  const fileInputRef = useRef(null)
  const onFileInputChange = (event: any) => {
    const {files} = event.target
    const acceptFiles: File[] = []

    for (let index in files) {
      const file = files[index]
      if (file.size > 262144000) {
        showNotification('danger', getTranslation('UPLOAD.FILE_SIZE_ERROR', intl, file.name))
        continue
      }

      acceptFiles.push(file)
    }
    props.setDocuments(acceptFiles)
  }

  const onTargetClick = () => {
    if (fileInputRef && fileInputRef.current) {
      // @ts-ignore
      fileInputRef.current.click()
    }
  }

  return (
    <>
      <FileDrop
        onTargetClick={onTargetClick}
        onDrop={(files, event) => props.setDocuments(files)}
        className='border-dashed border-primary pb-0 mb-0'
      >
        <div className='alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-0'>
          <i className='bi bi-file-earmark-arrow-up text-primary fs-3x'></i>
          <div className='d-flex flex-column text-primary pe-0 pe-sm-10 ms-3'>
            <h5 className='mb-1'>{getTranslation('UPLOAD.DRAG_AND_DROP', intl)}</h5>
            <span>{getTranslation('UPLOAD.CLICK_HERE', intl)}</span>
          </div>
        </div>
      </FileDrop>
      <input
        onChange={onFileInputChange}
        ref={fileInputRef}
        multiple={true}
        type='file'
        style={{display: 'none'}}
        className='hidden'
      />
    </>
  )
})
