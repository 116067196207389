import React, {useState} from 'react'
import {observer} from 'mobx-react'

// @ts-ignore
import {convertFromHTML, EditorState, ContentState} from 'draft-js'
import './style.scss'
import {InputLabel} from '../InputLabel'
import {isEmpty} from 'lodash'
import {getTranslation, removeHtmlTags} from '../../Helpers/Default'
import {useIntl} from 'react-intl'
import EmptyText from '../EmptyText'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const TextEditor = observer((props: any) => {
  const intl = useIntl()
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike', 'link'], // toggled buttons
    [{list: 'ordered'}, {list: 'bullet'}, {list: 'check'}],
    [{header: [1, 2, 3, 4, 5, 6, false]}],
    [{color: []}, {background: []}], // dropdown with defaults from theme
    [{align: []}],

    ['clean'], // remove formatting button
  ]

  const customToolbar = () => {
    // Přidáme české překlady přímo do toolbaru
    const toolbar = document.querySelector('.ql-toolbar')
    if (toolbar) {
      const headerSelect = toolbar.querySelector('.ql-header')
      if (headerSelect) {
        const options: any = headerSelect.querySelectorAll('.ql-picker-item')
        if (options && options[0]) {
          options[0].setAttribute('data-translation', intl.formatMessage({id: 'heading1'}))
          options[1].setAttribute('data-translation', intl.formatMessage({id: 'heading2'}))
          options[2].setAttribute('data-translation', intl.formatMessage({id: 'heading3'}))
          options[3].setAttribute('data-translation', intl.formatMessage({id: 'heading4'}))
          options[4].setAttribute('data-translation', intl.formatMessage({id: 'heading5'}))
          options[5].setAttribute('data-translation', intl.formatMessage({id: 'heading6'}))
          options[6].setAttribute('data-translation', intl.formatMessage({id: 'normal'}))
        }
      }
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      customToolbar()
    }, 1000)
  }, [])

  let labelClass = ['fw-bold-custom']
  if (props.required) {
    labelClass.push('required')
  }

  const handleChange = (e: any) => {
    props.action({
      target: {
        name: props.name,
        value: e,
      },
    })
    // setEditorState(e)
  }

  if (!props.isEdit) {
    return (
      <div className='pt-4 fs-6'>
        {isEmpty(removeHtmlTags(props.value)) ? (
          <EmptyText
            text={props.emptyText ? props.emptyText : getTranslation('DESCRIPTION.EMPTY', intl)}
          />
        ) : (
          <div dangerouslySetInnerHTML={{__html: props.value}}></div>
        )}
      </div>
    )
  }

  return (
    <div className={`${props.className ?? props.className}`}>
      {props.label ? <InputLabel className={labelClass.join(' ')}>{props.label}</InputLabel> : null}
      <ReactQuill
        theme='snow'
        value={props.value}
        onChange={handleChange}
        modules={{
          toolbar: toolbarOptions,
        }}
      />
      {props.formik && props.formik.touched[props.name] && props.formik.errors[props.name] && (
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <div className='fv-help-block'> {props.formik.errors[props.name]}</div>
        </div>
      )}
    </div>
  )
})

export default TextEditor
