import {action, makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {getTranslation, hasModule} from '../../Helpers/Default'
import {MEETING_OWNERS_MODULE, USERS_MODULE} from '../../Constants/Modules'
import {showNotification} from '../../Helpers/Notification'
import {IsDemo} from '../../Helpers/Demo'

class UserStore {
  isLoading: boolean = false
  isCreating: boolean = false
  isUpdating: boolean = false
  isSearching: boolean = false
  isActivating: boolean = false
  isSending: boolean = false
  usersCount = 0
  committeeCount = 0
  activeUsersCount = 0
  waitingUsersCount = 0
  companyUsersCount = 0
  archivedUserCount = 0
  isArchiving = false
  isDeleting = false

  user: any = null
  users: any = []
  detail: any = {}
  loadDetail: boolean = false
  selectLang: string = 'cs'

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
      changeLanguage: action,
    })
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async get(id: any) {
    const res = await apiRequest(
      '/api/v2/user/' + id,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    if (res.status === 200) {
      this.setValue('detail', res.data)
      return res.data
    }
    return null
  }

  async storeSettings(data: any) {
    const res = await apiRequest(
      '/api/v2/user/settings',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
  }

  async getAll(param: string = 'users') {
    const res = await apiRequest(
      `/api/v2/user?tab=${param}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    if (res.status === 200) {
      if (res?.data) {
        this.setValue('users', res.data.users)
        this.setValue('usersCount', res.data.usersCount)
        this.setValue('committeeCount', res.data.committeeCount)
        this.setValue('activeUsersCount', res.data.activeUsersCount)
        this.setValue('waitingUsersCount', res.data.waitingUsersCount)
        this.setValue('companyUsersCount', res.data.companyUsersCount)
        this.setValue('archivedUserCount', res.data.archivedUserCount)
      } else {
        this.setValue('users', [])
      }
    }
  }

  async archive(id: string) {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      `/api/v2/user/archive/${id}`,
      REQ_TYPE.put,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    return res.status === 200
  }

  async restore(id: string) {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      `/api/v2/user/archive/restore/${id}`,
      REQ_TYPE.put,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    return res.status === 200
  }

  async remove(id: string) {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      `/api/v2/user/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    return res.status === 200
  }

  async removeAccess(id: string) {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      `/api/v2/user/access/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    return res.status === 200
  }

  async changeLanguage(lang: string) {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      `/api/v2/user/language`,
      REQ_TYPE.post,
      // @ts-ignore
      {lang: lang},
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    if (res.status === 200) {
      this.setValue('selectLang', lang)
    }
    return res.status === 200
  }

  async create(data: any) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isCreating', true)
    const res = await apiRequest(
      `/api/v2/user`,
      REQ_TYPE.post,
      // @ts-ignore
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isCreating', false)

    return res.status === 200
  }

  // async updateSettings(id: string, data: any) {
  //   const res = await apiRequest(
  //     `/api/v2/user/settings/${id}`,
  //     REQ_TYPE.put,
  //     // @ts-ignore
  //     data,
  //     // @ts-ignore
  //     getHeaders(),
  //     this,
  //     ''
  //   )
  //
  //   return res.status === 200
  // }
  async update(data: any, intl: any) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isUpdating', true)
    const res = await apiRequest(
      `/api/v2/user/${data.id}`,
      REQ_TYPE.put,
      // @ts-ignore
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isUpdating', false)
    // if (res.data.duplicityEmail) {
    //   showNotification('danger', getTranslation('USER.CHANGE.EMAIL.CONTACT_SUPPORT', intl))
    // }
    return res.data //res.status === 200
  }

  async updateSettings(data: any, intl: any, type: string) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isUpdating', true)
    const res = await apiRequest(
      `/api/v2/user/${data.id}/settings`,
      REQ_TYPE.put,
      // @ts-ignore
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isUpdating', false)
    if (type === 'favorite_add') {
      showNotification('success', getTranslation('USER.CHANGE.FAVORITE.ADD', intl))
    } else if (type === 'favorite_delete') {
      showNotification('success', getTranslation('USER.CHANGE.FAVORITE.DELETE', intl))
    }
    return res.status === 200
  }

  async changePassword(data: any) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isUpdating', true)
    const res = await apiRequest(
      `/api/v2/user/password`,
      REQ_TYPE.put,
      // @ts-ignore
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isUpdating', false)
    return res.status === 200
  }

  async sendActivateEmail(id: any) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isSending', true)
    const res = await apiRequest(
      `/api/v2/user/activate-email/${id}`,
      REQ_TYPE.put,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSending', false)
    return res.status === 200
  }

  async search(text: string) {
    if (!hasModule(USERS_MODULE)) {
      return []
    }
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/user/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSearching', false)
    return res.data ?? []
  }

  async checkActivateToken(token: string) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isLoading', true)
    const res = await apiRequest(
      `/api/v2/user/activate-token/${token}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoading', false)
    if (res.status === 200 && res.data.isActive === false) {
      return {
        ok: true,
        data: res.data,
      }
    } else {
      return {
        ok: false,
        data: res.data,
      }
    }
  }

  async activateUser(userId: string, data: any, url_hash: string) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isActivating', true)
    const res = await apiRequest(
      `/api/v2/user/${userId}/activate/${url_hash}`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isActivating', false)
    return res.status === 200
  }
}
export default new UserStore()
