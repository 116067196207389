import * as React from 'react'
import {observer} from 'mobx-react'
import {TooltipDefault} from '../Tooltip'
import {getTranslation} from '../../Helpers/Default'
import {DefaultButton} from '../Buttons/DefaultButton'

const TableActionButton = observer((props: any) => {
  const className = [props.className]
  let button_type = 'text-hover-'
  if (props.disabled) {
    className.push('disabled')
  }

  if (props.button_type === 'primary') {
    button_type = 'text-hover-'
  }

  const buttonHtml = (
    <span
      className={` ${button_type}${props.color_type} cursor-pointer p-2 ${className.join(' ')}`}
      onClick={() => {
        if (props.disabled || props.loader) {
          return
        }
        if (props.action) {
          props.action(props.data)
        }
      }}
      data-bs-toggle={props.tooltip ? 'tooltip' : ''}
      title={props.tooltip ? props.tooltip : ''}
    >
      {props.loader ? (
        <span className={`spinner-border spinner-border-sm align-middle`}></span>
      ) : (
        props.children
      )}
    </span>
  )

  if (props.tooltip) {
    return <TooltipDefault tooltip={props.tooltip}>{buttonHtml}</TooltipDefault>
  }

  return buttonHtml
})

export default TableActionButton
