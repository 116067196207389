import {observer} from 'mobx-react'
import * as React from 'react'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {DefaultModal} from '../DefaultModal'
import {ModalBody} from 'react-bootstrap-v5'
import {DefaultButton} from '../Buttons/DefaultButton'
import RoomStore from '../../Stores/Room'
import {getTranslation} from '../../Helpers/Default'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const DemoModal: FC<{}> = observer(() => {
  const intl = useIntl()
  const [open, setOpen] = React.useState(true)

  return (
    <>
      <DefaultModal show={open} onHide={() => {}} width={'mw-800px'}>
        <ModalBody>
          <div className={'w-100 text-center mb-5'}>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-45px' />
          </div>
          <div className={'w-100 mb-5'}>
            <ul className='list-unstyled'>
              <li className='pb-3'>{getTranslation('DEMO.MODAL.TEXT.LINE.1', intl)}</li>
              <li className='pb-3'>{getTranslation('DEMO.MODAL.TEXT.LINE.2', intl)}</li>
              <li className='pb-3'>
                {getTranslation('DEMO.MODAL.TEXT.LINE.3', intl)}{' '}
                <a href='mailto:podpora@svjaplikace.cz'>podpora@svjaplikace.cz</a>.
              </li>
            </ul>
          </div>
          <div className={'w-100 text-center'}>
            <DefaultButton
              className={'btn btn-primary btn-sm'}
              type={'button'}
              id={'enter_button'}
              onClick={() => {
                setOpen(false)
              }}
              loading={false}
              disable={false}
            >
              {getTranslation('DEMO.MODAL.BUTTONS.ENTRY', intl)}
            </DefaultButton>
          </div>
        </ModalBody>
      </DefaultModal>
    </>
  )
})

export default DemoModal
