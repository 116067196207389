import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import * as React from 'react'
import {useEffect} from 'react'
import Translation from '../Translation'

export default function EventConfirmationDialog() {
  const [open, setOpen] = React.useState(false)
  const [title, setTitle] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [allFunction, setAllFunction] = React.useState(null)
  const [thisAndFutureFunction, setThisAndFutureFunction] = React.useState(null)
  const [thisFunction, setThisFunction] = React.useState(null)
  const [cancelFunction, setCancelFunction] = React.useState(null)
  const [buttonsSettings, setButtonSettings] = React.useState({
    cancel: {
      text: 'BASE.NO',
    },
    all: {
      text: 'CALENDAR.FORM.BUTTONS.FUTURE',
    },
    thisAndFuture: {
      text: 'CALENDAR.FORM.BUTTONS.THIS_AND_FUTURE',
    },
    this: {
      text: 'CALENDAR.FORM.BUTTONS.THIS',
    },
    confirm: {
      text: 'BASE.YES',
    },
  })

  useEffect(() => {
    const handleClick = (etc: any) => {
      setTitle(etc.detail.title || 'BASE.CONFIRM.TITLE')
      setMessage(etc.detail.message || 'BASE.CONFIRM.QUESTION.CREATE')
      setAllFunction(() => etc.detail.allEvents)
      setThisAndFutureFunction(() => etc.detail.thisAndFutureEvents)
      setThisFunction(() => etc.detail.thisEvent)
      setCancelFunction(() => etc.detail.onCancel)
      setOpen(!open)
      return true
    }

    document.addEventListener('eventConfirmDialog', handleClick)

    // 👇️ remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('eventConfirmDialog', handleClick)
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(!open)
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {title ? <Translation text={title} /> : null}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={'btn btn-sm btn-danger'}
          onClick={() => {
            if (cancelFunction !== null) {
              // @ts-ignore
              cancelFunction()
            }
            setOpen(!open)
          }}
        >
          <Translation text={buttonsSettings.cancel.text} />
        </Button>

        <Button
          onClick={() => {
            if (thisFunction !== null) {
              // @ts-ignore
              thisFunction()
            }
            setOpen(!open)
          }}
          autoFocus
        >
          <Translation text={buttonsSettings.this.text} />
        </Button>

        <Button
          onClick={() => {
            if (thisAndFutureFunction !== null) {
              // @ts-ignore
              thisAndFutureFunction()
            }
            setOpen(!open)
          }}
          autoFocus
        >
          <Translation text={buttonsSettings.thisAndFuture.text} />
        </Button>

        <Button
          onClick={() => {
            if (allFunction !== null) {
              // @ts-ignore
              allFunction()
            }
            setOpen(!open)
          }}
          autoFocus
        >
          <Translation text={buttonsSettings.all.text} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
