const Tooltip = (props: any) => {
  if (!props.text) {
    return props.children
  }
  return (
    <span data-bs-toggle='tooltip' data-bs-placement='top' title={props.text}>
      {props.children}
    </span>
  )
}

export default Tooltip
