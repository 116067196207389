import {observer} from 'mobx-react'

import PhoneCountryInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import React, {FC} from 'react'
import clsx from 'clsx'
import {InputLabel} from '../InputLabel'
import {formatPhoneNumber} from '../../Helpers/Default'

const PhoneInput: FC<{
  formik?: any
  className?: string
  required?: boolean
  name: string
  placeholder?: string
  label?: string
  type?: string
  changeValue: any
  value: any
  readOnly: boolean
  extraRowClass?: string
  inputBold?: boolean
}> = observer((props) => {
  let labelClass = []
  let inputClass = ['form-control', 'form-control-lg', 'phone-input', 'w-100']
  let extraRowClass = props.extraRowClass ? props.extraRowClass : 'mb-5'
  let inputBold = props.inputBold ?? false
  if (props.required) {
    labelClass.push('required')
    inputClass.push('required')
  }

  if (props.className) {
    inputClass.push(props.className)
  }

  if (props.formik) {
    inputClass.push(
      clsx(
        '',
        {'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name]},
        {'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name]}
      )
    )
  }

  if (props.readOnly) {
    return (
      <div className={`${extraRowClass}`}>
        <span className={`${inputBold ? 'fw-bold-custom' : ''} fs-6`}>
          {formatPhoneNumber(props.value) ?? '-'}
        </span>
      </div>
    )
  }

  return (
    <div className={`${extraRowClass}`}>
      {props.label ? <InputLabel className={labelClass.join(' ')}>{props.label}</InputLabel> : null}
      <PhoneCountryInput
        // @ts-ignore
        country={'cz'}
        preferredCountries={['cz', 'sk']}
        inputClass={`${inputClass.join(' ')}`}
        // enableSearch={true}
        inputProps={{
          name: props.name,
        }}
        inputStyle={{
          backgroundColor: '#f5f8fa',
          border: 'none',
          height: '45.48px',
          color: '#5E6278 !important',
          paddingLeft: '55px',
          fontSize: '1.15rem !important',
          fontWeight: 'normal !important',
          fontFamily: '"Roboto", Helvetica, "sans-serif" !important',
        }}
        buttonStyle={{
          backgroundColor: '#f5f8fa',
          border: 'none',
          paddingLeft: '10px',
        }}
        value={(props?.value?.indexOf('+') === -1 ? '+420' : '') + props.value}
        onChange={(value, data, event, formattedValue) => {
          props.changeValue({
            target: {
              value: formattedValue,
              name: props.name,
            },
          })
        }}
      />
      {props.formik && props.formik.touched[props.name] && props.formik.errors[props.name] && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <div className='fv-help-block'> {props.formik.errors[props.name]}</div>
        </div>
      )}
    </div>
  )
})

export default PhoneInput
