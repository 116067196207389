import {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {hasCrudRole} from '../../Helpers/User'
import {isReadOnlyMode} from '../../Helpers/Default'
import {showNotification} from '../../Helpers/Notification'
import {NOTIFICATION_TYPE} from '../../Constants/Notification'

const useCheckCrudRole = (module: string, pageUrl: string, directCheckRule = false) => {
  const history = useHistory()

  useEffect(() => {
    if (!isReadOnlyMode() || directCheckRule) {
      const userHasPermission = hasCrudRole(module)
      if (!userHasPermission) {
        showNotification(NOTIFICATION_TYPE.danger, 'BASE.ERRORS.NO_PERMISSION')
        history.push(pageUrl)
      }
    }
  }, [history])
}

export default useCheckCrudRole
