import {observer} from 'mobx-react'
import * as React from 'react'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {DefaultBadge} from '../../../Components/Badge'
import {getTranslation, removeHtmlTags, shortenText} from '../../../Helpers/Default'
import {getMessageIcons, renderPeriod} from '../../../Helpers/Messages'
import {LinkButton} from '../../../Components/LinkButton'
import {Link, useHistory} from 'react-router-dom'
import moment from 'moment/moment'
import {DEFAULT_DATE_FORMAT, DEFAULT_DB_DATE_FORMAT} from '../../../Constants/Defatul'

const MessagesWidget: FC<{messages: any[]; title?: string; icon?: string; link?: string}> =
  observer((props) => {
    const intl = useIntl()
    const history = useHistory()

    const renderMessages = () => {
      const html = []
      for (let i in props.messages) {
        if (parseInt(i) > 2) {
          break
        }
        const message = props.messages[i]
        if (!message) {
          continue
        }
        const {date} = renderPeriod(message.dateFrom, message.dateTo, false, message.long_term)
        html.push(
          <div key={i} className='col-12 col-md-4 mb-5 mb-md-0'>
            <div className='card-xl-stretch custom-border-card'>
              <div className=''>
                <div className={'w-100'}>
                  <span className='fs-4 text-gray-900 fw-bold-custom text-gray-900 lh-base'>
                    {shortenText(message.title, 30, 'characters')} {getMessageIcons(message)}
                  </span>
                </div>
                <div className='d-block d-lg-inline-flex w-100 justify-content-between my-6'>
                  <div>
                    {message.label ? (
                      <DefaultBadge color={message.label.type}>{message.label.name}</DefaultBadge>
                    ) : null}
                  </div>
                  <div>
                    {!message.isPinned ? (
                      <span>{date}</span>
                    ) : (
                      <span>
                        {message.dateFrom
                          ? moment(message.dateFrom, DEFAULT_DB_DATE_FORMAT).format(
                              DEFAULT_DATE_FORMAT
                            )
                          : null}
                      </span>
                    )}
                  </div>
                </div>

                <div className='fw-semibold fs-5 text-gray-600 text-gray-900  min-h-135px'>
                  <div
                    className={'w-100'}
                    dangerouslySetInnerHTML={{
                      __html: message?.description
                        ? shortenText(removeHtmlTags(message.description), 30) ?? ''
                        : '',
                    }}
                  ></div>
                </div>

                <div className='text-muted fw-bold cursor-pointer mt-5 d-flex justify-content-start text-center'>
                  <span
                    onClick={() => {
                      history.push(`/zpravy/${message.id}`)
                    }}
                  >
                    {getTranslation('MESSAGES.TABLE.ACTION.SHOW', intl)}
                  </span>
                  {/*<LinkButton*/}
                  {/*  link={`/zpravy/${message.id}`}*/}
                  {/*  color={'light'}*/}
                  {/*  text={getTranslation('MESSAGES.TABLE.ACTION.SHOW', intl)}*/}
                  {/*/>*/}
                </div>
              </div>
            </div>
          </div>
        )
      }

      if (html.length === 0) {
        html.push(
          <div className='col-md-12'>
            <div className='card-xl-stretch me-md-6'>
              <div className=''>
                <div className={'w-100 text-center'}>
                  <span className='text-black fs-7 w-100 text-muted lh-base'>
                    {getTranslation('DASHBOARD.MESSAGES.NO_MESSAGES', intl)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      }

      return html
    }

    return (
      <>
        <div className='card h-md-100'>
          <div className='card-header border-0 pt-5'>
            <div className='border-bottom w-100 d-inline-flex justify-content-between pb-3'>
              <h3 className='card-title align-items-start align-items-center'>
                <KTSVG
                  path={props.icon ?? '/media/icons/duotune/communication/com012.svg'}
                  className='svg-icon-1'
                />
                <span className='card-label fw-bold-custom text-gray-900 ps-3'>
                  {getTranslation(
                    props.title ? props.title : 'DASHBOARD.MESSAGES.LAST_MESSAGES',
                    intl
                  )}
                </span>
              </h3>

              <div className='card-toolbar'>
                <Link to={props.link || '/zpravy'} className='text-muted text-decoration-underline'>
                  {getTranslation('DASHBOARD.MESSAGES.SHOW_ALL_MESSAGES', intl)}
                </Link>
              </div>
            </div>
          </div>

          <div className='card-body pt-7 px-0'>
            <div className='row px-9'>{renderMessages()}</div>
          </div>
        </div>
      </>
    )
  })

export default MessagesWidget
