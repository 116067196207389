import {FC} from 'react'
import {useIntl} from 'react-intl'
import {observer} from 'mobx-react'
import {ShowLess1024px} from '../../Responsible'
import {getRandomKey, getTranslation} from '../../../Helpers/Default'
import * as React from 'react'
import {TooltipDefault} from '../../Tooltip'
import {setRoutesParam} from '../../../Helpers/Routes'
import {useHistory, useLocation} from 'react-router-dom'
import {Dropdown} from 'react-bootstrap-v5'

const TopTableTabs: FC<{
  handleChangeFilter?: any
  showFilter: boolean
  setShowFilter: any
  tabs?: any
  selectedFilter?: string
  disabledFilters?: boolean
  disabledTabs?: boolean
  customLeftSide?: any
}> = observer((props) => {
  const intl = useIntl()
  const location = useLocation()
  const history = useHistory()
  const disabledFilters = props.disabledFilters !== undefined ? props.disabledFilters : false
  const disabledTabs = props.disabledTabs !== undefined ? props.disabledTabs : false
  const getTabs = () => {
    const tabsHtml = []
    for (let i in props.tabs) {
      const tab = props.tabs[i]
      if (tab.visible !== undefined && !tab.visible) {
        continue
      }
      tabsHtml.push(
        <li key={getRandomKey()} className='nav-item cursor-pointer'>
          <div
            className={`nav-link ${props.selectedFilter === tab.id ? 'active fa-border' : ''}`}
            onClick={() => {
              props.handleChangeFilter(tab.id)
            }}
          >
            <i className={`${tab.icon} pe-2 fs-3`}></i> {tab.title}
            {tab.count !== undefined && tab.count >= 0 ? (
              <span className='badge badge-light-primary ms-2'>{tab.count}</span>
            ) : null}
          </div>
        </li>
      )
    }
    return tabsHtml
  }

  const getMobileTabs = () => {
    const tabsHtml = []
    for (let i in props.tabs) {
      const tab = props.tabs[i]
      if (tab.visible !== undefined && !tab.visible) {
        continue
      }
      tabsHtml.push(
        <Dropdown.Item
          key={getRandomKey()}
          eventKey='4.1'
          className='p-3'
          onClick={() => {
            props.handleChangeFilter(tab.id)
          }}
        >
          <i className={`${tab.icon} pe-2 fs-3`}></i> {tab.title}
          {tab.count !== undefined && tab.count >= 0 ? (
            <span className='badge badge-light-primary ms-2'>{tab.count}</span>
          ) : null}
        </Dropdown.Item>
      )
      // tabsHtml.push(
      //   <li key={getRandomKey()} className='nav-item cursor-pointer'>
      //     <div
      //       className={`nav-link ${props.selectedFilter === tab.id ? 'active fa-border' : ''}`}
      //       onClick={() => {
      //         props.handleChangeFilter(tab.id)
      //       }}
      //     >
      //       <i className={`${tab.icon} pe-2 fs-3`}></i> {tab.title}
      //       {tab.count !== undefined && tab.count >= 0 ? (
      //         <span className='badge badge-light-primary ms-2'>{tab.count}</span>
      //       ) : null}
      //     </div>
      //   </li>
      // )
    }
    return tabsHtml
  }

  const selectedTab = props.tabs.find((tab: any) => tab.id === props.selectedFilter)

  return (
    <>
      <div className='card-toolbar justify-content-between w-100'>
        <div className='d-inline-flex justify-content-between w-100'>
          {!disabledTabs ? (
            <>
              {ShowLess1024px() ? (
                <div className='d-inline-flex justify-content-center align-items-center'>
                  <strong>{getTranslation('CARD.HEADER.MOBILE.TYPE', intl)}:</strong>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='button'
                      bsPrefix='p-3'
                      className={'d-inline-flex  justify-content-center align-items-center'}
                    >
                      {selectedTab ? (
                        <>
                          {/*<i className={`${selectedTab.icon} pe-2 fs-3`}></i>*/}
                          <span>{selectedTab.title}</span>
                          {selectedTab.count !== undefined && selectedTab.count >= 0 ? (
                            <span className='badge badge-circle badge-light-primary ms-2'>
                              {selectedTab.count}
                            </span>
                          ) : null}
                        </>
                      ) : null}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {getMobileTabs()}

                      {/*<Dropdown.Item*/}
                      {/*  key={getRandomKey()}*/}
                      {/*  eventKey='4.1'*/}
                      {/*  className='p-3'*/}
                      {/*  onClick={() => {*/}
                      {/*    props.handleChangeFilter('pined')*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <i className='la la-thumb-tack pe-2 fs-3'></i>{' '}*/}
                      {/*  {getTranslation('MESSAGES.TABLE.FILTER.PINED', intl)}*/}
                      {/*  <span className='badge badge-circle badge-light-primary ms-2'>*/}
                      {/*    {MessageStore.pinedCount}*/}
                      {/*  </span>*/}
                      {/*</Dropdown.Item>*/}

                      {/*<Dropdown.Item*/}
                      {/*  key={getRandomKey()}*/}
                      {/*  eventKey='4.1'*/}
                      {/*  className='p-3'*/}
                      {/*  onClick={() => {*/}
                      {/*    props.handleChangeFilter('planned')*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <i className='la la-clock pe-2 fs-3'></i>{' '}*/}
                      {/*  {getTranslation('MESSAGES.TABLE.FILTER.PLANNED', intl)}*/}
                      {/*  <span className='badge badge-circle badge-light-primary ms-2'>*/}
                      {/*    {MessageStore.plannedCount}*/}
                      {/*  </span>*/}
                      {/*</Dropdown.Item>*/}
                      {/*{hasCommitteeRole() ? (*/}
                      {/*  <Dropdown.Item*/}
                      {/*    key={getRandomKey()}*/}
                      {/*    eventKey='4.1'*/}
                      {/*    className='p-3'*/}
                      {/*    onClick={() => {*/}
                      {/*      props.handleChangeFilter('concept')*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <i className='la la-pen pe-2 fs-3'></i>{' '}*/}
                      {/*    {getTranslation('MESSAGES.TABLE.FILTER.CONCEPTS', intl)}*/}
                      {/*    <span className='badge badge-circle badge-light-primary ms-2'>*/}
                      {/*      {MessageStore.conceptCount}*/}
                      {/*    </span>*/}
                      {/*  </Dropdown.Item>*/}
                      {/*) : null}*/}

                      {/*<Dropdown.Item*/}
                      {/*  key={getRandomKey()}*/}
                      {/*  eventKey='4.1'*/}
                      {/*  className='p-3'*/}
                      {/*  onClick={() => {*/}
                      {/*    props.handleChangeFilter('old')*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <i className='la la-hourglass-end pe-2 fs-3'></i>{' '}*/}
                      {/*  {getTranslation('MESSAGES.TABLE.FILTER.OLD', intl)}*/}
                      {/*  <span className='badge badge-circle badge-light-primary ms-2'>*/}
                      {/*    {MessageStore.oldCount}*/}
                      {/*  </span>*/}
                      {/*</Dropdown.Item>*/}

                      {/*<Dropdown.Item*/}
                      {/*  key={getRandomKey()}*/}
                      {/*  eventKey='4.1'*/}
                      {/*  className='p-3'*/}
                      {/*  onClick={() => {*/}
                      {/*    props.handleChangeFilter('archive')*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <i className='la la-hourglass-end pe-2 fs-3'></i>{' '}*/}
                      {/*  {getTranslation('MESSAGES.TABLE.FILTER.ARCHIVE', intl)}*/}
                      {/*  <span className='badge badge-circle badge-light-primary ms-2'>*/}
                      {/*    {MessageStore.archiveCount}*/}
                      {/*  </span>*/}
                      {/*</Dropdown.Item>*/}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                <div className={'d-inline-flex justify-content-center align-items-center'}>
                  <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0'>
                    {getTabs()}
                  </ul>
                </div>
              )}
            </>
          ) : null}
          {props.customLeftSide ? props.customLeftSide : null}
          {!disabledFilters ? (
            <div className={'d-inline-flex justify-content-center align-items-center'}>
              <button
                className='btn btn-icon w-25px h-25px'
                tabIndex={0}
                type='button'
                aria-label='Zobrazit/Skrýt filtry'
                data-mui-internal-clone-element={true}
                onClick={() => {
                  setRoutesParam('filter', (!props.showFilter).toString(), history, location)
                  props.setShowFilter(!props.showFilter)
                }}
              >
                {!props.showFilter ? (
                  <TooltipDefault position='top' text={getTranslation('BASE.FILTER.SHOW', intl)}>
                    <i className='fa fad fa-search'></i>
                    {/*<svg*/}
                    {/*  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"*/}
                    {/*  focusable={false}*/}
                    {/*  aria-hidden={true}*/}
                    {/*  viewBox="0 0 24 24"*/}
                    {/*  data-testid="FilterListIcon"*/}
                    {/*>*/}
                    {/*  <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>*/}
                    {/*</svg>*/}
                  </TooltipDefault>
                ) : (
                  <TooltipDefault position='top' text={getTranslation('BASE.FILTER.HIDE', intl)}>
                    <i className='fa fad fa-times'></i>
                    {/*<svg*/}
                    {/*  className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root'*/}
                    {/*  focusable={false}*/}
                    {/*  aria-hidden={true}*/}
                    {/*  viewBox='0 0 24 24'*/}
                    {/*  data-testid='FilterListOffIcon'*/}
                    {/*>*/}
                    {/*  <path d='M10.83 8H21V6H8.83l2 2zm5 5H18v-2h-4.17l2 2zM14 16.83V18h-4v-2h3.17l-3-3H6v-2h2.17l-3-3H3V6h.17L1.39 4.22 2.8 2.81l18.38 18.38-1.41 1.41L14 16.83z'></path>*/}
                    {/*</svg>*/}
                  </TooltipDefault>
                )}

                <span className='MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root'></span>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
})

export default TopTableTabs
