export function setLocalStorageValue(name: string, value: any) {
  window.localStorage.setItem(name, value)
}

export function getLocalStorageValue(name: string) {
  const value = window.localStorage.getItem(name)
  if (value) {
    return value
  }
  return null
}

export function deleteLocalStorageValue(name: string) {
  window.localStorage.removeItem(name)
}
