import React from 'react'
import {observer} from 'mobx-react'
import {Modal} from 'react-bootstrap-v5'

export const DefaultModal = observer((props: any) => {
  if (!props.show) {
    setTimeout(() => {
      const el = document.getElementById('kt_body')
      if (el) {
        el.style.overflow = 'inherit'
      }
    }, 1000)
  }
  return (
    <Modal
      id={props.id}
      tabIndex={-1}
      dialogClassName={`modal-dialog-centered h-auto ${props.width ? props.width : 'mw-1000px'}`}
      show={props.show}
      onHide={() => {
        if (props.onHide) {
          props.onHide()
        }
      }}
      restoreFocusOptions={{preventScroll: false}}
    >
      {props.children}
    </Modal>
  )
})
