import {FC} from 'react'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {
  getDefaultSexOptions,
  getDefaultVisibilityOptions,
  getRandomKey,
  getTranslation,
  splitNameByExtend,
} from '../../../Helpers/Default'
import {DefaultModal} from '../../DefaultModal'
import * as React from 'react'
import {UploadFileArea} from '../../UploadFileArea'
import {DefaultButton} from '../../Buttons/DefaultButton'
import DefaultSeparator from '../../DefaultSeparator'
import {Table} from 'react-bootstrap-v5'
import {cloneDeep} from 'lodash'
import InputInline from '../../InputInline'
import SelectInline from '../../SelectInline'
import TextEditor from '../../TextEditor'
import FileStore from '../../../Stores/File'
import SwitchInline from '../../SwitchInline'

const AttachmentsModal: FC<{
  show?: boolean
  closeModal: any
  setAttachments: any
  selectedAttachments: any
  moreInfo?: boolean
  submit?: any
  title?: string
  showAcceptRead?: boolean
}> = observer(
  ({
    show = false,
    closeModal,
    setAttachments,
    selectedAttachments,
    moreInfo = false,
    submit = null,
    title = undefined,
    showAcceptRead = false,
  }) => {
    const intl = useIntl()
    const [openAttachments, setOpenAttachments] = React.useState<any>([])
    const [pomAttachments, setPomAttachments] = React.useState<any>([])

    const changeValue = (e: any, index: any) => {
      let name = e.target.name
      if (name.indexOf('_') > -1) {
        name = name.split('_')[0]
      }

      const data = pomAttachments
      data[index][name] = e.target.value
      if (name === 'isPublic') {
        setPomAttachments(cloneDeep(data))
      } else {
        setPomAttachments(data)
      }
    }

    const uploadedAttachments = (files: any) => {
      const files_list: any = []
      for (let i in files) {
        if (typeof files[i] === 'object') {
          const document = files[i]
          let name: string = document['name'] || ''
          let splitName = splitNameByExtend(name)

          const file_data = {
            file: files[i],
            extend: splitName.extend,
            name: splitName.name,
            description: '',
            isPublic: cloneDeep(getDefaultVisibilityOptions(intl)[0]),
          }
          files_list.push(file_data)
        }
      }
      if (pomAttachments.length > 0) {
        setPomAttachments(cloneDeep([...pomAttachments, ...files_list]))
        const newFileIndices = Array.from(
          {length: files_list.length},
          (_, i) => i + pomAttachments.length
        )
        setOpenAttachments([...openAttachments, ...newFileIndices])
      } else {
        setPomAttachments(cloneDeep(files_list))
        const newFileIndices = Array.from({length: files_list.length}, (_, i) => i)
        setOpenAttachments(newFileIndices)
      }
    }

    const renderSelectedFiles = () => {
      if (moreInfo) {
        return (
          <div>
            {pomAttachments.map((file: any, fileIndex: string) => {
              // @ts-ignore
              return (
                <div
                  key={getRandomKey()}
                  className={pomAttachments.length > parseInt(fileIndex) + 1 ? 'mb-5' : ''}
                >
                  <div className='accordion' id={`file_${fileIndex}`}>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id={`item_header_file_${fileIndex}`}>
                        <button
                          className='accordion-button fs-4 fw-bold-custom collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#item_body_file_${fileIndex}`}
                          aria-expanded='false'
                          aria-controls={`item_body_file_${fileIndex}`}
                          onClick={() => {
                            if (openAttachments.includes(fileIndex)) {
                              setOpenAttachments(
                                openAttachments.filter((item: any) => item !== fileIndex)
                              )
                            } else {
                              setOpenAttachments([...openAttachments, fileIndex])
                            }
                          }}
                        >
                          {file.file.name}
                        </button>
                      </h2>
                      <div
                        id={`item_body_file2_${fileIndex}`}
                        className={`accordion-collapse collapse ${
                          openAttachments.includes(fileIndex) ? 'show' : ''
                        }`}
                        key={
                          pomAttachments[fileIndex] ? pomAttachments[fileIndex].key : getRandomKey()
                        }
                      >
                        <div className='accordion-body'>
                          <InputInline
                            name={`name_${fileIndex}`}
                            value={file.name}
                            label={getTranslation('FILE.FORM.NAME', intl)}
                            placeholder={getTranslation('FILE.FORM.NAME', intl)}
                            required={false}
                            simpleTextAfter={`.${file.extend}`}
                            inputSize={'col-lg-12'}
                            formik={{errors: {}, touched: {}}}
                            changeValue={(e: any) => changeValue(e, fileIndex)}
                            readOnly={false}
                          />

                          <SelectInline
                            // @ts-ignore
                            values={pomAttachments[fileIndex]}
                            name={`isPublic`}
                            label={getTranslation('FILE.FORM.VISIBILITY', intl)}
                            placeholder={getTranslation('FILE.FORM.PLACEHOLDER', intl)}
                            formik={{errors: {}, touched: {}}}
                            required={true}
                            changeValue={(e: any) => changeValue(e, fileIndex)}
                            data={getDefaultVisibilityOptions(intl)}
                            readOnly={false}
                            option={{
                              value: 'value',
                              name: function (value: any) {
                                return value['label']
                              },
                            }}
                          />

                          {showAcceptRead ? (
                            <SwitchInline
                              label={getTranslation('FILE.FROM.ACCEPT_READ', intl)}
                              columnLabel={true}
                              name={'acceptRead'}
                              value={pomAttachments[fileIndex].acceptRead}
                              // label={
                              //   initialValues.readOnly ? getTranslation('MESSAGES.FORM.PIN_MESSAGE', intl) : null
                              // }
                              formik={{errors: {}, touched: {}}}
                              changeValue={(e: any) => changeValue(e, fileIndex)}
                              readOnly={false}
                              texts={{
                                select: getTranslation('BASE.YES', intl),
                                unselect: getTranslation('BASE.NO', intl),
                              }}
                            />
                          ) : null}

                          <TextEditor
                            name={`description_${fileIndex}`}
                            isEdit={true}
                            label={getTranslation('FILE.FORM.DESCRIPTION', intl)}
                            // key={getRandomKey()}
                            value={file.description}
                            action={(value: any) => {
                              changeValue(value, fileIndex)
                            }}
                          />
                          <div className={'w-100 mt-5 text-center'}>
                            <DefaultButton
                              onClick={() => {
                                let data = pomAttachments.filter(
                                  (item: any, index: any) => index !== fileIndex
                                )
                                setPomAttachments(cloneDeep(data))
                              }}
                              size={'sm'}
                              key={1}
                              style={'light'}
                              color={'danger'}
                              icon={<i className='fa fa-trash'></i>}
                            >
                              {getTranslation('FILE_MANAGER.ATTACHMENTS.FORM.BUTTON.REMOVE', intl)}
                            </DefaultButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
      }
      return (
        <div className='table-responsive w-100'>
          <Table className={'w-100'}>
            <thead>
              <tr>
                <th key={getRandomKey()} className='fw-bold-customer'>
                  {getTranslation('FILE_MANAGER.TABLE.TITLE.NAME', intl)}
                </th>
                <th key={getRandomKey()} className='fw-bold-customer' style={{width: '100px'}}>
                  {getTranslation('FILE_MANAGER.TABLE.TITLE.ACTION', intl)}
                </th>
              </tr>
            </thead>
            <tbody>
              {pomAttachments.length === 0 ? (
                <tr>
                  <td colSpan={2}>
                    <p className='text-center text-muted h3 py-5'>
                      {getTranslation('FILE_MANAGER.NO_FILES', intl)}
                    </p>
                  </td>
                </tr>
              ) : null}
              {pomAttachments.map((file: any, fileIndex: boolean) => {
                return (
                  <tr key={getRandomKey()}>
                    <td>
                      <div className='d-flex align-items-center fw-bold-custom'>{file.name}</div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <DefaultButton
                          onClick={() => {
                            const filterFiles = pomAttachments.filter(
                              (f: any, index: boolean) => fileIndex !== index
                            )
                            setPomAttachments(filterFiles)
                          }}
                          size={'sm'}
                          key={1}
                          style={'light'}
                          color={'danger'}
                          icon={<i className='fa fa-trash'></i>}
                        ></DefaultButton>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      )
    }

    return (
      <>
        <DefaultModal
          class={'modal-xl'}
          title={title ? title : getTranslation('FILE_MANAGER.ATTACHMENTS.FORM.TITLE', intl)}
          show={show}
          onHide={closeModal}
        >
          <div className='modal-header'>
            <h5 className='modal-title' id='exampleModalLabel'>
              {title ? title : getTranslation('FILE_MANAGER.ATTACHMENTS.FORM.TITLE', intl)}
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={closeModal}
            ></button>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-12'>
                <UploadFileArea setDocuments={uploadedAttachments} />
              </div>
            </div>
            {pomAttachments.length > 0 ? (
              <>
                <div className='row'>
                  <DefaultSeparator />
                </div>
                <div className='row'>
                  <div className='col-12'>{renderSelectedFiles()}</div>
                </div>
              </>
            ) : null}
          </div>
          <div className='modal-footer'>
            <div className='row w-100'>
              <div className='col-12'>
                <div className='w-100 d-inline-flex justify-content-between'>
                  <div>
                    <DefaultButton
                      onClick={() => {
                        closeModal()
                      }}
                      size={'sm'}
                      key={1}
                      color={'danger'}
                    >
                      {getTranslation('FILE_MANAGER.ATTACHMENTS.FORM.BUTTON.CANCEL', intl)}
                    </DefaultButton>
                  </div>
                  <div>
                    <DefaultButton
                      onClick={async () => {
                        setAttachments(cloneDeep(pomAttachments))
                        // @ts-ignore
                        if (submit !== null) {
                          await submit(cloneDeep(pomAttachments))
                        }
                        closeModal()
                      }}
                      size={'sm'}
                      key={2}
                      color={'success'}
                      disabled={pomAttachments.length === 0}
                      loading={FileStore.uploading}
                    >
                      {getTranslation('FILE_MANAGER.ATTACHMENTS.FORM.BUTTON.ADD', intl)}
                    </DefaultButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DefaultModal>
      </>
    )
  }
)

export default AttachmentsModal
