import {observer} from 'mobx-react'
import * as React from 'react'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import CardLayout from '../CardLayout'
import {getTranslation} from '../../Helpers/Default'
import PDFViewV2 from './PDF2'
import FileViewer from 'react-file-viewer'
import Papa from 'papaparse'
import OfficeDocumentViewer from './Office/OfficeDocumentViewer'

interface FileViewProps {
  file: {
    attachment: {
      type: string
      url: string
    }
  }
}

const FileView: FC<FileViewProps> = observer((props) => {
  const intl = useIntl()
  const [showView, setShowView] = useState(false)
  const [csvData, setCsvData] = useState<string[][] | null>(null)

  useEffect(() => {
    if (props.file.attachment && props.file.attachment.type === 'text/csv') {
      fetch(props.file.attachment.url)
        .then((response) => response.text())
        .then((csvText) => {
          const parsedData = Papa.parse<string[]>(csvText, {header: false})
          setCsvData(parsedData.data)
        })
        .catch((error) => {
          console.error('Error fetching CSV file:', error)
        })
    }
  }, [props.file.attachment])

  if (props.file.attachment) {
    const {type, url} = props.file.attachment

    const isPDF = type.indexOf('application/pdf') > -1
    const isDOC =
      type.indexOf('application/msword') > -1 ||
      type.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') > -1
    const isXLS =
      type.indexOf('application/vnd.ms-excel') > -1 ||
      type.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') > -1
    const isImage =
      type.indexOf('image/png') > -1 ||
      type.indexOf('image/jpg') > -1 ||
      type.indexOf('image/jpeg') > -1 ||
      type.indexOf('image/gif') > -1
    const isCSV = type === 'text/csv'

    const renderCSV = () => (
      <table>
        <tbody>
          {csvData &&
            csvData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    )

    const renderFile = () => {
      if (isPDF) {
        return <PDFViewV2 file={props.file} />
      } else if (isImage) {
        const fileType = type.split('/')[1] // Extract the file type from the MIME type
        return <PDFViewV2 file={props.file} />
        // } else if (isDOC || isXLS) {
        //   return <OfficeDocumentViewer fileUrl={url} fileType={type} />
      } else if (isCSV) {
        return renderCSV()
      } else {
        return (
          <div className={`p-4 text-center`}>Zobrazení pro tento typ souboru není podporováno.</div>
        )
      }
    }

    return (
      <div className={'mb-8'}>
        <CardLayout>
          <div className='d-inline-flex justify-content-between align-content-center w-100'>
            <div>
              <h3 className={'mb-0'}>{getTranslation('FILE_VIEW.TITLE', intl)}</h3>
            </div>
            <div>
              <div
                className={'cursor-pointer'}
                onClick={() => {
                  setShowView(!showView)
                }}
              >
                <small>
                  {getTranslation(showView ? 'DOCUMENT.SHOW_LESS' : 'DOCUMENT.SHOW_MORE', intl)}
                </small>
              </div>
            </div>
          </div>
          <div className={`${!showView ? 'd-none' : null} mt-5`}>{renderFile()}</div>
        </CardLayout>
      </div>
    )
  }
  return null
})

export default FileView
