import * as React from 'react'
import {getRandomKey, getTranslation} from '../Default'
import {KTSVG} from '../../../_metronic/helpers'

export function getDocumentIcon(file: any, type: any = null) {
  let url = ''

  if (type === 'folder') {
    return (
      <div
        className={`symbol symbol-25px d-flex align-items-center ${file.color ? file.color : ''}`}
      >
        <i className='fa fa-solid fa-folder pe-3'></i>
      </div>
    )
  } else if (type === 'link') {
    url = 'media/icons/001-file.svg'
  } else if (file.attachment) {
    url = 'media/files/' + getFileIcon(file.attachment.type)
  } else if (type === 'upload_file') {
    url = 'media/files/' + getFileIcon(file.file.type)
  } else if (type === 'attachment') {
    url = 'media/files/' + getFileIcon(file.type)
  } else if (type === 'file') {
    url = 'media/files/' + getFileIcon(file.type)
  } else {
    url = 'media/files/' + getFileIcon('null')
  }
  return (
    <div className='symbol symbol-35px'>
      <img className={'file-icon pe-3'} src={window.location.origin + '/' + url} alt={file.name} />
    </div>
  )
}

export default function getFileIcon(type: string) {
  if (type === 'application/postscript') {
    return 'ai.svg'
  } else if (type === 'video/x-msvideo') {
    return 'avi.svg'
  } else if (type === 'text/csv') {
    return 'csv.svg'
  } else if (
    type === 'application/msword' ||
    type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    return 'doc.svg'
  } else if (type === 'image/vnd.dwg') {
    return 'dwg.svg'
  } else if (type === 'image/gif') {
    return 'gif.svg'
  } else if (type === 'text/html') {
    return 'html.svg'
  } else if (
    type === 'application/vnd.ms-excel' ||
    type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    return 'xls.svg'
  } else if (type === 'image/jpeg' || type === 'image/x-citrix-jpeg') {
    return 'jpg.svg'
  } else if (type === 'application/mp4' || type === 'video/mp4' || type === 'audio/mp4') {
    return 'mp4.svg'
  } else if (type === 'application/pdf') {
    return 'pdf.svg'
  } else if (type === 'image/png' || type === 'image/x-citrix-png' || type === 'image/x-png') {
    return 'png.svg'
  } else if (
    type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
    type === 'application/vnd.ms-powerpoint'
  ) {
    return 'ppt.svg'
  } else if (type === 'image/vnd.adobe.photoshop') {
    return 'psd.svg'
  } else if (type === 'application/rtf' || type === 'text/richtext') {
    return 'rtf.svg'
  } else if (type === 'image/svg+xml') {
    return 'svg.svg'
  } else if (type === 'application/xml') {
    return 'xml.svg'
  } else if (type === 'application/zip') {
    return 'zip.svg'
  } else if (type === 'link') {
    return '001-file.svg'
  } else {
    return 'no_name_file.svg'
  }
}

export const breadcrumbArray = () => {
  return (
    <span className='svg-icon svg-icon-2x svg-icon-primary mx-1'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z'
          fill='black'
        ></path>
      </svg>
    </span>
  )
}

export const createBreadcrumb = (folderList: any, clickBreadcrumb: any) => {
  const html = [
    <>
      <span className='svg-icon svg-icon-2x svg-icon-primary me-3'>
        <KTSVG className='svg-icon svg-icon-4' path='media/icons/duotune/files/fil012.svg' />
      </span>
      {/*<span className={'me-3'}>Vaše dokumenty</span>*/}
    </>,
  ]

  for (let i in folderList) {
    const folder = folderList[i]
    html.push(
      <span key={getRandomKey()}>
        <span className='btn-link bold cursor-pointer' onClick={() => clickBreadcrumb(folder)}>
          {folder.name}
        </span>
        {parseInt(i) < folderList.length - 1 ? breadcrumbArray() : null}
      </span>
    )
  }

  return (
    <div className='d-flex flex-stack'>
      <div className='badge badge-lg badge-light-primary'>
        <div className='d-flex align-items-center flex-wrap'>{html}</div>
      </div>
    </div>
  )
}

export const getDocumentTableColumns = (intl: any) => {
  return [
    {
      header: getTranslation('MESSAGES.TABLE.TITLE.NAME', intl),
      id: 'name',
      muiSelectCheckboxProps: {},
      accessorFn: (row: any) => row.title,
      // @ts-ignore
      Cell: ({cell, row}) => {
        let item = row.original

        // let params = data;
        // if (data['color'] !== undefined) {
        //     params = {type: 'folder', color: data.color};
        // }
        if (!item.attachment) {
          let type = 'folder'
          if (item.type) {
            type = item.type
          }
          item = {...item, type: type}
        }

        return (
          <div
            className='d-inline-flex align-items-center'
            onDoubleClick={() => {}}
            onClick={() => {}}
          >
            {getDocumentIcon(item)}
            <div className=' cursor-pointer'>
              <strong>{item.name}</strong>
            </div>
          </div>
        )
      },
    },
  ]
}

export function formatBytes(bytes: any, decimals: any) {
  if (bytes === 0) return '0 Bytes'
  let k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
