import RolesAccessStore from '../../Stores/RolesAccessStore'
import {LIST_ROLES} from '../User'
import {toJS} from 'mobx'
import {getTranslation} from '../../Helpers/Default'

export const DEFAULT_ROWS_PER_PAGE = [25, 50]
export const DEFAULT_ROW_PER_PAGE = 25
export const DEFAULT_DATE_FORMAT = 'DD. MM. YYYY'
export const DEFAULT_DB_DATE_FORMAT = 'YYYY-MM-DD'
export const DEFAULT_DATE_TIME_FORMAT = 'DD. MM. YYYY, HH:mm'
export const DEFAULT_LUXOR_DATE_TIME_FORMAT = 'dd. MM. yyyy, HH:mm'
export const DEFAULT_LUXOR_DATE_FORMAT = 'dd. MM. yyyy'
export const DEFAULT_DATE_PICKER_FORMAT = 'd. m. Y'
export const DEFAULT_DATE_TIME_PICKER_FORMAT = 'd. m. Y, H:i'

export const DEFAULT_VISIBILITY_OPTIONS = [
  {value: 'public', label: 'BASE.SELECT.VISIBILITY.PUBLIC'},
  {value: 'private', label: 'BASE.SELECT.VISIBILITY.PRIVATE'},
]

export const DEFAULT_VISIBILITY_OPTIONS_MAP = {
  true: {value: true, label: 'BASE.SELECT.VISIBILITY.PUBLIC'},
  false: {value: false, label: 'BASE.SELECT.VISIBILITY.PRIVATE'},
}

export const DEFAULT_DOCUMENT_VISIBILITY_OPTIONS_MAP = {
  true: {value: true, label: 'DOCUMENT.SELECT.VISIBILITY.PUBLIC'},
  false: {value: false, label: 'DOCUMENT.SELECT.VISIBILITY.PRIVATE'},
}

export const NOT_SPECIFIED = 'BASE.NOT_SPECIFIED'

export const DEFAULT_PAGINATION_SETTINGS = {
  pageIndex: 0,
  pageSize: 15,
}

export const SEX_TYPES = [
  {
    label: 'BASE.SELECT.MAN',
    value: 'male',
  },
  {
    label: 'BASE.SELECT.WOMAN',
    value: 'female',
  },
  {
    label: 'BASE.SELECT.OTHERS',
    value: 'others',
  },
]

export const COUNTRIES = [
  {value: 'af', label: 'Afghánistán'},
  {value: 'al', label: 'Albánie'},
  {value: 'dz', label: 'Alžírsko'},
  {value: 'as', label: 'Americká Samoa'},
  {value: 'ad', label: 'Andorra'},
  {value: 'ao', label: 'Angola'},
  {value: 'ai', label: 'Anguilla'},
  {value: 'aq', label: 'Antarktida'},
  {value: 'ag', label: 'Antigua a Barbuda'},
  {value: 'ar', label: 'Argentina'},
  {value: 'am', label: 'Arménie'},
  {value: 'aw', label: 'Aruba'},
  {value: 'au', label: 'Austrálie'},
  {value: 'at', label: 'Rakousko'},
  {value: 'az', label: 'Ázerbájdžán'},
  {value: 'bs', label: 'Bahamy'},
  {value: 'bh', label: 'Bahrajn'},
  {value: 'bd', label: 'Bangladéš'},
  {value: 'bb', label: 'Barbados'},
  {value: 'by', label: 'Bělorusko'},
  {value: 'be', label: 'Belgie'},
  {value: 'bz', label: 'Belize'},
  {value: 'bj', label: 'Benin'},
  {value: 'bm', label: 'Bermudy'},
  {value: 'bt', label: 'Bhútán'},
  {value: 'bo', label: 'Bolívie'},
  {value: 'ba', label: 'Bosna a Hercegovina'},
  {value: 'bw', label: 'Botswana'},
  {value: 'bv', label: 'Bouvetův ostrov'},
  {value: 'br', label: 'Brazílie'},
  {value: 'io', label: 'Britské indickooceánské území'},
  {value: 'bn', label: 'Brunej'},
  {value: 'bg', label: 'Bulharsko'},
  {value: 'bf', label: 'Burkina Faso'},
  {value: 'bi', label: 'Burundi'},
  {value: 'kh', label: 'Kambodža'},
  {value: 'cm', label: 'Kamerun'},
  {value: 'ca', label: 'Kanada'},
  {value: 'cv', label: 'Kapverdy'},
  {value: 'ky', label: 'Kajmanské ostrovy'},
  {value: 'cf', label: 'Středoafrická republika'},
  {value: 'td', label: 'Čad'},
  {value: 'cl', label: 'Chile'},
  {value: 'cn', label: 'Čína'},
  {value: 'cx', label: 'Vánoční ostrov'},
  {value: 'cc', label: 'Kokosové ostrovy'},
  {value: 'co', label: 'Kolumbie'},
  {value: 'km', label: 'Komory'},
  {value: 'cg', label: 'Kongo - Brazzaville'},
  {value: 'cd', label: 'Kongo - Kinshasa'},
  {value: 'ck', label: 'Cookovy ostrovy'},
  {value: 'cr', label: 'Kostarika'},
  {value: 'ci', label: 'Pobřeží slonoviny'},
  {value: 'hr', label: 'Chorvatsko'},
  {value: 'cu', label: 'Kuba'},
  {value: 'cw', label: 'Curaçao'},
  {value: 'cy', label: 'Kypr'},
  {value: 'cs', label: 'Česká republika'},
  {value: 'dk', label: 'Dánsko'},
  {value: 'dj', label: 'Džibutsko'},
  {value: 'dm', label: 'Dominika'},
  {value: 'do', label: 'Dominikánská republika'},
  {value: 'ec', label: 'Ekvádor'},
  {value: 'eg', label: 'Egypt'},
  {value: 'sv', label: 'El Salvador'},
  {value: 'gq', label: 'Rovníková Guinea'},
  {value: 'er', label: 'Eritrea'},
  {value: 'ee', label: 'Estonsko'},
  {value: 'sz', label: 'Eswatini'},
  {value: 'et', label: 'Etiopie'},
  {value: 'fk', label: 'Falklandské ostrovy'},
  {value: 'fo', label: 'Faerské ostrovy'},
  {value: 'fj', label: 'Fidži'},
  {value: 'fi', label: 'Finsko'},
  {value: 'fr', label: 'Francie'},
  {value: 'gf', label: 'Francouzská Guyana'},
  {value: 'pf', label: 'Francouzská Polynésie'},
  {value: 'tf', label: 'Francouzská jižní území'},
  {value: 'ga', label: 'Gabon'},
  {value: 'gm', label: 'Gambie'},
  {value: 'ge', label: 'Gruzie'},
  {value: 'de', label: 'Německo'},
  {value: 'gh', label: 'Ghana'},
  {value: 'gi', label: 'Gibraltar'},
  {value: 'gr', label: 'Řecko'},
  {value: 'gl', label: 'Grónsko'},
  {value: 'gd', label: 'Grenada'},
  {value: 'gp', label: 'Guadeloupe'},
  {value: 'gu', label: 'Guam'},
  {value: 'gt', label: 'Guatemala'},
  {value: 'gg', label: 'Guernsey'},
  {value: 'gn', label: 'Guinea'},
  {value: 'gw', label: 'Guinea-Bissau'},
  {value: 'gy', label: 'Guyana'},
  {value: 'ht', label: 'Haiti'},
  {value: 'hm', label: 'Heardův ostrov a McDonaldovy ostrovy'},
  {value: 'va', label: 'Svatý stolec'},
  {value: 'hn', label: 'Honduras'},
  {value: 'hk', label: 'Hongkong'},
  {value: 'hu', label: 'Maďarsko'},
  {value: 'is', label: 'Island'},
  {value: 'in', label: 'Indie'},
  {value: 'id', label: 'Indonésie'},
  {value: 'ir', label: 'Írán'},
  {value: 'iq', label: 'Irák'},
  {value: 'ie', label: 'Irsko'},
  {value: 'im', label: 'Ostrov Man'},
  {value: 'il', label: 'Izrael'},
  {value: 'it', label: 'Itálie'},
  {value: 'jm', label: 'Jamajka'},
  {value: 'jp', label: 'Japonsko'},
  {value: 'je', label: 'Jersey'},
  {value: 'jo', label: 'Jordánsko'},
  {value: 'kz', label: 'Kazachstán'},
  {value: 'ke', label: 'Keňa'},
  {value: 'ki', label: 'Kiribati'},
  {value: 'kp', label: 'Korejská lidově demokratická republika'},
  {value: 'kr', label: 'Korejská republika'},
  {value: 'kw', label: 'Kuvajt'},
  {value: 'kg', label: 'Kyrgyzstán'},
  {value: 'la', label: 'Laoská lidově demokratická republika'},
  {value: 'lv', label: 'Lotyšsko'},
  {value: 'lb', label: 'Libanon'},
  {value: 'ls', label: 'Lesotho'},
  {value: 'lr', label: 'Libérie'},
  {value: 'ly', label: 'Libye'},
  {value: 'li', label: 'Lichtenštejnsko'},
  {value: 'lt', label: 'Litva'},
  {value: 'lu', label: 'Lucembursko'},
  {value: 'mo', label: 'Macao'},
  {value: 'mk', label: 'Severní Makedonie'},
  {value: 'mg', label: 'Madagaskar'},
  {value: 'mw', label: 'Malawi'},
  {value: 'my', label: 'Malajsie'},
  {value: 'mv', label: 'Maledivy'},
  {value: 'ml', label: 'Mali'},
  {value: 'mt', label: 'Malta'},
  {value: 'mh', label: 'Marshallovy ostrovy'},
  {value: 'mq', label: 'Martinik'},
  {value: 'mr', label: 'Mauretánie'},
  {value: 'mu', label: 'Mauricius'},
  {value: 'yt', label: 'Mayotte'},
  {value: 'mx', label: 'Mexiko'},
  {value: 'fm', label: 'Mikronésie'},
  {value: 'md', label: 'Moldavsko'},
  {value: 'mc', label: 'Monako'},
  {value: 'mn', label: 'Mongolsko'},
  {value: 'me', label: 'Černá Hora'},
  {value: 'ms', label: 'Montserrat'},
  {value: 'ma', label: 'Maroko'},
  {value: 'mz', label: 'Mozambik'},
  {value: 'mm', label: 'Myanmar'},
  {value: 'na', label: 'Namibie'},
  {value: 'nr', label: 'Nauru'},
  {value: 'np', label: 'Nepál'},
  {value: 'nl', label: 'Nizozemsko'},
  {value: 'nc', label: 'Nová Kaledonie'},
  {value: 'nz', label: 'Nový Zéland'},
  {value: 'ni', label: 'Nikaragua'},
  {value: 'ne', label: 'Niger'},
  {value: 'ng', label: 'Nigérie'},
  {value: 'nu', label: 'Niue'},
  {value: 'nf', label: 'Norfolk Island'},
  {value: 'mp', label: 'Severní Mariany'},
  {value: 'no', label: 'Norsko'},
  {value: 'om', label: 'Omán'},
  {value: 'pk', label: 'Pákistán'},
  {value: 'pw', label: 'Palau'},
  {value: 'ps', label: 'Palestina'},
  {value: 'pa', label: 'Panama'},
  {value: 'pg', label: 'Papua-Nová Guinea'},
  {value: 'py', label: 'Paraguay'},
  {value: 'pe', label: 'Peru'},
  {value: 'ph', label: 'Filipíny'},
  {value: 'pn', label: 'Pitcairn Islands'},
  {value: 'pl', label: 'Polsko'},
  {value: 'pt', label: 'Portugalsko'},
  {value: 'pr', label: 'Portoriko'},
  {value: 'qa', label: 'Katar'},
  {value: 're', label: 'Réunion'},
  {value: 'ro', label: 'Rumunsko'},
  {value: 'ru', label: 'Rusko'},
  {value: 'rw', label: 'Rwanda'},
  {value: 'bl', label: 'Saint Barthélemy'},
  {value: 'sh', label: 'Svatá Helena'},
  {value: 'kn', label: 'Svatý Kitts a Nevis'},
  {value: 'lc', label: 'Svatá Lucie'},
  {value: 'mf', label: 'Svatý Martin'},
  {value: 'pm', label: 'Saint Pierre a Miquelon'},
  {value: 'vc', label: 'Svatý Vincenc a Grenadiny'},
  {value: 'ws', label: 'Samoa'},
  {value: 'sm', label: 'San Marino'},
  {value: 'st', label: 'Svatý Tomáš a Princův ostrov'},
  {value: 'sa', label: 'Saúdská Arábie'},
  {value: 'sn', label: 'Senegal'},
  {value: 'rs', label: 'Srbsko'},
  {value: 'sc', label: 'Seychely'},
  {value: 'sl', label: 'Sierra Leone'},
  {value: 'sg', label: 'Singapur'},
  {value: 'sx', label: 'Sint Maarten'},
  {value: 'sk', label: 'Slovensko'},
  {value: 'si', label: 'Slovinsko'},
  {value: 'sb', label: 'Šalamounovy ostrovy'},
  {value: 'so', label: 'Somálsko'},
  {value: 'za', label: 'Jihoafrická republika'},
  {value: 'gs', label: 'Jižní Georgie a Jižní Sandwichovy ostrovy'},
  {value: 'ss', label: 'Jižní Súdán'},
  {value: 'es', label: 'Španělsko'},
  {value: 'lk', label: 'Srí Lanka'},
  {value: 'sd', label: 'Súdán'},
  {value: 'sr', label: 'Surinam'},
  {value: 'sj', label: 'Špicberky a Jan Mayen'},
  {value: 'se', label: 'Švédsko'},
  {value: 'ch', label: 'Švýcarsko'},
  {value: 'sy', label: 'Sýrie'},
  {value: 'tw', label: 'Tchaj-wan'},
  {value: 'tj', label: 'Tádžikistán'},
  {value: 'tz', label: 'Tanzanie'},
  {value: 'th', label: 'Thajsko'},
  {value: 'tl', label: 'Timor-Leste'},
  {value: 'tg', label: 'Togo'},
  {value: 'tk', label: 'Tokelau'},
  {value: 'to', label: 'Tonga'},
  {value: 'tt', label: 'Trinidad a Tobago'},
  {value: 'tn', label: 'Tunisko'},
  {value: 'tr', label: 'Turecko'},
  {value: 'tm', label: 'Turkmenistán'},
  {value: 'tc', label: 'Turks a Caicos'},
  {value: 'tv', label: 'Tuvalu'},
  {value: 'ug', label: 'Uganda'},
  {value: 'ua', label: 'Ukrajina'},
  {value: 'ae', label: 'Spojené arabské emiráty'},
  {value: 'gb', label: 'Velká Británie'},
  {value: 'us', label: 'Spojené státy'},
  {value: 'um', label: 'Menší odlehlé ostrovy USA'},
  {value: 'uy', label: 'Uruguay'},
  {value: 'uz', label: 'Uzbekistán'},
  {value: 'vu', label: 'Vanuatu'},
  {value: 've', label: 'Venezuela'},
  {value: 'vn', label: 'Vietnam'},
  {value: 'vg', label: 'Britské Panenské ostrovy'},
  {value: 'vi', label: 'Panenské ostrovy (USA)'},
  {value: 'wf', label: 'Wallis a Futuna'},
  {value: 'eh', label: 'Západní Sahara'},
  {value: 'ye', label: 'Jemen'},
  {value: 'zm', label: 'Zambie'},
  {value: 'zw', label: 'Zimbabwe'},
  {value: 'af', label: 'Afrika'},
]

export const getAllSvjRoles = (disabledItems: string[] = []) => {
  if (!RolesAccessStore.data) return []

  const roles = [
    ...toJS(RolesAccessStore.data.defaultRoles),
    ...toJS(RolesAccessStore.data.customRoles),
  ]

  return roles.map((role: any) => ({
    label: role.title,
    disabled: disabledItems.includes(role.name),
    // translate: role.translate ?? false,
    // order: role.order ?? null,
    value: role.id,
    name: role.name,
  }))
}

export const getGroupsAllSvjRoles = (disabledItems: string[] = [], intl: any) => {
  if (!RolesAccessStore.data) return []

  return [
    {
      label: getTranslation('BASE.ROLES.SELECT.DEFAULT', intl),
      options: toJS(RolesAccessStore.data.ownersRoles).map((role: any) => ({
        label: role.title,
        disabled: disabledItems.includes(role.name),
        // translate: role.translate ?? false,
        // order: role.order ?? null,
        value: role.id,
        name: role.name,
      })),
    },
    {
      label: getTranslation('BASE.ROLES.SELECT.COMMITTEE', intl),
      options: toJS(RolesAccessStore.data.committeeRoles).map((role: any) => ({
        label: role.title,
        disabled: disabledItems.includes(role.name),
        // translate: role.translate ?? false,
        // order: role.order ?? null,
        value: role.id,
        name: role.name,
      })),
    },
    {
      label: getTranslation('BASE.ROLES.SELECT.AUDIT_COMMITTEE', intl),
      options: toJS(RolesAccessStore.data.auditCommitteeRoles).map((role: any) => ({
        label: role.title,
        disabled: disabledItems.includes(role.name),
        // translate: role.translate ?? false,
        // order: role.order ?? null,
        value: role.id,
        name: role.name,
      })),
    },
    {
      label: getTranslation('BASE.ROLES.SELECT.CUSTOM', intl),
      options: toJS(RolesAccessStore.data.customRoles).map((role: any) => ({
        label: role.title,
        disabled: disabledItems.includes(role.name),
        // translate: role.translate ?? false,
        // order: role.order ?? null,
        value: role.id,
        name: role.name,
      })),
    },
  ]
}

export const mapRoleTranslation = (role: any) => {
  switch (role.name) {
    case 'ROLE_ADMIN':
      return 'ADMIN'
    case 'ROLE_USER':
  }
}
