import React, {FC} from 'react'
import {observer} from 'mobx-react'
import {usePageData} from '../../../_metronic/layout/core'
import {RightPanelDefault} from '../../../_metronic/partials/layout/RightPanel/RightPanel'

const RightPanelWrapper: FC = observer((props: any) => {
  const {pageRightPanel, showPageRightPanel} = usePageData()
  return (
    <RightPanelDefault
      title={'Form funkce'}
      show={showPageRightPanel || false}
      close={() => {}}
      right_buttons={[]}
      left_buttons={[]}
    >
      {pageRightPanel}
    </RightPanelDefault>
  )
})

export default RightPanelWrapper
