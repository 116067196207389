import {getTranslation} from '../../../Helpers/Default'
import {selectFilter, textFilter} from 'react-bootstrap-table2-filter'
import {getTableSelectFilter, getTableTextFilter} from '../../../Constants/Table'
import {hasCrudRole} from '../../../Helpers/User'
import {ShowLess1024px} from '../../Responsible'

const createColumn = (
  config: any,
  filters: any,
  changeFilter: any,
  intl: any,
  additionalData: any
) => {
  if ((config.visible !== undefined && !config.visible) || config.hidden) {
    return null
  }
  const filterOptions =
    config.filterType === 'select' ? {options: additionalData.selectOptions[config.dataField]} : {}
  const filterObject: any = {
    text: textFilter,
    select: selectFilter,
  }

  const FilterComponent = config.filterType ? filterObject[config.filterType] : null
  const filterStyle: any = {
    text: getTableTextFilter(intl),
    select: getTableSelectFilter(intl),
  }

  const column: any = {
    dataField: config.dataField,
    text: getTranslation(config.textKey, intl),
    filter: FilterComponent
      ? FilterComponent({
          ...filterStyle[config.filterType],
          defaultValue: filters?.[config.dataField] ?? '',
          onFilter: (filterValue: any) => {
            if (filterValue !== filters?.[config.dataField]) {
              changeFilter(filterValue, config.dataField)
            }
          },
          ...filterOptions,
        })
      : null,
    filterValue: config.filterValueGetter,
    formatter: config.formatter,
    className: config.className || '',
    classes: `${config.goToDetail ? 'cursor-pointer' : ''} ${config.classes ? config.classes : ''}`,
    hidden: config.hidden ?? null,
    sort: config.hidden ?? null,
  }

  if (config.dataField === 'action') {
    if (ShowLess1024px()) {
      column.headerStyle = {width: '30px'}
    } else if (config.width) {
      column.headerStyle = {width: config.width}
    } else {
      column.headerStyle = {width: '175px'}
    }
    if (config.labelClass) {
      column.headerStyle = {width: column.headerStyle.width, textAlign: 'right'}
    }
  } else {
    if (config.width) {
      column.headerStyle = {width: config.width}
    }
  }

  if (config.goToDetail) {
    column.events = {
      onClick: config.goToDetail,
    }
  }
  return column
}

export default createColumn
