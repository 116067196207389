import {observer} from 'mobx-react'
import React, {FC} from 'react'
import clsx from 'clsx'
import {InputLabel} from '../InputLabel'
import {getTranslation} from '../../Helpers/Default'
import {PasswordStrengthMeter} from './PasswordStrengthMeter'

const InputColumn: FC<{
  id?: string
  value: any
  formik?: any
  className?: string
  required?: boolean
  name: string
  placeholder?: string
  label?: string
  inputBold?: boolean
  type?: string
  changeValue: any
  readOnly?: boolean
  onBlur?: any
  description?: string
  innerRef?: any
  groupAfterText?: string
  disabled?: boolean
  simpleTextAfter?: string
  labelSize?: string
  inputSize?: string
  isInline?: boolean
  min?: number
  groupAfterClass?: string
  disableHeight?: boolean
  passwordBar?: boolean
}> = observer((props: any) => {
  const isInline = props.isInline ?? false
  let labelSize = props.labelSize ?? 'col-2'
  let inputSize = props.inputSize ?? 'col-10'
  let labelClass = ['fw-bold-custom fw-bold-custom form-label']
  let inputClass = ['form-control', 'form-control-lg', 'form-control-solid']
  let disabled = props.disabled ?? false
  let groupAfterClass = props.groupAfterClass ?? ''
  let disableHeight = props.disableHeight ?? false
  let inputBold = props.inputBold ?? false
  let passwordBar = props.passwordBar ?? false

  if (props.required) {
    labelClass.push('required')
    inputClass.push('required')
  }

  if (props.className) {
    inputClass.push(props.className)
  }

  if (props.formik) {
    inputClass.push(
      clsx(
        '',
        {'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name]},
        {'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name]}
      )
    )
  }

  const getInput = () => {
    return (
      <input
        ref={props.innerRef ?? null}
        id={props.id ?? props.name}
        disabled={disabled}
        type={props.type ?? 'text'}
        className={inputClass.join(' ')}
        placeholder={props.placeholder ?? ''}
        name={props.name}
        defaultValue={props.value}
        onChange={props.changeValue}
        onBlur={(changes) => {
          if (props.onBlur) {
            props.onBlur(changes)
          }
        }}
      />
    )
  }

  if (props.readOnly) {
    return (
      <div className={`mb-5 ${!disableHeight ? 'h-40px' : ''}`}>
        <div className={'w-100 h-100 d-inline-flex align-items-center'}>
          {props.label ? (
            <InputLabel
              className={`${labelClass.join(' ')} fw-bold-custom text-muted`}
              readOnly={props.readOnly}
            >
              {props.label}
            </InputLabel>
          ) : null}
          <div
            className={`${props.label ? 'col-7' : 'col-12'} ${inputBold ? 'fw-bold-custom' : ''}`}
          >
            <span className=' fs-6 text-dark'>{props.value ?? '-'}</span>
          </div>
        </div>
      </div>
    )
  }

  if (isInline) {
    return (
      <div className={`mb-5 ${!disableHeight ? 'h-40px' : ''}`}>
        <div className='form-group row'>
          {props.label ? (
            <InputLabel className={`${labelClass.join(' ')} ${labelSize} col-form-label`}>
              {props.label}
            </InputLabel>
          ) : null}
          <div className={`${inputSize}`}>{getInput()}</div>
        </div>
      </div>
    )
  }

  return (
    <div className='mb-5'>
      {props.label ? <InputLabel className={labelClass.join(' ')}>{props.label}</InputLabel> : null}

      {props.groupAfterText ? (
        <div className='input-group mb-3'>
          {getInput()}
          <span className={`input-group-text ${groupAfterClass} border-0`} id='basic-addon2'>
            {props.groupAfterText}
          </span>
        </div>
      ) : (
        <>
          {props.simpleTextAfter ? (
            <div className='d-inline-flex align-items-center '>
              {getInput()} <span className='ps-2'>{props.simpleTextAfter}</span>
            </div>
          ) : (
            getInput()
          )}
        </>
      )}
      {passwordBar ? (
        <div className={'mt-2'}>
          <PasswordStrengthMeter password={props.value || ''} />
        </div>
      ) : null}

      {props.formik && props.formik.touched[props.name] && props.formik.errors[props.name] && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <div className='fv-help-block'> {props.formik.errors[props.name]}</div>
        </div>
      )}
      {props.description ? <div className={'form-text'}>{props.description}</div> : null}
    </div>
  )
})

export default InputColumn
