export const REPORT_CATEGORY_TYPE_MAP = {
  fault: {
    value: 'fault',
    label: 'REPORT.CATEGORY.FAULT',
  },
  repair: {
    value: 'repair',
    label: 'REPORT.CATEGORY.REPAIR',
  },
  complaint: {
    value: 'complaint',
    label: 'REPORT.CATEGORY.COMPLAINT',
  },
  request: {
    value: 'request',
    label: 'REPORT.CATEGORY.REQUEST',
  },
  other: {
    value: 'other',
    label: 'REPORT.CATEGORY.OTHER',
  },
}

export const REPORT_VISIBILITY_TYPE_MAP = {
  private: {
    value: 'private',
    label: 'REPORT.FORM.VISIBILITY.NO_PUBLIC',
  },
  public: {
    value: 'public',
    label: 'REPORT.FORM.VISIBILITY.PUBLIC',
  },
}

export const REPORT_CATEGORY_TYPE = {
  fault: 'fault',
  repair: 'repair',
  complaint: 'complaint',
  request: 'request',
  other: 'other',
}

export const REPORT_STATUS_TYPE = {
  new: 'new',
  solving: 'solving',
  solved: 'solved',
  archive: 'archive',
}

export const REPORT_STATUS_TYPE_MAP = {
  new: {
    value: 'new',
    label: 'REPORT.STATUS.NEW',
  },
  solving: {
    value: 'solving',
    label: 'REPORT.STATUS.SOLVING',
  },
  solved: {
    value: 'solved',
    label: 'REPORT.STATUS.SOLVED',
  },
  archive: {
    value: 'archive',
    label: 'REPORT.STATUS.ARCHIVE',
  },
}

export const REPORT_CATEGORY = {
  fault: 'REPORT.CATEGORY.FAULT',
  repair: 'REPORT.CATEGORY.REPAIR',
  complaint: 'REPORT.CATEGORY.COMPLAINT',
  request: 'REPORT.CATEGORY.REQUEST',
  other: 'REPORT.CATEGORY.OTHER',
}

export const REPORT_STATUS = {
  new: 'REPORT.STATUS.NEW',
  solving: 'REPORT.STATUS.SOLVING',
  solved: 'REPORT.STATUS.SOLVED',
  archive: 'REPORT.STATUS.ARCHIVE',
}
