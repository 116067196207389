import {action, makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {hasModule} from '../../Helpers/Default'
import {MEETING_OWNERS_MODULE, ROOMS_MODULE} from '../../Constants/Modules'
import {IsDemo} from '../../Helpers/Demo'

class RoomStore {
  isLoading: boolean = false
  isRemoving: boolean = false
  isCreating: boolean = false
  isGetting: boolean = false
  isSearching: boolean = false
  rooms: any = []
  roomsCount: number = 0

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
      create: action,
      getAll: action,
      get: action,
    })
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async create(data: any) {
    if (IsDemo()) {
      return false
    }
    if (this.isCreating) {
      return null
    }

    this.setValue('isCreating', true)
    const res = await apiRequest(
      '/api/v2/room',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    this.setValue('isCreating', false)
    return res.status === 200
  }

  async update(data: any) {
    if (IsDemo()) {
      return false
    }
    if (this.isCreating) {
      return null
    }

    this.setValue('isCreating', true)
    const res = await apiRequest(
      `/api/v2/room/${data.id}`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    this.setValue('isCreating', false)
    return res.status === 200
  }

  async getAll() {
    this.setValue('isLoading', true)
    const res = await apiRequest(
      '/api/v2/room',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res?.data) {
      for (let i in res.data.rooms) {
        let room = res.data.rooms[i]
        if (room.svj_users.length > 0) {
          room['owners_list'] =
            room.svj_users
              ?.map((dd: any) => {
                return dd.firstName
              })
              .join(', ') ?? ''
        }
      }
      this.setValue('rooms', res.data.rooms)
    } else {
      this.setValue('rooms', [])
    }
    this.setValue('isLoading', false)
  }

  async get(id: string) {
    const res = await apiRequest(
      `/api/v2/room/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res.data) {
      return res.data
    }
  }

  async remove(id: string) {
    if (IsDemo()) {
      return false
    }
    if (this.isRemoving) {
      return null
    }

    this.setValue('isRemoving', true)
    const res = await apiRequest(
      `/api/v2/room/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isRemoving', false)
    return res.status === 200
  }

  async search(text: string) {
    if (!hasModule(ROOMS_MODULE)) {
      return []
    }
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/room/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSearching', false)
    return res.data ?? []
  }
}
export default new RoomStore()
