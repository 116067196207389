import {makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {createFormData, hasModule} from '../../Helpers/Default'
import {DOCUMENTS_MODULE, MEETING_OWNERS_MODULE} from '../../Constants/Modules'
import {IsDemo} from '../../Helpers/Demo'

class MeetingOfOwnersStore {
  constructor() {
    makeAutoObservable(this)
  }
  allCount = 0
  conceptCount = 0
  finishedCount = 0
  scheduledCount = 0
  data = []
  isLoading = false
  isCreating = false
  isUpdating = false
  isSearching = false

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async getAll(tab = 'all') {
    this.setValue('isLoading', true)
    const res = await apiRequest(
      `/api/v2/meeting-of-owners?tab=${tab}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res.data) {
      this.setValue('data', res.data.data)
      this.setValue('allCount', res.data.allCount)
      this.setValue('conceptCount', res.data.conceptCount)
      this.setValue('finishedCount', res.data.finishedCount)
      this.setValue('scheduledCount', res.data.scheduledCount)
    }
    this.setValue('isLoading', false)
  }

  async get(id: string) {
    this.setValue('isLoading', true)
    const res = await apiRequest(
      `/api/v2/meeting-of-owners/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    this.setValue('isLoading', false)

    return res.data
  }

  async create(data: any, attachments: any) {
    if (IsDemo()) {
      return false
    }
    if (this.isCreating) {
      return false
    }
    this.setValue('isCreating', true)
    const res = await apiRequest(
      '/api/v2/meeting-of-owners',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    const promises = []
    for (const file of attachments) {
      const formData = createFormData(file)

      promises.push(
        apiRequest(
          `/api/v2/meeting-of-owners/upload/${res.data.id}`,
          REQ_TYPE.post,
          // @ts-ignore
          formData,
          // @ts-ignore
          getHeaders(null, {'content-type': 'multipart/form-data'}),
          this,
          ''
        )
      )
    }
    await Promise.all(promises)
    this.setValue('isCreating', false)
    return res.status === 200
  }

  async update(id: any, data: any, attachments: any) {
    if (IsDemo()) {
      return false
    }
    if (this.isUpdating) {
      return false
    }
    this.setValue('isUpdating', true)

    const promises = []
    for (const file of attachments) {
      const formData = createFormData(file)

      promises.push(
        apiRequest(
          `/api/v2/meeting-of-owners/upload/${id}`,
          REQ_TYPE.post,
          // @ts-ignore
          formData,
          // @ts-ignore
          getHeaders(null, {'content-type': 'multipart/form-data'}),
          this,
          ''
        )
      )
    }
    await Promise.all(promises)
    const res = await apiRequest(
      `/api/v2/meeting-of-owners/${id}`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isUpdating', false)
    return res.status === 200
  }

  async finish(id: any) {
    if (IsDemo()) {
      return false
    }
    if (this.isUpdating) {
      return false
    }
    this.setValue('isUpdating', true)
    const res = await apiRequest(
      `/api/v2/meeting-of-owners/${id}/finish`,
      REQ_TYPE.put,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    this.setValue('isUpdating', false)
    return res.status === 200
  }

  async remove(id: string) {
    if (IsDemo()) {
      return false
    }
    if (this.isUpdating) {
      return false
    }
    this.setValue('isUpdating', true)
    const res = await apiRequest(
      `/api/v2/meeting-of-owners/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    this.setValue('isUpdating', false)
    return res.status === 200
  }

  async search(text: string) {
    if (!hasModule(MEETING_OWNERS_MODULE)) {
      return []
    }
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/meeting-of-owners/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSearching', false)
    return res.data ?? []
  }
}
export default new MeetingOfOwnersStore()
