import React from 'react'
import {observer} from 'mobx-react'
import {getRandomKey} from '../../../Helpers/Default'
import {TooltipDefault} from '../../Tooltip'
import {ShowMore375px} from '../../Responsible'

export const DefaultButton = observer((props: any) => {
  let button = ''
  let type = ''
  let style = ''
  let size = ''
  let className = ''
  let color: string = 'primary'
  let content = props.children
  let onClick = props.onClick
  let tooltipPosition = 'top'
  let pomType = props.type
  if (props.tooltipPosition) {
    tooltipPosition = props.tooltipPosition
  }

  // if (props.type) {
  //   type = props.type + '-'
  // }

  if (props.style) {
    style = props.style + '-'
  }

  if (props.color) {
    color = props.color
  }

  if (props.size) {
    size = 'btn-' + props.size
  }

  if (props.className) {
    className = props.className
  }

  if (props.disabled) {
    className += ' disabled'
    onClick = null
  }

  button = 'btn-' + type + style + color

  if (props.loading) {
    content = <span className={`spinner-border spinner-border-sm align-middle`}></span>
    onClick = null
    pomType = null
  }
  const getButton = () => {
    return (
      <button
        disabled={props.disabled || false}
        id={props.id ?? getRandomKey()}
        className={`btn ${size} ${button} cursor-pointer ${className} d-inline-flex align-items-center justify-content-center`}
        type={pomType ?? 'button'}
        onClick={onClick}
      >
        {!props.loading && props.icon ? props.icon : null}
        {ShowMore375px() ? (
          <span className={props.icon && content ? 'ms-1' : ''}>{content}</span>
        ) : !props.icon ? (
          <span>{content}</span>
        ) : null}
      </button>
    )
  }
  if (!props.tooltip) {
    return getButton()
  }

  return (
    <TooltipDefault position={tooltipPosition} tooltip={props.tooltip}>
      <span>{getButton()}</span>
    </TooltipDefault>
  )
})
