import {observer} from 'mobx-react'
import * as React from 'react'
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {getTranslation, shortenText} from '../../../Helpers/Default'
import SectionTitle from '../../../Components/SectionTitle'
import {KTSVG} from '../../../../_metronic/helpers'
import {LinkButton} from '../../../Components/LinkButton'
import DashboardStore from '../../../Stores/Dashboard'
import {getColorLabel} from '../../../Helpers/Calendar'
import {renderPeriod} from '../../../Helpers/Messages'
import {TooltipDefault} from '../../../Components/Tooltip'
import {Link} from 'react-router-dom'

const CalendarWidget: FC<{}> = observer(() => {
  const intl = useIntl()

  const renderCalendarEvents = () => {
    return DashboardStore.events.map((event: any) => {
      const {date, time} = renderPeriod(event.start, event.end)
      return (
        <div className='d-flex align-items-center mb-6'>
          <span
            data-kt-element='bullet'
            className={`bullet bullet-vertical d-flex align-items-center min-h-50px mh-100 me-4 bg-${getColorLabel(
              event.label.type
            )}`}
          ></span>

          <div className='flex-grow-1 me-5'>
            <div className='text-gray-800 fw-bold-custom'>
              {date}
              {!event.allDay ? (
                <span className='text-gray-500 fw-semibold fs-7 ps-3'>{time} </span>
              ) : null}
            </div>

            <div className='fw-bold-custom fs-5'>{shortenText(event.title, 10)}</div>

            {/*<div className='text-gray-500 fw-semibold fs-7'>*/}
            {/*  Lead by*/}
            {/*  <a href='#' className='text-primary opacity-75-hover fw-semibold'>*/}
            {/*    Peter Marcus*/}
            {/*  </a>*/}
            {/*</div>*/}
          </div>
          <TooltipDefault tooltip={getTranslation('DASHBOARD.CALENDAR.SHOW_EVENT', intl)}>
            <a className='text-hover-warning ms-4 detail-profil' href={event.url}>
              <i className='fa fa-search fs-6'></i>
            </a>
          </TooltipDefault>
          {/*<LinkButton*/}
          {/*  link={event.url}*/}
          {/*  color={'light'}*/}
          {/*  icon={<i className='fa fa-search fs-6'></i>}*/}
          {/*  tooltip={getTranslation('DASHBOARD.CALENDAR.SHOW_EVENT', intl)}*/}
          {/*/>*/}
        </div>
      )
    })
  }

  return (
    <>
      <div className='card h-md-100'>
        <div className='card-header border-0 pt-5'>
          <div className='border-bottom w-100 d-inline-flex justify-content-between pb-3'>
            <h3 className='card-title align-items-start align-items-center'>
              <KTSVG path={'/media/icons/duotune/general/gen014.svg'} className='svg-icon-1' />
              <span className='card-label fw-bold-custom text-gray-900 ps-3'>
                {getTranslation('DASHBOARD.CALENDAR.EVENT_ON_MONTH', intl)}
              </span>
            </h3>

            <div className='card-toolbar'>
              <Link to={'/kalendar-udalosti'} className='text-muted text-decoration-underline'>
                {getTranslation('DASHBOARD.CALENDAR.SHOW_ALL_EVENTS', intl)}
              </Link>
            </div>
          </div>
        </div>

        {DashboardStore.events.length === 0 ? (
          <div className={'card-body justify-align-center align-items-center d-flex'}>
            <div className={'w-100 text-center d-flex'}>
              <span className='text-black fs-7 w-100 text-muted lh-base'>
                {getTranslation('DASHBOARD.EVENT.NO_EVENTS', intl)}
              </span>
            </div>
          </div>
        ) : (
          <div className='card-body pt-7 px-0'>
            <div className='tab-content mb-2 px-9'>
              <div
                className='tab-pane fade  show active '
                id='kt_timeline_widget_3_tab_content_1'
                role='tabpanel'
              >
                {renderCalendarEvents()}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
})

export default CalendarWidget
