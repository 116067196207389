import React, {FC} from 'react'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {getRandomKey, getTranslation} from '../../Helpers/Default'
import {DefaultButton} from '../Buttons/DefaultButton'
import ICalendarLink from 'react-icalendar-link'

const DownloadICSButton: FC<{event: any; className?: string}> = observer((props) => {
  const intl = useIntl()
  let localClassName = props.className ? props.className : ''

  let icsData: any = {
    title: props.event.title,
    description: props.event.place_description
      ? props.event.place_description.replace(/<\/?[^>]+(>|$)/g, '')
      : '',
    startTime: new Date(props.event.start),
    endTime: new Date(props.event.end),
    location: props.event.place_address
      ? props.event.place_address.replace(/<\/?[^>]+(>|$)/g, '')
      : '',
  }

  return (
    <ICalendarLink event={icsData} filename={icsData.title.replace(/ /g, '_')}>
      <DefaultButton
        size={'sm'}
        color={'info'}
        className={`${localClassName} cursor-pointer`}
        key={getRandomKey()}
        icon={<i className='bi bi-calendar3 pe-0'></i>}
        tooltip={getTranslation('CALENDAR.BUTTONS.DOWNLOAD_ICS', intl)}
      ></DefaultButton>
    </ICalendarLink>
  )
})

export default DownloadICSButton
