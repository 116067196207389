import {action, makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'

class AcceptReadStore {
  isLoading = false
  isAccepting = false
  data: any = []

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
      getAll: action,
    })
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async getAll() {
    if (this.isLoading) {
      return
    }
    this.setValue('isLoading', true)
    const res = await apiRequest(
      '/api/v2/accept-read',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    if (res?.data) {
      this.setValue('data', res.data)
    } else {
      this.setValue('data', [])
    }

    this.setValue('isLoading', false)
  }

  async accept(id: string) {
    if (this.isAccepting) {
      return
    }
    this.setValue('isAccepting', true)
    const res = await apiRequest(
      '/api/v2/accept-read/accept/' + id,
      REQ_TYPE.put,
      // @ts-ignore
      {},
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    this.setValue('isAccepting', false)
    return res.status === 200
  }
}

export default new AcceptReadStore()
