import {FC, useRef, useEffect, useState} from 'react'
import {connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {RootState} from '../../../../setup'
import AuthStore from '../../../Stores/Auth'
import {
  deleteLocalStorageValue,
  getLocalStorageValue,
  setLocalStorageValue,
} from '../../../Helpers/LocalStorage'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

// redirect to login page if token is not valid
window.addEventListener('pageshow', function (event) {
  if (event.persisted) {
    let accessToken = AuthStore.getToken()
    if (!accessToken) {
      window.location.href = '/prihlasit'
    }
  }
})

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  let accessToken = AuthStore.getToken()
  // console.log(token2, accessToken)
  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const currentUrl = window.location.href

          if (!currentUrl.includes('aktivace')) {
            const urlParams = new URLSearchParams(new URL(currentUrl).search)
            const svjSessionId = urlParams.get('svj')
            const localSvjSessionId = getLocalStorageValue('svj')
            if (svjSessionId && svjSessionId !== localSvjSessionId) {
              setLocalStorageValue('svj', svjSessionId)
            }
            await AuthStore.verify({})
            // dispatch(props.fulfillUser(user))
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
