import {getTranslation} from '../../Helpers/Default'

export const getTableTextFilter = (intl: any) => {
  return {
    className: ' form-control form-control-sm form-control-solid',
    placeholder: getTranslation('BASE.FILTER.PLACEHOLDER.SEARCH', intl),
  }
}

export const getTableSelectFilter = (intl: any) => {
  return {
    className: ' form-select form-select-solid form-select-sm',
    placeholder: getTranslation('BASE.FILTER.PLACEHOLDER.SELECT', intl),
  }
}
