import {action, makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {IsDemo} from '../../Helpers/Demo'

class OrderStore {
  data = []
  isLoading = false
  isCreating = false
  isLoadingEmployee = false
  isUpdating = false
  constructor() {
    makeAutoObservable(this, {
      setValue: action,
      getAll: action,
      get: action,
    })
  }

  setValue = (key: string, value: any) => {
    // @ts-ignore
    this[key] = value
  }

  create = async (data: any) => {
    if (IsDemo()) {
      return false
    }
    this.setValue('isCreating', true)
    const response = await apiRequest(
      `/api/v2/order`,
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isCreating', false)

    return response.status === 200
  }

  getForm = async (urlId: any) => {
    this.setValue('isLoading', true)
    const response = await apiRequest(
      `/api/v2/order/form/${urlId}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoading', false)
    if (response.status !== 200) {
      return null
    }
    return response.data
  }

  getCheckForm = async (urlId: any) => {
    this.setValue('isLoading', true)
    const response = await apiRequest(
      `/api/v2/order/form/${urlId}/check`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoading', false)
    if (response.status !== 200) {
      return null
    }
    return response.data
  }

  get = async (id: any) => {
    this.setValue('isLoading', true)
    const response = await apiRequest(
      `/api/v2/order/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoading', false)

    return response.status === 200
  }

  getEmployee = async () => {
    this.setValue('isLoadingEmployee', true)
    const response = await apiRequest(
      `/api/v2/order/employee`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoadingEmployee', false)

    return response.data
  }

  getAll = async () => {
    this.setValue('isLoading', true)
    const response = await apiRequest(
      `/api/v2/order`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoading', false)
    this.setValue('data', response.data)
    return response.status === 200
  }

  newToken = async (id: string) => {
    if (IsDemo()) {
      return false
    }
    this.setValue('isUpdating', true)
    const response = await apiRequest(
      `/api/v2/order/${id}/new-token`,
      REQ_TYPE.put,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isUpdating', false)

    return response.status === 200
  }

  async fillOrder(data: any, id: string) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isUpdating', true)
    const response = await apiRequest(
      `/api/v2/order/${id}/fill`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isUpdating', false)

    return response.status === 200
  }

  async acceptOrder(data: any, id: string) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isUpdating', true)
    const response = await apiRequest(
      `/api/v2/order/${id}/accept`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isUpdating', false)

    return response.status === 200
  }
}

export default new OrderStore()
