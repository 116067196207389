import {getTranslation} from '../Default'
import {getFullNameSimple} from '../User'
import moment from 'moment/moment'
import {DEFAULT_DATE_TIME_FORMAT} from '../../Constants/Defatul'
import {DefaultBadge} from '../../Components/Badge'
import * as React from 'react'
import createColumn from '../../Components/DefaultTableMui/Columns'

export const acceptReadsColumns = (intl: any) => {
  const columnConfigs = [
    {
      dataField: 'user',
      textKey: 'CALENDAR.FORM.ACCEPT_READ.TITLE.USER',
      formatter: (cellContent: any, row: any) => {
        return row.user ? getFullNameSimple(row.user) : '-'
      },
    },

    {
      dataField: 'isRead',
      textKey: 'CALENDAR.FORM.ACCEPT_READ.TITLE.STATUS',
      width: '220px',
      formatter: (cellContent: any, row: any) => {
        return (
          <div className='d-inline-flex align-content-center'>
            <div className='d-flex'>
              <DefaultBadge color={row.isRead ? 'success' : 'secondary'}>
                {getTranslation(
                  row.isRead
                    ? 'CALENDAR.FORM.ACCEPT_READ.READ'
                    : 'CALENDAR.FORM.ACCEPT_READ.UN_READ',
                  intl
                )}
              </DefaultBadge>
            </div>
            <div className='d-flex'>
              <span className='ps-2 ps-3'>
                {row.isRead ? moment(row.readDate).format(DEFAULT_DATE_TIME_FORMAT) : null}
              </span>
            </div>
          </div>
        )
      },
    },
  ]

  return Object.keys(columnConfigs).map((key) =>
    createColumn(
      // @ts-ignore
      columnConfigs[key],
      () => {},
      () => {},
      intl,
      {}
    )
  )
}
