import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {getTranslation} from '../../../Helpers/Default'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import AuthStore from '../../../Stores/Auth'
import {checkFormErrors, checkValueError} from '../../../Helpers/Form'
import {isEmpty} from 'lodash'
import {confirmDialog} from '../../../Helpers/ConfirmDialog'
import * as yup from 'yup'
import InputColumn from '../../../Components/InputColumn'

const initialValues = {
  email: '',
}

const ForgotPassword = observer((props: any) => {
  const intl = useIntl()
  const history = useHistory()
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(getTranslation('LOGIN.FORM.MESSAGES.EMAIL.WRONG_FORMAT', intl))
      .required(getTranslation('LOGIN.FORM.MESSAGES.EMAIL.REQUIRE', intl)),
  })
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [showFormError, setShowFormError] = useState<boolean>(false)
  const [passwords, setPasswords] = useState<any>({
    newPassword: '',
    confirmPassword: '',
  })
  const token = props.match.params.token

  useEffect(() => {
    const checkToken = async (token: string) => {
      if (await AuthStore.checkToken({token})) {
        setShowForm(true)
        setShowFormError(false)
      } else {
        setShowFormError(true)
        setShowForm(false)
      }
    }
    if (token) {
      checkToken(token)
    }
  }, [])

  const passwordSchema = yup.object({
    newPassword: yup
      .string()
      .min(8, getTranslation('USER.SETTINGS.PASSWORD.CHECKS.MIN', intl))
      .matches(/[A-Z]/, getTranslation('USER.SETTINGS.PASSWORD.CHECKS.LETTERS', intl))
      .required(getTranslation('USER.SETTINGS.PASSWORD.REQUIRED', intl)),
  })

  const formikPassword = useFormik({
    initialValues: passwords,
    validationSchema: passwordSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting, setErrors}) => {
      let errors = {}
      let isFocused = false

      isFocused = checkValueError(
        'newPassword',
        isEmpty(passwords.newPassword),
        isFocused,
        'USER.FORM.PASSWORD.VALIDATION.NEW_PASSWORD',
        errors,
        intl
      )

      isFocused = checkValueError(
        'confirmPassword',
        isEmpty(passwords.confirmPassword),
        isFocused,
        'USER.FORM.PASSWORD.VALIDATION.CONFIRM_PASSWORD',
        errors,
        intl
      )

      isFocused = checkValueError(
        'confirmPassword',
        passwords.newPassword !== passwords.confirmPassword,
        isFocused,
        'USER.FORM.PASSWORD.VALIDATION.PASSWORD_NOT_MATCH',
        errors,
        intl
      )

      if (!checkFormErrors(errors, setErrors, setSubmitting, formik)) {
        if (await AuthStore.changePassword({password: passwords.newPassword, token})) {
          history.push('/prihlasit')
        }
      }
    },
  })

  const updatePassword = async () => {}

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      if (values.email === '') {
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setStatus('The login detail is incorrect')
      } else {
        if (await AuthStore.resetPassword({email: values.email})) {
          setHasErrors(false)
          setLoading(false)
        }
      }
      // setHasErrors(undefined)
      // setTimeout(() => {
      //   requestPassword(values.email)
      //     .then(({data: {result}}) => {
      //       setHasErrors(false)
      //       setLoading(false)
      //     })
      //     .catch(() => {
      //       setHasErrors(true)
      //       setLoading(false)
      //       setSubmitting(false)
      //       setStatus('The login detail is incorrect')
      //     })
      // }, 1000)
    },
  })

  const changeValue = (e: any) => {
    setPasswords({...passwords, [e.target.name]: e.target.value})
  }

  if (showFormError) {
    return (
      <>
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          {/* begin::Logo */}
          <a href='#' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-45px' />
          </a>
          <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_password_reset_enter_form'
              onSubmit={formikPassword.handleSubmit}
            >
              <div className='text-center mb-10'>
                {/* begin::Title */}
                {/*<h1 className='text-dark mb-3'>{getTranslation('FORGOT_PASSWORD.TITLE', intl)}</h1>*/}
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-400 fw-bold-custom fs-4'>
                  {getTranslation('FORGOT_PASSWORD.ERROR.DESCRIPTION', intl)}
                </div>
                {/* end::Link */}
              </div>

              {/* begin::Form group */}
              <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <div
                  className='btn btn-lg btn-light-primary fw-bold-customer'
                  onClick={() => {
                    setShowForm(false)
                    setShowFormError(false)
                    history.push('/zapomenute-heslo')
                  }}
                >
                  {getTranslation('LOGIN.PASSWORD.FORGOT.BUTTON', intl)}
                </div>
              </div>
              {/* end::Form group */}
            </form>
          </div>
        </div>
      </>
    )
  }

  if (showForm) {
    return (
      <>
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          {/* begin::Logo */}
          <a href='#' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-45px' />
          </a>
          <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_password_reset_enter_form'
              onSubmit={formikPassword.handleSubmit}
            >
              <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark mb-3'>{getTranslation('FORGOT_PASSWORD.TITLE', intl)}</h1>
                {/* end::Title */}
              </div>

              {/* begin::Title */}
              {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>
                    Sorry, looks like there are some errors detected, please try again.
                  </div>
                </div>
              )}

              {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                  <div className='text-info'>Sent password reset. Please check your email</div>
                </div>
              )}
              {/* end::Title */}

              {/* begin::Form group */}
              <div className='fv-row mb-10'>
                <div className='col-lg-12'>
                  <InputColumn
                    value={passwords.newPassword}
                    name={'newPassword'}
                    changeValue={changeValue}
                    label={getTranslation('USER.NEW_PASSWORD', intl)}
                    type={'password'}
                    required={true}
                    formik={formikPassword}
                    passwordBar={true}
                  />
                </div>

                <div className='col-lg-12'>
                  <InputColumn
                    value={passwords.confirmPassword}
                    name={'confirmPassword'}
                    changeValue={changeValue}
                    label={getTranslation('USER.CONFIRM_PASSWORD', intl)}
                    type={'password'}
                    required={true}
                    formik={formikPassword}
                    passwordBar={true}
                  />
                </div>
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button
                  type='submit'
                  id='kt_password_reset_submit'
                  className='btn btn-lg btn-primary fw-bold-customer me-4'
                >
                  <span className='indicator-label'>
                    {getTranslation('FORGOT_PASSWORD.BUTTON.SET_NEW_PASSWORD', intl)}
                  </span>
                  {loading && (
                    <span className='indicator-progress'>
                      {getTranslation('FORGOT_PASSWORD.BUTTON.LOADING', intl)}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <Link to='/prihlasit'>
                  <button
                    type='button'
                    id='kt_login_password_reset_form_cancel_button'
                    className='btn btn-lg btn-light-primary fw-bold-customer'
                    disabled={formikPassword.isSubmitting || !formikPassword.isValid}
                  >
                    {getTranslation('FORGOT_PASSWORD.BUTTON.CANCEL', intl)}
                  </button>
                </Link>{' '}
              </div>
              {/* end::Form group */}
            </form>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-45px' />
        </a>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='text-center mb-10'>
              {/* begin::Title */}
              <h1 className='text-dark mb-3'>{getTranslation('FORGOT_PASSWORD.TITLE', intl)}</h1>
              {/* end::Title */}

              {/* begin::Link */}
              <div className='text-gray-400 fw-bold-custom fs-4'>
                {getTranslation('FORGOT_PASSWORD.DESCRIPTION', intl)}
              </div>
              {/* end::Link */}
            </div>

            {/* begin::Title */}
            {hasErrors === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  Sorry, looks like there are some errors detected, please try again.
                </div>
              </div>
            )}

            {hasErrors === false && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>Sent password reset. Please check your email</div>
              </div>
            )}
            {/* end::Title */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <label className='form-label fw-bold-customer text-gray-900 fs-6'>
                {getTranslation('FORGOT_PASSWORD.EMAIL', intl)}
              </label>
              <input
                type='email'
                placeholder={getTranslation('FORGOT_PASSWORD.EMAIL.PLACEHOLDER', intl)}
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container invalid-feedback'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary fw-bold-customer me-4'
              >
                <span className='indicator-label'>
                  {getTranslation('FORGOT_PASSWORD.BUTTON.ACCEPT', intl)}
                </span>
                {loading && (
                  <span className='indicator-progress'>
                    {getTranslation('FORGOT_PASSWORD.BUTTON.LOADING', intl)}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <Link to='/prihlasit'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-primary fw-bold-customer'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {getTranslation('FORGOT_PASSWORD.BUTTON.CANCEL', intl)}
                </button>
              </Link>{' '}
            </div>
            {/* end::Form group */}
          </form>
        </div>
      </div>
    </>
  )
})

export default ForgotPassword
