import { FC } from "react";
import { observer } from "mobx-react";
import { getTranslation } from "../../Helpers/Default";
import { useIntl } from "react-intl";

const Translation: FC<{text: string}> = observer((props) => {
  const intl = useIntl()
  return (getTranslation(props.text, intl));
})

export default Translation