import {observer} from 'mobx-react'
import Tooltip from '../../../_metronic/helpers/components/Tooltip'
import {TooltipDefault} from '../Tooltip'

const VisibilityIcon = observer((props: any) => {
  let className = 'fa-eye'
  let size = 'base'
  let placement = props.placement ?? 'top'
  if (props.isVisible === false) {
    className += ' fa-eye-slash text-danger'
  } else {
    className += ' text-success'
  }

  if (props.size) {
    size = props.size
  }

  return (
    <TooltipDefault tooltip={props.tooltip || ''} placement={placement}>
      <span>
        <i className={`fa ${className} ${props.className} fs-${size}`}></i>
      </span>
    </TooltipDefault>
  )
})
export default VisibilityIcon
