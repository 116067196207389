import {observer} from 'mobx-react'
import * as React from 'react'
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {renderFullNameWithIcon} from '../../../Helpers/User'
import {TooltipDefault} from '../../../Components/Tooltip'
import {getRandomKey, getTranslation} from '../../../Helpers/Default'
import ContactStore from '../../../Stores/Contact'
import {getContactName} from '../../../Helpers/Contact'
import {toJS} from 'mobx'

const MainContacts: FC<{users: any}> = observer((props) => {
  const intl = useIntl()

  const filterUsersCommunityManager = props.users.filter(
    (user: any) => user.role.name === 'community_manager' && user.isActive && user.user
  )
  const filterUsersMayorCommittee = props.users.filter(
    (user: any) => user.role.name === 'mayorCommittee' && user.isActive && user.user
  )
  const filterUsersSubMayorCommittee = props.users.filter(
    (user: any) => user.role.name === 'subMayorCommittee' && user.isActive && user.user
  )

  const filterUsersMemberCommittee = props.users.filter(
    (user: any) => user.role.name === 'memberCommittee' && user.isActive && user.user
  )

  const renderContacts = (users: any, type = 'user') => {
    return users.map((user: any) => {
      let phone = ''
      let email = ''
      let url = ''
      if (type === 'contact') {
        const phones = user?.phones.split('/&/') || []
        const emails = user?.emails.split('/&/') || []
        phone = phones[0]
        email = emails[0]
        url = `/adresar-kontaktu/${user.id}`
      } else {
        phone = user?.phone || '-'
        email = user?.user?.email || '-'
        url = `/sousede/${user.id}`
      }

      return (
        <div className='col-12 mt-3' key={getRandomKey()}>
          <div className='contact-wrapper d-inline-flex justify-content-between align-items-center w-100'>
            <div className='fullname'>
              {type === 'contact' ? getContactName(user) : renderFullNameWithIcon(user)}
            </div>
            <div className='contacts d-inline-flex'>
              <div className='contact'>
                <TooltipDefault tooltip={phone}>
                  <a href={`tel:${phone}`} className='phone text-hover-success'>
                    <i className='fa fa-phone fs-6'></i>
                  </a>
                </TooltipDefault>
              </div>
              <div className='contact'>
                <TooltipDefault tooltip={email}>
                  <a
                    href={`mailto:${email}?subject=Dotaz`}
                    className='ms-5 email text-hover-primary'
                  >
                    <i className='fa fa-envelope fs-6'></i>
                  </a>
                </TooltipDefault>
              </div>
              <div className='contact'>
                <TooltipDefault
                  tooltip={getTranslation(
                    type === 'contact' ? 'DASHBOARD.TOOLTIP.CONTACT' : 'DASHBOARD.TOOLTIP.PROFILE',
                    intl
                  )}
                >
                  <a className='text-hover-warning ms-5 detail-profil' href={url}>
                    <i className='fa fa-search fs-6'></i>
                  </a>
                </TooltipDefault>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className='card h-md-100'>
        <div className='card-header border-0 pt-5'>
          <div className='border-bottom w-100 d-inline-flex justify-content-between pb-3'>
            <h3 className='card-title align-items-start align-items-center'>
              <KTSVG
                path={'/media/icons/duotune/communication/com005.svg'}
                className='svg-icon-1'
              />
              <span className='card-label fw-bold-custom text-gray-900 ps-3'>
                {getTranslation('DASHBOARD.CONTACTS.IMPORTANT', intl)}
              </span>
            </h3>

            <div className='card-toolbar'></div>
          </div>
        </div>

        <div className='card-body pt-7 px-0'>
          {filterUsersMayorCommittee.length > 0 ||
          filterUsersSubMayorCommittee.length > 0 ||
          filterUsersMemberCommittee.length > 0 ||
          filterUsersCommunityManager.length > 0 ? (
            <div className={'tab-content mb-2 px-9 mb-6'}>
              <div className='col-12 border-bottom'>
                <span className='fs-4 text-gray-900 fw-bold-custom text-gray-900 lh-base'>
                  {getTranslation('DASHBOARD.ROLES.MAIN_STATUTAR_CONTACT', intl)}
                </span>
              </div>
              {filterUsersMayorCommittee.length > 0
                ? renderContacts(filterUsersMayorCommittee)
                : null}
              {filterUsersSubMayorCommittee.length > 0
                ? renderContacts(filterUsersSubMayorCommittee)
                : null}
              {filterUsersMemberCommittee.length > 0
                ? renderContacts(filterUsersMemberCommittee)
                : null}
              {filterUsersCommunityManager.length > 0
                ? renderContacts(filterUsersCommunityManager)
                : null}
            </div>
          ) : null}

          {ContactStore?.pinContacts?.length > 0 ? (
            <div className={'tab-content mb-2 px-9 mb-6'}>
              <div className='col-12 border-bottom'>
                <span className='fs-4 text-gray-900 fw-bold-custom text-gray-900 lh-base'>
                  {getTranslation('DASHBOARD.ROLES.PIN_CONTACT', intl)}
                </span>
              </div>
              {renderContacts(ContactStore.pinContacts, 'contact')}
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
})

export default MainContacts
