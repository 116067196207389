import {useEffect} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import {checkRoutesParams} from '../../Helpers/Routes'

export function TableTabFilterEffect(
  setSelectedFilter: any,
  defaultValue: any,
  paramName: string = 'tab'
) {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const param = checkRoutesParams(paramName, defaultValue, history, location)
    setSelectedFilter(param)
  }, [location, history, setSelectedFilter, defaultValue])
}
