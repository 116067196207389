export const VOTE_STATUS_MAP = {
  concept: {
    value: 'concept',
    label: 'VOTE.STATUS.CONCEPT',
  },
  finish: {
    value: 'finish',
    label: 'VOTE.STATUS.FINISH',
  },
  run: {
    value: 'run',
    label: 'VOTE.STATUS.RUN',
  },
}

export const VOTE_TYPE_MAP = {
  online: {
    value: 'online',
    label: 'VOTE.TYPE.ONLINE',
  },
  local: {
    value: 'local',
    label: 'VOTE.TYPE.LOCAL',
  },
}
