/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useLayout} from '../core'
import {getTranslation} from '../../../app/Helpers/Default'
import {useIntl} from 'react-intl'
import {IsFree} from './IsFree'

const Footer: FC = () => {
  const {classes} = useLayout()
  const intl = useIntl()
  return (
    <>
      <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
        {/* begin::Container */}
        <div
          className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
        >
          {/* begin::Copyright */}
          <div className='text-dark order-2 order-md-1'>
            <div className='text-muted fw-bold me-2 d-inline-flex justify-content-center align-items-center'>
              {getTranslation('BASE.APP_VERSION', intl, '2.1.2')}
              <a
                href={'https://helpdesk.svjaplikace.cz/aktualizace-aplikace/'}
                target={'_blank'}
                className='text-muted text-hover-primary'
                rel='noreferrer'
              >
                <i className='fa fa-info-circle ms-2'></i>
              </a>
            </div>
            <div>
              <span className='text-muted fw-bold me-2'>
                2018 - {new Date().getFullYear()} &copy;
              </span>
              <a
                href='https://svjaplikace.cz'
                target={'_blank'}
                className='text-muted text-hover-primary'
                rel='noreferrer'
              >
                svjaplikace.cz
              </a>
            </div>
          </div>
          {/* end::Copyright */}

          {/* begin::Nav */}
          <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
            <li className='menu-item'>
              <a
                href='https://svjaplikace.cz/obchodni-podminky/'
                target='_blank'
                className='menu-link ps-0 pe-2'
                rel='noreferrer'
              >
                {getTranslation('BASE.TERM_AND_CONDITIONS', intl)}
              </a>
            </li>
            <li className='menu-item'>
              <a
                href='https://svjaplikace.cz/prohlaseni-o-zpracovani-osobnich-udaju/'
                target='_blank'
                className='menu-link pe-0'
                rel='noreferrer'
              >
                {getTranslation('BASE.PRIVACY_POLICY', intl)}
              </a>
            </li>
          </ul>
          {/* end::Nav */}
        </div>
        {/* end::Container */}
      </div>
    </>
  )
}

export {Footer}
