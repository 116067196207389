import {makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {createFormData, downloadFile, hasModule} from '../../Helpers/Default'
import {DOCUMENTS_MODULE, GALLERY_MODULE} from '../../Constants/Modules'
import {IsDemo} from '../../Helpers/Demo'

class GalleryStore {
  constructor() {
    makeAutoObservable(this)
  }
  data = []
  isLoading = false
  isCreating = false
  isUpdating = false
  isDeleting = false
  isSearching = false
  allCount = 0

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async getAll() {
    this.setValue('isLoading', true)
    const res = await apiRequest(
      '/api/v2/gallery',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res.data) {
      this.setValue('allCount', res.data.length || 0)
      this.setValue('data', res.data)
    }
    this.setValue('isLoading', false)
  }

  async get(id: string) {
    this.setValue('isLoading', true)
    const res = await apiRequest(
      '/api/v2/gallery/' + id,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    this.setValue('isLoading', false)
    if (res.status === 500 && res.message === 'BE.GALLERY.NOT_ACCES') {
      return null
    } else if (res.status === 200) {
      return res.data
    }
  }

  async getLink(id: string, galleryId: string) {
    const res = await apiRequest(
      `/api/v2/gallery/${galleryId}/image/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    return res.data
  }

  async create(data: any) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isCreating', true)
    const res = await apiRequest(
      '/api/v2/gallery',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    this.setValue('isCreating', false)

    return res.status === 200
  }

  async update(id: string, data: any) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isUpdating', true)
    const res = await apiRequest(
      '/api/v2/gallery/' + id,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    this.setValue('isUpdating', false)

    return res.status === 200
  }

  async delete(id: string) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isDeleting', true)
    const res = await apiRequest(
      '/api/v2/gallery/' + id,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    this.setValue('isDeleting', false)

    return res.status === 200
  }

  async addAttachments(id: string, file: any) {
    if (IsDemo()) {
      return false
    }
    const formData = createFormData(file)

    const res = await apiRequest(
      `/api/v2/gallery/${id}/upload`,
      REQ_TYPE.post,
      // @ts-ignore
      formData,
      // @ts-ignore
      getHeaders(null, {'content-type': 'multipart/form-data'}),
      this,
      'login_request'
    )
    return true
  }

  async removeAttachment(id: string, galleryId: string) {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      `/api/v2/gallery/${galleryId}/attachment/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    return res.status === 200
  }

  async downloadAttachment(id: string, galleryId: string, fileName: string) {
    const res = await apiRequest(
      `/api/v2/gallery/${galleryId}/download/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    if (res.data) {
      downloadFile(res.data, fileName)
    }
    return res.status === 200
  }

  async search(text: string) {
    if (!hasModule(GALLERY_MODULE)) {
      return []
    }
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/gallery/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSearching', false)
    return res.data ?? []
  }
}
export default new GalleryStore()
