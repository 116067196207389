import React, {FC, useEffect} from 'react'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {DefaultModal} from '../DefaultModal'
import ModalHeader from 'react-bootstrap-v5/lib/cjs/ModalHeader'
import AcceptReadStore from '../../Stores/AcceptRead'
import EventAcceptRead from './Event'
import {getTranslation} from '../../Helpers/Default'
import MessageAcceptRead from './Message'
import DocumentAcceptRead from './Document'

const AcceptReadDialog: FC<{}> = observer((props) => {
  const intl = useIntl()

  const [initialData, setInitialData] = React.useState<any>({length: 0, actual: 0})
  const [show, setShow] = React.useState(false)
  useEffect(() => {
    async function get() {
      await AcceptReadStore.getAll()
      setShow(AcceptReadStore.data.length > 0)
      setInitialData((prev: any) => ({...prev, length: AcceptReadStore.data.length}))
    }

    get()
  }, [])

  const hide = () => {}

  const skip = () => {
    const actual = initialData.actual + 1
    setInitialData((prev: any) => ({...prev, actual: actual}))

    if (initialData.length === actual) {
      setShow(false)
    }
  }

  const renderEvent = (data: any) => {
    return (
      <>
        <ModalHeader>
          <h5 className='modal-title fw-bold-customer text-gray-600'>
            {getTranslation('ACCEPT_READ.EVENT.TITLE', intl)}
          </h5>
        </ModalHeader>
        <EventAcceptRead
          id={data.dataValues.id}
          data={data.data}
          skip={() => {
            skip()
          }}
        />
      </>
    )
  }

  const renderMessage = (data: any) => {
    return (
      <>
        <ModalHeader>
          <h5 className='modal-title fw-bold-customer text-gray-600'>
            {getTranslation('ACCEPT_READ.MESSAGE.TITLE', intl)}
          </h5>
        </ModalHeader>
        <MessageAcceptRead
          id={data.dataValues.id}
          data={data.data}
          skip={() => {
            skip()
          }}
        />
      </>
    )
  }

  const renderDocument = (data: any) => {
    return (
      <>
        <ModalHeader>
          <h5 className='modal-title fw-bold-customer text-gray-600'>
            {getTranslation('ACCEPT_READ.DOCUMENT.TITLE', intl)}
          </h5>
        </ModalHeader>
        <DocumentAcceptRead
          id={data.dataValues.id}
          data={data.data}
          skip={() => {
            skip()
          }}
        />
      </>
    )
  }

  const getElement = (data: any) => {
    if (data.dataValues.type === 'event') {
      return renderEvent(data)
    } else if (data.dataValues.type === 'message') {
      return renderMessage(data)
    } else if (data.dataValues.type === 'document') {
      return renderDocument(data)
    }

    return <div>Neobsahuje tento typ</div>
  }
  const getContent = () => {
    if (AcceptReadStore.data.length > 0 && initialData.length > initialData.actual) {
      const data = AcceptReadStore.data[initialData.actual]
      return getElement(data)
    }

    return null
  }

  return (
    <DefaultModal show={show} onHide={hide}>
      <div key={`${initialData.actual}_accept_read`}>{getContent()}</div>
    </DefaultModal>
  )
})

export default AcceptReadDialog
