import {observer} from 'mobx-react'
import * as React from 'react'
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import SvjStore from '../../Stores/Svj'
import {getRandomKey, getTranslation} from '../../Helpers/Default'
import AuthStore from '../../Stores/Auth'
import {toJS} from 'mobx'
import UserStore from '../../Stores/User'

const SelectSVJ: FC<{}> = observer(() => {
  const intl = useIntl()
  const [svj_list_org, setSvjListOrg] = React.useState([])
  const [svj_list, setSvjList] = React.useState([])

  useEffect(() => {
    async function fetchData() {
      const svjs = await SvjStore.getAllUserSvj()
      setSvjList(svjs)
      setSvjListOrg(svjs)
    }

    fetchData()
  }, [])

  const renderSvjList = () => {
    const html = []

    for (let i in svj_list) {
      const svj: any = svj_list[i]
      html.push(
        <div className='card card-shadow border mb-5' key={getRandomKey()}>
          <div className='card-body'>
            <div className='d-sm-flex justify-content-between align-items-center w-100 '>
              <div className='w-auto mb-6 mb-sm-0'>
                <h6 className='m-0'>{svj.name}</h6>
                {svj.nickname ? (
                  <div className={'mt-2'}>
                    <span className={'text-muted fw-bold-custom fs-7 pe-4'}>
                      {getTranslation('SVJ.SETTINGS.NICKNAME', intl)}:
                    </span>
                    <span className='fw-bold-custom fs-7'>{svj.nickname}</span>
                  </div>
                ) : null}
              </div>

              <div className='d-flex justify-content-center align-items-center flex-column min-w-125px ms-0 ms-sm-6'>
                <div
                  className='btn btn-color-success bold bg-hover-light-success border-hover border-hover-success'
                  onClick={async () => {
                    SvjStore.setSVJ(svj.session_user_id)
                    // @ts-ignore
                    await AuthStore.verify()
                    window.location.href = '/nastenka'
                  }}
                >
                  <i className='bi bi-door-open'></i> {getTranslation('SVJ.LIST.ENTER', intl)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return html
  }

  return (
    <>
      <div className={'search w-100 mb-6'}>
        <div className='input-group mb-5'>
          <input
            type='text'
            className='form-control'
            onChange={async (e) => {
              const keyword = e.target.value
              if (keyword === '') {
                setSvjList(svj_list_org)
              } else {
                if (
                  AuthStore.searchByEmail &&
                  AuthStore.searchByEmail.indexOf('@svjaplikace.cz') > -1
                ) {
                  const foundEmails = SvjStore.svj_users_list.filter((svjUser: any) => {
                    return (
                      svjUser.user &&
                      svjUser.user.email?.toLowerCase().includes(keyword.toLowerCase())
                    )
                  })
                  const svjIds = foundEmails.map((svjUser: any) => svjUser.svjId)
                  const svj_list_filtered = svj_list_org.filter((svj: any) => {
                    return svjIds.includes(svj.id)
                  })
                  setSvjList(svj_list_filtered)
                } else {
                  const svj_list_filtered = svj_list_org.filter((svj: any) => {
                    return (
                      svj.name?.toLowerCase().includes(keyword.toLowerCase()) ||
                      svj.nickname?.toLowerCase().includes(keyword.toLowerCase())
                    )
                  })
                  setSvjList(svj_list_filtered)
                }
              }
            }}
            placeholder={getTranslation('SVJ.MODAL.SELECT_SVJ.SEARCH', intl)}
            aria-label={getTranslation('SVJ.MODAL.SELECT_SVJ.SEARCH', intl)}
            aria-describedby='basic-addon2'
          />
          <span className='input-group-text' id='basic-addon2'>
            <i className={'bi bi-search fs-4'}></i>
          </span>
        </div>
      </div>
      <div className='w-100'>{renderSvjList()}</div>
    </>
  )
})

export default SelectSVJ
