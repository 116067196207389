import {action, makeAutoObservable, observable} from 'mobx'
import MessageStore from '../Messages'
import EventStore from '../Event'
import ContactStore from '../Contact'
import {filterEvents} from '../../Helpers/Calendar'

class DashboardStore {
  committees: any = []
  messages: any = []
  pinnedMessages: any = []
  events: any = []

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
    })
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async getData() {
    const actualMessages = await MessageStore.getAll('actual')
    this.setValue('messages', actualMessages.messages)
    const pinedMessages = await MessageStore.getAll('pined')
    this.setValue('pinnedMessages', pinedMessages.messages)

    const events = await EventStore.getAll('all')
    await ContactStore.getPin()

    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear()
    const filteredData = filterEvents(events, currentYear, currentMonth)

    this.setValue('events', filteredData)
  }
}
export default new DashboardStore()
