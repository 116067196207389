import {observer} from 'mobx-react'
import * as React from 'react'
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {getTranslation} from '../../Helpers/Default'

const EmptyText: FC<{text?: any; center?: boolean}> = observer((props) => {
  const intl = useIntl()

  return (
    <div
      className={`w-100 ${
        props.center ? 'text-center' : 'text-left'
      } text-muted text-black fs-7 gs-0 `}
    >
      {props.text ? props.text : getTranslation('TABLE.EMPTY.DEFAULT', intl)}
    </div>
  )
})

export default EmptyText
