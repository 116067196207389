import {action, makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {IsDemo} from '../../Helpers/Demo'

class RolesAccessStore {
  isLoading = false
  isCreating = false
  data = {
    defaultRoles: [],
    customRoles: [],
    auditCommitteeRoles: [],
    committeeRoles: [],
    ownersRoles: [],
  }

  detail = null

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
      create: action,
      getAll: action,
      get: action,
    })
  }

  setValue = (key: string, value: any) => {
    // @ts-ignore
    this[key] = value
  }

  create = async (data: any) => {
    if (IsDemo()) {
      return false
    }
    this.setValue('isLoading', true)
    const response = await apiRequest(
      '/api/v2/role',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoading', false)

    return response.status === 200
  }

  update = async (id: string, data: any) => {
    if (IsDemo()) {
      return false
    }
    this.setValue('isLoading', true)
    const response = await apiRequest(
      `/api/v2/role/${id}`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoading', false)

    return response.status === 200
  }

  getAll = async () => {
    this.setValue('isLoading', true)
    const response = await apiRequest(
      '/api/v2/role',
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoading', false)

    if (response && response.data) {
      let newData = {
        defaultRoles: [],
        customRoles: [],
        auditCommitteeRoles: [],
        committeeRoles: [],
        ownersRoles: [],
      }

      for (let i in response.data.defaultRoles) {
        // @ts-ignore
        newData.defaultRoles.push({...response.data.defaultRoles[i]})
      }

      newData.auditCommitteeRoles = response.data.auditCommitteeRoles
      newData.ownersRoles = response.data.ownersRoles
      newData.committeeRoles = response.data.committeeRoles

      newData.customRoles = response.data.customRoles

      this.setValue('data', newData)
    }
  }

  get = async (id: any) => {
    this.setValue('isLoading', true)
    const response = await apiRequest(
      `/api/v2/role/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoading', false)

    if (response && response.data) {
      this.setValue('detail', response.data)
      return response.data
    }
    return null
  }

  delete = async (id: any) => {
    if (IsDemo()) {
      return false
    }
    this.setValue('isLoading', true)
    const response = await apiRequest(
      `/api/v2/role/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoading', false)

    return response.status === 200
  }
}

export default new RolesAccessStore()
