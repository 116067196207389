import {action, makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {createFormData, downloadFile, hasModule} from '../../Helpers/Default'
import {CONTACT_MODULE, DOCUMENTS_MODULE} from '../../Constants/Modules'
import {IsDemo} from '../../Helpers/Demo'

class FileStore {
  page = 0
  total_pages = 0
  total_items = 0
  folder_id = null
  data = []
  files = []
  folders = []
  structures = []
  uploading: boolean = false
  isLoading: boolean = false
  isSearching: boolean = false
  isDownloading: boolean = false
  user: any = null

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
    })
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async getAll(folder_id: string | null = null) {
    const res = await apiRequest(
      `/api/v2/file${folder_id ? '?folderId=' + folder_id : ''}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    if (res.status === 200) {
      this.setValue('files', res.data)
    }
  }

  async create(file: any) {
    if (IsDemo()) {
      return false
    }
    const formData = createFormData(file)

    const res = await apiRequest(
      '/api/v2/file/upload',
      REQ_TYPE.post,
      // @ts-ignore
      formData,
      // @ts-ignore
      getHeaders(null, {'content-type': 'multipart/form-data'}),
      this,
      'login_request'
    )

    return res.status === 200
  }

  async createLink(data: any) {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      '/api/v2/file/link',
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    return res.status === 200
  }

  async get(id: string, type = 'file') {
    this.setValue('isDownloading', true)
    const res = await apiRequest(
      `/api/v2/file/${id}/download?type=${type}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    this.setValue('isDownloading', false)
    return res.data
  }

  async delete(id: string) {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      '/api/v2/file/' + id,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    return res.status === 200
  }

  async search(text: string) {
    if (!hasModule(DOCUMENTS_MODULE)) {
      return []
    }
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/file/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSearching', false)
    return res.data ?? []
  }
}
export default new FileStore()
