import {observer} from 'mobx-react'
import React, {FC} from 'react'
import clsx from 'clsx'
import {Switch} from '@mui/material'
import moment from 'moment/moment'
import {DEFAULT_DATE_TIME_FORMAT} from '../../Constants/Defatul'
import {InputLabel} from '../InputLabel'

const CheckBoxColumn: FC<{
  formik?: any
  className?: string
  required?: boolean
  name: string
  placeholder?: string
  label?: any
  type?: string
  changeValue: any
  value: any
  readOnly: boolean
  texts?: any
  description?: any
  elementClassName?: string
  columnLabel: boolean
  hideInput?: boolean
}> = observer((props: any) => {
  let labelClass = []
  let elementClassName = []
  let inputClass = [
    'form-check-input',
    // 'form-control-solid',
  ]

  let hideInput = props.hideInput ? props.hideInput : false

  if (props.required) {
    labelClass.push('required')
    inputClass.push('required')
  }

  if (props.className) {
    inputClass.push(props.className)
  }

  if (props.labelClassName) {
    labelClass.push(props.labelClassName)
  }

  if (props.elementClassName) {
    elementClassName.push(props.elementClassName)
  }

  if (props.formik) {
    inputClass.push(
      clsx(
        '',
        {'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name]},
        {'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name]}
      )
    )
  }

  const getCheckbox = (showLabels = true) => {
    return (
      <>
        <div className='form-check form-check-custom form-check-solid form-check-sm'>
          {hideInput ? null : (
            <input
              className='form-check-input'
              type='checkbox'
              checked={props.value}
              disabled={props.readOnly}
              onChange={(e) => {
                props.changeValue({
                  target: {
                    value: e.target.checked,
                    name: props.name,
                  },
                })
              }}
              id='flexRadioLg'
            />
          )}
          {showLabels ? (
            <label
              className={`form-check-label ${hideInput ? 'ps-0 ms-0' : ''}`}
              onClick={() => {
                if (props.readOnly) {
                  return
                }
                props.changeValue({
                  target: {
                    value: !props.value,
                    name: props.name,
                  },
                })
              }}
            >
              {props.texts ? <>{props.value ? props.texts.select : props.texts.unselect}</> : null}
              {props.description ? props.description : null}
            </label>
          ) : null}
        </div>
      </>
    )
  }

  // if (props.readOnly) {
  //   labelClass.push('col-lg-5 fw-bold-custom text-muted')
  // }

  if (props.readOnly) {
    return <div className={`${elementClassName.join(' ')}`}>{getCheckbox()}</div>
  }

  return (
    <div className={elementClassName.join(' ')}>
      {props.label && props.columnLabel ? (
        <InputLabel className={labelClass.join(' ')}>{props.label}</InputLabel>
      ) : null}

      <div className='switch-area'>
        {props.label && !props.columnLabel ? (
          <InputLabel className={`${labelClass.join(' ')} me-2`}>{props.label}</InputLabel>
        ) : null}
        {getCheckbox()}
      </div>

      {props.formik && props.formik.touched[props.name] && props.formik.errors[props.name] && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <div className='fv-help-block'> {props.formik.errors[props.name]}</div>
        </div>
      )}
    </div>
  )
})

export default CheckBoxColumn
