import {useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {useHistory, useLocation} from 'react-router-dom'
import {
  findDifferences,
  getRandomKey,
  getTranslation,
  hasModule,
  isNewMode,
  isReadOnlyMode,
  removeHtmlTags,
} from '../../../Helpers/Default'
import {useFormik} from 'formik'
import {PageTitle} from '../../../../_metronic/layout/core'
import * as React from 'react'
import CardLayout from '../../../Components/CardLayout'
import InputInline from '../../../Components/InputInline'
import DateTimePickerDefault from '../../../Components/DateTimePicker'
import moment from 'moment'
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_PICKER_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_DATE_TIME_PICKER_FORMAT,
} from '../../../Constants/Defatul'
import {InputLabel} from '../../../Components/InputLabel'
import InputSimple from '../../../Components/InputSimple'
import SelectBase from '../../../Components/SelectBase'
import SelectInline from '../../../Components/SelectInline'

import {getColorLabel, getLabelTranslation, getNotifyPeriodOptions} from '../../../Helpers/Calendar'
import {DefaultBadge} from '../../../Components/Badge'
import EventStore from '../../../Stores/Event'
import FileManager from '../../../Components/FileManager'
import {getVisibilityOptions} from '../../../Helpers/Reports'
import TextEditor from '../../../Components/TextEditor'
import {DefaultButton} from '../../../Components/Buttons/DefaultButton'
import {checkFormErrors, checkValueError} from '../../../Helpers/Form'
import {cloneDeep, isEmpty} from 'lodash'
import {confirmDialog, eventConfirmDialog} from '../../../Helpers/ConfirmDialog'
import {toJS} from 'mobx'
import DefaultTableMui from '../../../Components/DefaultTableMui'
import {hasCrudRole} from '../../../Helpers/User'
import {ACCEPT_READ_MODULE, EVENTS_MODULE} from '../../../Constants/Modules'
import SectionTitle from '../../../Components/SectionTitle'
import useCheckCrudRole from '../../../Hooks/CheckCrudRole'
import {acceptReadsColumns} from '../../../Helpers/AcceptRead'
import ICSDownloadButton from '../../../Components/ICSDownloadButton'
import SwitchInline from '../../../Components/SwitchInline'
import UserStore from '../../../Stores/User'
import {isValid} from 'date-fns'
import {isFreeSVJ} from '../../../Helpers/Svj'
import EmptyText from '../../../Components/EmptyText'
const CalendarForm = observer((props: any) => {
  const intl = useIntl()
  moment.locale(UserStore.user.lang)
  const CALENDAR_LIST = [
    {
      value: 'null',
      label: getTranslation('CALENDAR.FORM.CALENDAR.NAME.MAIN', intl),
    },
  ]

  const id = props?.match?.params?.id || null
  const location = useLocation()
  const history = useHistory()
  const [labels, setLabels] = useState<any>([])
  const [files, setFiles] = useState<any>([])
  const [attachments, setAttachments] = useState<any>([])
  const [calendarList, setCalendarList] = useState<any>([])

  const END_OF_REPEAT = [
    {
      value: 'none',
      label: getTranslation('CALENDAR.FORM.END.REPEAT.NEVER', intl),
    },
    {
      value: 'date_end',
      label: getTranslation('CALENDAR.FORM.END.REPEAT.TO_DATE', intl),
    },
  ]

  const REPEAT_FREQUENCY = [
    {
      value: 'day',
      label: getTranslation('CALENDAR.FORM.REPEAT.WEEK', intl),
    },
    {
      value: 'month',
      label: getTranslation('CALENDAR.FORM.REPEAT.MONTH', intl),
    },
    {
      value: 'year',
      label: getTranslation('CALENDAR.FORM.REPEAT.YEAR', intl),
    },
  ]

  const defaultVisibility = getVisibilityOptions(intl)[0]

  const notifyPeriodDefault = {
    value: getNotifyPeriodOptions()[0].value,
    label: getTranslation(getNotifyPeriodOptions()[0].label, intl),
  }
  const [defaultLabel, setDefaultLabel] = useState<any>(null)

  const getTypeOfMonthRepeat = (date: any) => {
    return [
      {
        value: 'date',
        label: getTranslation('CALENDAR.FORM.REPEAT.EVERY_DAY', intl, moment(date).format('DD')),
      },
      {
        value: 'weekDay',
        label: getTranslation(
          'CALENDAR.FORM.REPEAT.DAY',
          intl,
          getTranslation(moment(date).format('dddd'), intl)
        ),
      },
    ]
  }

  const [initialValues, setInitialValues] = useState({
    id: null,
    key: getRandomKey(),
    readOnly: props.loadData ? true : isReadOnlyMode(props.loadData),
    isNew: isNewMode(),
    title: '',
    dateKey: getRandomKey(),
    start: new Date(),
    end: new Date(moment().add(1, 'hours').valueOf()),
    place_description: '',
    allDay: false,
    repeat: false,
    acceptRead: false,
    numberOfRepeat: 1,
    repeatFrequency: REPEAT_FREQUENCY[0],
    typeOfMonthRepeat: getTypeOfMonthRepeat(new Date())[0],
    endDateRepeat: moment().endOf('day'),
    calendar: CALENDAR_LIST[0],
    endRepeat: END_OF_REPEAT[0],
    label: defaultLabel,
    isPublic: defaultVisibility,
    notify_period: [notifyPeriodDefault],
    place_address: '',
    typeUpdate: 'thisAndFuture',
    parentId: null,
    loadData: props.loadData || false,
    allAccepts: [],
    addedAttachments: [],
    attachments: [],
    removedAttachments: [],
    meetingOfOwnersId: null,
    keySelect: getRandomKey(),
  })

  let returnBackParams = ''
  // @ts-ignore
  if (location?.state?.search) {
    // @ts-ignore
    returnBackParams = location.state.search
  }

  useCheckCrudRole(EVENTS_MODULE, `/kalendar-udalosti${returnBackParams}`)

  useEffect(() => {
    setInitialValues((prevState) => ({...prevState, key: getRandomKey()}))
  }, [attachments])

  useEffect(() => {
    setInitialValues((prevState) => ({...prevState, dateKey: getRandomKey()}))
  }, [calendarList])

  useEffect(() => {
    const getCalendars = async () => {
      await EventStore.getAllCalendars()
      const calendars = CALENDAR_LIST
      for (let i in EventStore.calendars) {
        calendars.push({
          value: EventStore.calendars[i].id,
          label: EventStore.calendars[i].name,
        })
      }
      setCalendarList(calendars)
    }
    const getLabels = async () => {
      await EventStore.getLabels()
      await getCalendars()
      const labels2 = toJS(EventStore.labels).map((label: any) => {
        return {
          ...label,
          value: label.id,
          type: label.type,
          label: getTranslation(getLabelTranslation(label.type), intl),
        }
      })
      setLabels(labels2)
      const defaultLabel = labels2.find((label: any) => label.type === 'default')
      setDefaultLabel(defaultLabel)
      if (defaultLabel) {
        setInitialValues((prevState) => ({...prevState, label: defaultLabel}))
      }

      if (!initialValues.isNew && id && !initialValues.loadData) {
        const data: any = await EventStore.get(id)
        await getData(labels2, data)
      } else if (initialValues.loadData) {
        await getData(labels2, props.eventData)
      }
    }
    getLabels()
    const getData = async (labels3: any, data: any) => {
      if (data) {
        let eventLabel: any = labels3.find((label: any) => {
          return label.id === data.labelId
        })

        let notifyPeriod: any = getNotifyPeriodOptions().find(
          (item: any) => data.notify_period.indexOf(item.value) > -1
        )
        if (!notifyPeriod) {
          notifyPeriod = [
            {...notifyPeriodDefault, label: getTranslation(notifyPeriodDefault.label, intl)},
          ]
        } else if (typeof notifyPeriod === 'object') {
          notifyPeriod = [{...notifyPeriod, label: getTranslation(notifyPeriod.label, intl)}]
        }

        const eventData: any = {
          ...initialValues,
          id: data.id,
          calendar: data.calendarId
            ? {
                value: data.calendar.id,
                label: data.calendar.name,
              }
            : CALENDAR_LIST[0],
          title: data.title,
          place_description: data.place_description,
          start: new Date(data.start),
          end: new Date(data.end),
          label: eventLabel || defaultLabel,
          isPublic: data.isPublic ? getVisibilityOptions(intl)[1] : getVisibilityOptions(intl)[0],
          place_address: data.place_address,
          allDay: data.allDay || false,
          repeat: data.repeat,
          numberOfRepeat: data.numberOfRepeat,
          acceptRead: data.acceptRead,
          meetingOfOwnersId: data.meetingOfOwnersId,
          repeatFrequency:
            REPEAT_FREQUENCY.find((item: any) => item.value === data.repeatFrequency) ||
            REPEAT_FREQUENCY[0],
          typeOfMonthRepeat:
            getTypeOfMonthRepeat(new Date(data.start)).find(
              (item: any) => item.value === data.typeOfMonthRepeat
            ) || getTypeOfMonthRepeat(new Date())[0],
          endDateRepeat: data.endDateRepeat ? new Date(data.endDateRepeat) : null,
          endRepeat: END_OF_REPEAT.find((item: any) => item.value === data.endRepeat),
          // notify: notify,
          notify_period: notifyPeriod,
          parentId: data.parentId,
          allAccepts: data.allAccepts || [],
          attachments: data.attachments || [],
          key: getRandomKey(),
        }

        setInitialValues(cloneDeep(eventData))
        setFiles(data.files || [])
        setAttachments(data.attachments || [])
      } else {
        history.push(`/kalendar-udalosti${returnBackParams}`)
      }
    }
  }, [])

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values, {setSubmitting, setErrors}) => {
      let errors = {}
      let isFocused = false

      isFocused = checkValueError(
        'title',
        isEmpty(values.title),
        isFocused,
        'CALENDAR.FORM.VALIDATION.NAME',
        errors,
        intl
      )

      const startDate = initialValues.start ? new Date(initialValues.start) : null
      const endDate = initialValues.end ? new Date(initialValues.end) : null

      isFocused = checkValueError(
        'start',
        !isValid(startDate) || initialValues.start === null,
        isFocused,
        'CALENDAR.FORM.VALIDATION.START',
        errors,
        intl
      )

      isFocused = checkValueError(
        'end',
        !isValid(endDate) || initialValues.end === null,
        isFocused,
        'CALENDAR.FORM.VALIDATION.END',
        errors,
        intl
      )

      // isFocused = checkValueError(
      //   'place_description',
      //   isEmpty(removeHtmlTags(values.place_description)),
      //   isFocused,
      //   'CALENDAR.FORM.VALIDATION.DESCRIPTION',
      //   errors,
      //   intl
      // )

      if (!checkFormErrors(errors, setErrors, setSubmitting)) {
        if (initialValues.id) {
          if (initialValues.repeat) {
            eventConfirmDialog(
              '',
              getTranslation('CALENDAR.CONFIRM.QUESTION.UPDATE', intl),
              updateFuture,
              updateThisAndFuture,
              updateThis
            )
          } else {
            confirmDialog('', getTranslation('CALENDAR.CONFIRM.QUESTION.UPDATE', intl), updateThis)
          }
        } else {
          confirmDialog('', getTranslation('CALENDAR.CONFIRM.QUESTION.CREATE', intl), create)
        }
      }
    },
  })

  const update = async () => {
    const data = getData()

    if (await EventStore.update(initialValues.id, data, data.addedAttachments)) {
      setInitialValues((prevState) => ({
        ...prevState,
        readOnly: true,
        addedAttachments: [],
        removedAttachments: [],
      }))
    }
  }

  const updateFuture = () => {
    setInitialValues((prevState) => ({...prevState, typeUpdate: 'future'}))
    update()
  }
  const updateThisAndFuture = () => {
    setInitialValues((prevState) => ({...prevState, typeUpdate: 'thisAndFuture'}))
    update()
  }
  const updateThis = () => {
    setInitialValues((prevState) => ({...prevState, typeUpdate: 'this'}))
    update()
  }

  const deleteFuture = () => {
    setInitialValues((prevState) => ({...prevState, typeUpdate: 'future'}))
    deleteEvent()
  }
  const deleteThisAndFuture = () => {
    setInitialValues((prevState) => ({...prevState, typeUpdate: 'thisAndFuture'}))
    deleteEvent()
  }
  const deleteThis = () => {
    setInitialValues((prevState) => ({...prevState, typeUpdate: 'this'}))
    deleteEvent()
  }

  const getData = () => {
    let notify_period: any = initialValues.notify_period.map((item: any) => item.value)
    let notify = false
    let calendar = null
    notify_period = notify_period.join(',')

    if (notify_period.indexOf('none') > -1) {
      notify_period = 'none'
    } else {
      notify = true
    }

    const listAttachments = findDifferences(attachments, initialValues.attachments)

    if (initialValues.calendar && initialValues.calendar.value !== 'null') {
      calendar = initialValues.calendar.value
    }

    let start: any = initialValues.start
    let end: any = initialValues.end

    if (initialValues.allDay) {
      start = moment(initialValues.start).startOf('day').valueOf()
      end = moment(initialValues.end).endOf('day').valueOf()
    }

    return {
      id: initialValues.id,
      calendar: calendar,
      calendarId: calendar,
      title: initialValues.title,
      place_description: initialValues.place_description,
      start: start,
      end: end,
      acceptRead: initialValues.acceptRead,
      typeUpdate: initialValues.typeUpdate,
      // @ts-ignore
      labelId: initialValues.label.id,
      // @ts-ignore
      isPublic: initialValues.isPublic.value,
      place_address: initialValues.place_address,
      // @ts-ignore
      allDay: initialValues.allDay || false,
      // @ts-ignore
      repeat: initialValues.repeat,
      numberOfRepeat: initialValues.numberOfRepeat,
      // @ts-ignore
      repeatFrequency: initialValues.repeatFrequency.value,
      // @ts-ignore
      typeOfMonthRepeat: initialValues.typeOfMonthRepeat.value,
      endDateRepeat: initialValues.endDateRepeat,
      // @ts-ignore
      endRepeat: initialValues.endRepeat.value || 'none',
      notify_period: notify_period,
      notify: notify,
      parentId: initialValues.parentId,
      addedAttachments: listAttachments.addedFiles || [],
      removedAttachments: listAttachments.removedFiles.map((item: any) => item.id) || [],
      files: files.map((item: any) => item.id),
    }
  }

  const deleteEvent = async () => {
    if (await EventStore.delete(initialValues.id, initialValues.typeUpdate)) {
      history.goBack()
    }
  }

  const create = async () => {
    // console.log(getData())
    // return null
    if (await EventStore.create(getData(), attachments)) {
      history.goBack()
    }
  }

  const renderRepeatPeriod = () => {
    return `${initialValues.numberOfRepeat} ${
      initialValues.repeatFrequency?.label.toLowerCase() || ''
    }`
  }

  const changeValue = (e: any) => {
    let data = initialValues //cloneDeep(initialValues)
    let name: string = e.target.name
    let value: any = e.target.value

    if (['allDay'].indexOf(name) > -1) {
      data.dateKey = getRandomKey()
      // value = moment(new Date(value)).format('YYYY-MM-DD HH:mm')
    }

    if (name === 'notify_period') {
      const found_none = e.target.value.findIndex((item: any) => item.value === 'none') || -1
      if (e.target.value.length === 0 || e.target.value.length === 3 || found_none > 0) {
        value = [{...notifyPeriodDefault, label: getTranslation(notifyPeriodDefault.label, intl)}]
      } else {
        // @ts-ignore
        value = e.target.value.filter((item: any) => item.value !== notifyPeriodDefault.value)
      }
    } else if (name === 'label') {
      value = labels.find((label: any) => label.id === e.target.value.value)
    }

    if (name === 'start') {
      data.dateKey = getRandomKey()
      if (data.typeOfMonthRepeat.value === 'date') {
        data.typeOfMonthRepeat = getTypeOfMonthRepeat(new Date(e.target.value))[0]
      }
    }

    // @ts-ignore
    data[name] = value
    setInitialValues((prevState) => ({...prevState, ...data}))
  }

  const changeDatePickers = (e: any) => {
    let data: any = initialValues //cloneDeep(initialValues)
    let name: string = e.target.name
    let value: any = e.target.value

    data[name] = value
    setInitialValues((prevState) => ({...prevState, ...data}))
  }

  let pageTitleText = 'CALENDAR.FORM.TITLE_DETAIL'

  if (!initialValues.readOnly) {
    pageTitleText = 'CALENDAR.FORM.TITLE_UPDATE'
    if (initialValues.isNew) {
      pageTitleText = 'CALENDAR.FORM.TITLE_CREATE'
    }
  }

  const mainButtons = [
    initialValues.readOnly ? (
      <ICSDownloadButton className={'me-2'} event={initialValues} key={getRandomKey()} />
    ) : null,
  ]
  if (hasCrudRole(EVENTS_MODULE) && initialValues.readOnly) {
    mainButtons.push(
      <DefaultButton
        size={'sm'}
        color={'primary'}
        className={''}
        onClick={() => EventStore.print(initialValues.id, initialValues.title.replace(/ /g, '_'))}
        key={getRandomKey()}
        loading={EventStore.isGetting}
        tooltip={getTranslation('BASE.BUTTONS.PRINT', intl)}
        icon={<i className='bi bi-printer pe-0'></i>}
      ></DefaultButton>
    )
  }

  return (
    <div>
      {!props.loadData ? (
        <PageTitle pageButtons={[]} linkBack={`/kalendar-udalosti${returnBackParams}`}>
          {getTranslation(pageTitleText, intl)}
        </PageTitle>
      ) : null}
      {/*<GoBackPageButton to={'/jednotky'}>Zpet na seznam jednotek</GoBackPageButton>*/}
      <CardLayout>
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='message_form'
          key={initialValues.dateKey}
        >
          <div
            className={`mx-auto w-100 pb-10 fv-plugins-bootstrap5 fv-plugins-framework ${
              !initialValues.loadData ? 'mw-600px pt-15' : 'mw-700px'
            }`}
          >
            <div className='d-flex flex-column mb-8 fv-row fv-plugins-icon-container'>
              <div className='mb-5'>
                <SectionTitle buttons={mainButtons}>
                  {getTranslation('CALENDAR.FORM.TITLE.INFORMATION', intl)}
                </SectionTitle>
              </div>
              <SelectInline
                name={'calendar'}
                label={
                  initialValues.readOnly
                    ? getTranslation('CALENDAR.FORM.CALENDAR', intl)
                    : getTranslation('CALENDAR.FORM.IN_CALENDAR', intl)
                }
                values={initialValues}
                changeValue={changeValue}
                formik={formik}
                readOnly={initialValues.readOnly}
                required={true}
                option={{
                  value: 'value',
                  name: function (value: any) {
                    return value['label']
                  },
                }}
                data={calendarList}
              />
              <InputInline
                name={'title'}
                label={getTranslation('CALENDAR.FORM.NAME', intl)}
                value={initialValues.title}
                placeholder={getTranslation('PLACEHOLDER', intl)}
                required={true}
                inputBold={true}
                inputSize={'col-lg-12'}
                formik={formik}
                changeValue={changeValue}
                readOnly={initialValues.readOnly}
              />
              <DateTimePickerDefault
                name={'start'}
                value={initialValues.start}
                placeholder={getTranslation('PLACEHOLDER', intl)}
                required={true}
                displayFormat={
                  initialValues.allDay ? DEFAULT_DATE_FORMAT : DEFAULT_DATE_TIME_FORMAT
                }
                key={initialValues.dateKey + '-2'}
                format={
                  initialValues.allDay
                    ? DEFAULT_DATE_PICKER_FORMAT
                    : DEFAULT_DATE_TIME_PICKER_FORMAT
                }
                label={getTranslation('CALENDAR.FORM.START_EVENT_DATE', intl)}
                formik={formik}
                isInline={true}
                enableTime={!initialValues.allDay}
                changeValue={changeDatePickers}
                readOnly={initialValues.readOnly}
              />
              <DateTimePickerDefault
                name={'end'}
                value={initialValues.end}
                placeholder={getTranslation('PLACEHOLDER', intl)}
                required={true}
                disable={[
                  (date: any) => {
                    return moment(date) <= moment(initialValues.start).subtract(1, 'day')
                  },
                ]}
                key={initialValues.dateKey}
                displayFormat={
                  initialValues.allDay ? DEFAULT_DATE_FORMAT : DEFAULT_DATE_TIME_FORMAT
                }
                format={
                  initialValues.allDay
                    ? DEFAULT_DATE_PICKER_FORMAT
                    : DEFAULT_DATE_TIME_PICKER_FORMAT
                }
                label={getTranslation('CALENDAR.FORM.END_EVENT_DATE', intl)}
                formik={formik}
                isInline={true}
                enableTime={!initialValues.allDay}
                changeValue={changeDatePickers}
                readOnly={initialValues.readOnly}
              />
              {(initialValues.readOnly && initialValues.acceptRead) || !initialValues.readOnly ? (
                <>
                  {(initialValues.readOnly && initialValues.allDay) || !initialValues.readOnly ? (
                    <SwitchInline
                      label={getTranslation('CALENDAR.FORM.ALL_DAY', intl)}
                      columnLabel={true}
                      name={'allDay'}
                      value={initialValues.allDay}
                      // label={
                      //   initialValues.readOnly ? getTranslation('MESSAGES.FORM.PIN_MESSAGE', intl) : null
                      // }
                      formik={formik}
                      changeValue={changeValue}
                      readOnly={initialValues.readOnly}
                      texts={{
                        select: getTranslation('BASE.YES', intl),
                        unselect: getTranslation('BASE.NO', intl),
                      }}
                    />
                  ) : // <div className={'row mb-6 d-inline-flex align-items-center'}>
                  //   <InputLabel
                  //     colSize={'col-lg-4'}
                  //     className={``}
                  //     alignTop={true}
                  //     readOnly={initialValues.readOnly}
                  //     inlineLabel={true}
                  //   >
                  //     {getTranslation('CALENDAR.FORM.ALL_DAY', intl)}
                  //   </InputLabel>
                  //   <div className={'col-lg-8'}>
                  //     <div className={'row'}>
                  //       <div className={'col-lg-12 fv-row fv-plugins-icon-container'}>
                  //         <div className={'d-inline-flex'}>
                  //           {initialValues.readOnly ? (
                  //             <>
                  //               {initialValues.allDay
                  //                 ? getTranslation('BASE.YES', intl)
                  //                 : getTranslation('BASE.no', intl)}
                  //             </>
                  //           ) : (
                  //             <CheckBoxColumn
                  //               columnLabel={true}
                  //               description={getTranslation('CALENDAR.FORM.IS_ALL_DAY', intl)}
                  //               name={'allDay'}
                  //               // @ts-ignore
                  //               value={initialValues.allDay}
                  //               changeValue={changeValue}
                  //               required={false}
                  //               formik={formik}
                  //               readOnly={initialValues.readOnly}
                  //             />
                  //           )}
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                  null}
                  {!isFreeSVJ() && hasModule(ACCEPT_READ_MODULE) ? (
                    <SwitchInline
                      label={getTranslation('CALENDAR.FROM.ACCEPT_READ', intl)}
                      columnLabel={true}
                      name={'acceptRead'}
                      value={initialValues.acceptRead}
                      // label={
                      //   initialValues.readOnly ? getTranslation('MESSAGES.FORM.PIN_MESSAGE', intl) : null
                      // }
                      formik={formik}
                      changeValue={changeValue}
                      readOnly={initialValues.readOnly}
                      texts={{
                        select: getTranslation('BASE.YES', intl),
                        unselect: getTranslation('BASE.NO', intl),
                      }}
                    />
                  ) : // <div className={'row mb-6 d-inline-flex align-items-center'}>
                  //   <InputLabel
                  //     colSize={'col-lg-4'}
                  //     className={``}
                  //     alignTop={true}
                  //     readOnly={initialValues.readOnly}
                  //     inlineLabel={true}
                  //   >
                  //     {getTranslation('CALENDAR.FROM.ACCEPT_READ', intl)}
                  //   </InputLabel>
                  //   <div className={'col-lg-8'}>
                  //     <div className={'row'}>
                  //       <div className={'col-lg-12 fv-row fv-plugins-icon-container'}>
                  //         <div className={'d-inline-flex'}>
                  //           {initialValues.readOnly ? (
                  //             <>
                  //               {initialValues.acceptRead
                  //                 ? getTranslation('BASE.YES', intl)
                  //                 : getTranslation('BASE.no', intl)}
                  //             </>
                  //           ) : (
                  //             <CheckBoxColumn
                  //               columnLabel={true}
                  //               description={getTranslation(
                  //                 'CALENDAR.FROM.ACCEPT_READ_VALUE',
                  //                 intl
                  //               )}
                  //               name={'acceptRead'}
                  //               // @ts-ignore
                  //               value={initialValues.acceptRead}
                  //               changeValue={changeValue}
                  //               required={false}
                  //               formik={formik}
                  //               readOnly={initialValues.readOnly}
                  //             />
                  //           )}
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                  null}
                </>
              ) : null}
              {!isFreeSVJ() &&
              ((!initialValues.acceptRead && !initialValues.readOnly) ||
                (initialValues.readOnly && initialValues.repeat)) ? (
                <>
                  <SwitchInline
                    label={getTranslation('CALENDAR.FORM.REPEAT', intl)}
                    columnLabel={true}
                    name={'repeat'}
                    value={initialValues.repeat}
                    // label={
                    //   initialValues.readOnly ? getTranslation('MESSAGES.FORM.PIN_MESSAGE', intl) : null
                    // }
                    formik={formik}
                    changeValue={changeValue}
                    readOnly={initialValues.readOnly}
                    texts={{
                      select: getTranslation('BASE.YES', intl),
                      unselect: getTranslation('BASE.NO', intl),
                    }}
                  />
                  {/*<div className={'row mb-6 d-inline-flex align-items-center'}>*/}
                  {/*  <InputLabel*/}
                  {/*    colSize={'col-lg-4'}*/}
                  {/*    className={``}*/}
                  {/*    alignTop={true}*/}
                  {/*    readOnly={initialValues.readOnly}*/}
                  {/*    inlineLabel={true}*/}
                  {/*  >*/}
                  {/*    {getTranslation('CALENDAR.FORM.REPEAT', intl)}*/}
                  {/*  </InputLabel>*/}
                  {/*  <div className={'col-lg-8'}>*/}
                  {/*    <div className={'row'}>*/}
                  {/*      <div className={'col-lg-12 fv-row fv-plugins-icon-container'}>*/}
                  {/*        <div className={'d-inline-flex'}>*/}
                  {/*          <CheckBoxColumn*/}
                  {/*            columnLabel={true}*/}
                  {/*            description={getTranslation('CALENDAR.FORM.REPEAT', intl)}*/}
                  {/*            name={'repeat'}*/}
                  {/*            // @ts-ignore*/}
                  {/*            value={initialValues.repeat}*/}
                  {/*            changeValue={changeValue}*/}
                  {/*            required={false}*/}
                  {/*            formik={formik}*/}
                  {/*            readOnly={initialValues.readOnly}*/}
                  {/*          />*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {initialValues.repeat ? (
                    <>
                      <div className={'row mb-6 d-inline-flex align-items-top'}>
                        <InputLabel
                          colSize={'col-lg-4'}
                          className={``}
                          alignTop={true}
                          readOnly={initialValues.readOnly}
                          inlineLabel={true}
                        >
                          {getTranslation('CALENDAR.FORM.REPEAT.ONES_PER', intl)}
                        </InputLabel>
                        <div className={'col-lg-8'}>
                          {initialValues.readOnly ? (
                            <div className={'row'}>
                              <div className={'col-lg-12 fs-6 text-gray-800'}>
                                {renderRepeatPeriod()}
                              </div>
                              <div className={'col-lg-12 fs-6 text-gray-800 mt-3'}>
                                {initialValues.typeOfMonthRepeat.label}
                              </div>
                            </div>
                          ) : (
                            <div className={'row'}>
                              <div className={'col-lg-3 fv-row fv-plugins-icon-container'}>
                                <div className={'d-inline-flex'}>
                                  <InputSimple
                                    name={'numberOfRepeat'}
                                    className={'w-100'}
                                    value={initialValues.numberOfRepeat}
                                    required={false}
                                    type={'number'}
                                    formik={formik}
                                    changeValue={changeValue}
                                    readOnly={initialValues.readOnly}
                                  />
                                </div>
                              </div>
                              <div className={'col-lg-9 fv-row fv-plugins-icon-container'}>
                                <div className={'d-inline-flex w-100'}>
                                  <SelectBase
                                    name={'repeatFrequency'}
                                    values={initialValues}
                                    changeValue={changeValue}
                                    formik={formik}
                                    readOnly={initialValues.readOnly}
                                    required={true}
                                    option={{
                                      value: 'value',
                                      name: function (value: any) {
                                        return value['label']
                                      },
                                    }}
                                    data={REPEAT_FREQUENCY}
                                  />
                                </div>
                              </div>
                              {initialValues.repeatFrequency.value === REPEAT_FREQUENCY[1].value ? (
                                <div
                                  className={'col-lg-12 mt-5 fv-row fv-plugins-icon-container'}
                                  key={initialValues.keySelect}
                                >
                                  <div className={'d-inline-flex w-100'}>
                                    <SelectBase
                                      name={'typeOfMonthRepeat'}
                                      values={initialValues}
                                      changeValue={changeValue}
                                      formik={formik}
                                      required={true}
                                      option={{
                                        value: 'value',
                                        name: function (value: any) {
                                          return value['label']
                                        },
                                      }}
                                      data={getTypeOfMonthRepeat(initialValues.start)}
                                    />
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className={'row mb-6 d-inline-flex align-items-top'}>
                        <InputLabel
                          colSize={'col-lg-4'}
                          className={``}
                          alignTop={true}
                          readOnly={initialValues.readOnly}
                          inlineLabel={true}
                        >
                          {getTranslation('CALENDAR.FORM.END.REPEAT', intl)}
                        </InputLabel>
                        <div className={'col-lg-8'}>
                          <div className={'row'}>
                            {!initialValues.readOnly ? (
                              <div
                                className={`${
                                  initialValues.endRepeat.value === END_OF_REPEAT[0].value
                                    ? 'col-lg-12'
                                    : 'col-lg-6'
                                } fv-row fv-plugins-icon-container`}
                              >
                                <div className={'d-inline-flex w-100'}>
                                  <SelectBase
                                    name={'endRepeat'}
                                    values={initialValues}
                                    changeValue={changeValue}
                                    formik={formik}
                                    required={true}
                                    readOnly={initialValues.readOnly}
                                    option={{
                                      value: 'value',
                                      name: function (value: any) {
                                        return value['label']
                                      },
                                    }}
                                    data={END_OF_REPEAT}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {!initialValues.readOnly &&
                            initialValues.endRepeat.value === END_OF_REPEAT[1].value ? (
                              <div className={'col-lg-6 fv-row fv-plugins-icon-container'}>
                                <div className={'d-inline-flex w-100'}>
                                  <DateTimePickerDefault
                                    name={'endDateRepeat'}
                                    value={initialValues.endDateRepeat}
                                    className={'w-100'}
                                    placeholder={getTranslation('PLACEHOLDER', intl)}
                                    required={false}
                                    format={DEFAULT_DATE_PICKER_FORMAT}
                                    formik={formik}
                                    isInline={true}
                                    changeValue={changeValue}
                                    readOnly={initialValues.readOnly}
                                  />
                                </div>
                              </div>
                            ) : (
                              <>
                                {initialValues.readOnly ? (
                                  <div className={'col-lg-6 fv-row fv-plugins-icon-container'}>
                                    {initialValues.endRepeat.value === END_OF_REPEAT[1].value
                                      ? moment(initialValues.endDateRepeat).format(
                                          DEFAULT_DATE_FORMAT
                                        )
                                      : getTranslation('CALENDAR.FORM.END.REPEAT.NEVER', intl)}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}

              <InputInline
                name={'place_address'}
                label={getTranslation('CALENDAR.FORM.ADDRESS', intl)}
                value={initialValues.place_address}
                placeholder={getTranslation('PLACEHOLDER', intl)}
                required={false}
                inputSize={'col-lg-12'}
                formik={formik}
                changeValue={changeValue}
                readOnly={initialValues.readOnly}
              />
              <SelectInline
                values={initialValues}
                name={'label'}
                placeholder={getTranslation('MESSAGES.FORM.PLACEHOLDER.LABEL', intl)}
                label={getTranslation('MESSAGES.FORM.LABEL', intl)}
                formik={formik}
                changeValue={changeValue}
                data={labels}
                readOnly={initialValues.readOnly}
                readOnlyDescription={true}
                option={{
                  value: 'value',
                  name: function (value: any) {
                    return value['label']
                  },
                }}
                description={
                  <>
                    {initialValues.label ? (
                      <DefaultBadge
                        // @ts-ignore
                        color={getColorLabel(initialValues.label.type)}
                      >
                        {initialValues.label.label}
                      </DefaultBadge>
                    ) : null}
                  </>
                }
              />
              <SelectInline
                values={initialValues}
                name={'isPublic'}
                label={getTranslation('CALENDAR.FORM.VISIBILITY', intl)}
                formik={formik}
                required={false}
                changeValue={changeValue}
                data={getVisibilityOptions(intl)}
                readOnly={initialValues.readOnly}
                option={{
                  value: 'value',
                  name: function (value: any) {
                    return value['label']
                  },
                }}
              />
              {!isFreeSVJ() ? (
                <SelectInline
                  values={initialValues}
                  name={'notify_period'}
                  label={getTranslation('CALENDAR.FORM.REMIND', intl)}
                  formik={formik}
                  required={false}
                  multipleSelect={true}
                  changeValue={changeValue}
                  data={getNotifyPeriodOptions()}
                  readOnly={initialValues.readOnly}
                  option={{
                    value: 'value',
                    name: function (value: any) {
                      return getTranslation(value['label'], intl)
                    },
                  }}
                />
              ) : null}
            </div>
            <div className={'mb-10'}>
              <div className='mb-5 mt-5'>
                <h2 className='fw-bold-custom text-dark border-bottom w-100'>
                  {getTranslation('CALENDAR.FORM.DESCRIPTION', intl)}
                </h2>
              </div>
              <TextEditor
                name={'place_description'}
                isEdit={!initialValues.readOnly}
                key={initialValues.key + '-3'}
                value={initialValues.place_description}
                action={changeValue}
                formik={formik}
                className={'mb-5'}
              />
            </div>
            {!isFreeSVJ() ? (
              <div className={'mb-15'}>
                <FileManager
                  key={initialValues.key}
                  availableFiles={true}
                  availableAttachments={true}
                  selectedFiles={files}
                  setFiles={setFiles}
                  selectedAttachments={attachments}
                  setAttachments={setAttachments}
                  readOnly={initialValues.readOnly}
                  hasCrudRole={hasCrudRole(EVENTS_MODULE)}
                />
              </div>
            ) : null}

            {initialValues.readOnly && initialValues.acceptRead && !initialValues.loadData ? (
              <div className={'mb-5'}>
                <div className='mb-5 mt-5'>
                  <h2 className='fw-bold-custom text-dark border-bottom w-100'>
                    {getTranslation('CALENDAR.FORM.ACCEPT_READ.TITLE', intl)}
                  </h2>
                </div>
                <div>
                  <DefaultTableMui
                    pagination={false}
                    data={initialValues.allAccepts}
                    columns={acceptReadsColumns(intl)}
                    showColumnFilters={false}
                  />
                </div>
              </div>
            ) : null}

            {initialValues.loadData ? null : (
              <div className={'w-100 border-top pt-5'}>
                {hasCrudRole(EVENTS_MODULE) && initialValues.meetingOfOwnersId ? (
                  <div className={'pb-3'}>
                    <EmptyText text={getTranslation('CALENDAR.FORM.TEXT_MEETING', intl)} />
                  </div>
                ) : null}
                <div className='d-inline-flex justify-content-between align-content-center w-100 page-buttons-area'>
                  <div className='left'>
                    <DefaultButton
                      onClick={(e: any) => {
                        history.push('/kalendar-udalosti' + returnBackParams)
                      }}
                      className={'btn btn-danger btn-sm'}
                      id={'event_back'}
                      loading={EventStore.isDeleting}
                      disable={EventStore.isDeleting}
                    >
                      {getTranslation('ROOMS.FORM.BUTTONS.BACK', intl)}
                    </DefaultButton>
                    {hasCrudRole(EVENTS_MODULE) &&
                    initialValues.readOnly &&
                    !initialValues.meetingOfOwnersId ? (
                      <DefaultButton
                        onClick={(e: any) => {
                          if (initialValues.repeat) {
                            eventConfirmDialog(
                              '',

                              getTranslation(
                                initialValues.acceptRead
                                  ? 'CALENDAR.CONFIRM.QUESTION.DELETE.ACCEPT_READ'
                                  : 'CALENDAR.CONFIRM.QUESTION.DELETE',
                                intl
                              ),
                              deleteFuture,
                              deleteThisAndFuture,
                              deleteThis
                            )
                          } else {
                            confirmDialog(
                              '',
                              getTranslation(
                                initialValues.acceptRead
                                  ? 'CALENDAR.CONFIRM.QUESTION.DELETE.ACCEPT_READ'
                                  : 'CALENDAR.CONFIRM.QUESTION.DELETE',
                                intl
                              ),
                              deleteThis
                            )
                          }
                        }}
                        className={'btn btn-light-danger btn-sm ms-3'}
                        id={'event_delete_form'}
                        loading={EventStore.isDeleting}
                        disable={EventStore.isDeleting}
                      >
                        {getTranslation('CALENDAR.FORM.BUTTONS.DELETE', intl)}
                      </DefaultButton>
                    ) : null}
                  </div>
                  <div className='right'>
                    {hasCrudRole(EVENTS_MODULE) ? (
                      <>
                        {initialValues.readOnly ? (
                          <>
                            <button
                              className={'btn btn-warning btn-sm'}
                              type={'button'}
                              onClick={(e) => {
                                e.preventDefault()
                                if (initialValues.meetingOfOwnersId) {
                                  history.push(
                                    '/shromazdeni-vlastniku/' +
                                      initialValues.meetingOfOwnersId +
                                      '/upravit'
                                  )
                                } else {
                                  history.push('/kalendar-udalosti/' + id + '/upravit')
                                  let initialValuesData = initialValues
                                  initialValuesData.readOnly = false
                                  setInitialValues(initialValuesData)
                                }
                                return false
                              }}
                              id={'event_form_submit'}
                            >
                              {getTranslation('CALENDAR.FORM.BUTTONS.EDIT', intl)}
                            </button>
                          </>
                        ) : (
                          <DefaultButton
                            className={'btn btn-success btn-sm'}
                            type={'submit'}
                            id={'event_form_submit'}
                            loading={EventStore.isCreating}
                            disable={EventStore.isCreating}
                          >
                            {initialValues.id
                              ? getTranslation('CALENDAR.FORM.BUTTONS.SAVE_CHANGES', intl)
                              : getTranslation('CALENDAR.FORM.BUTTONS.CREATE', intl)}
                          </DefaultButton>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </CardLayout>
    </div>
  )
})

export default CalendarForm
