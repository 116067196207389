import React from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {observer} from 'mobx-react'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  extraIcon?: any
  external?: boolean
  bold?: boolean
  count?: number | null
  visible?: boolean
}

const AsideMenuItem: React.FC<Props> = observer(
  ({
    children,
    to,
    title,
    icon,
    fontIcon,
    hasBullet = false,
    extraIcon,
    external = false,
    bold = false,
    count = null,
    visible = true,
  }) => {
    const {pathname} = useLocation()
    const isActive = checkIsActive(pathname, to)
    const {config} = useLayout()
    const {aside} = config

    if (!visible) {
      return null
    }

    if (external) {
      return (
        <div className='menu-item fw-bold-custom'>
          <a
            href={to}
            target={'_blank'}
            rel='noreferrer'
            className={clsx(`menu-link without-sub ${bold ? 'fw-boldest' : ''}`, {
              active: isActive,
            })}
          >
            {icon && aside.menuIcon === 'svg' && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {extraIcon ? extraIcon : null}
            <span className={`menu-title ${bold ? 'fw-boldest' : ''}`}>{title}</span>
            {count !== null && count !== 0 ? (
              <span className='menu-badge'>
                <span className='badge badge-count-custom'>{count}</span>
              </span>
            ) : null}
          </a>
          {children}
        </div>
      )
    }

    return (
      <div className='menu-item fw-bold-custom'>
        <Link
          className={clsx(`menu-link without-sub ${bold ? 'fw-boldest' : ''}`, {active: isActive})}
          to={to}
        >
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && aside.menuIcon === 'svg' && (
            <span className='menu-icon'>
              <KTSVG path={icon} className='svg-icon-2' />
            </span>
          )}
          {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
          {extraIcon ? extraIcon : null}
          <span className='menu-title'>{title}</span>
          {count !== null && count !== 0 ? (
            <span className='menu-badge'>
              <span className='badge badge-count-custom'>{count}</span>
            </span>
          ) : null}
        </Link>
        {children}
      </div>
    )
  }
)

export {AsideMenuItem}
