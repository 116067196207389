import React, {FC, createContext, useContext} from 'react'
import UserStore from '../../app/Stores/User'
import {observer} from 'mobx-react'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: 'cs' | 'de' | 'en' | 'es' | 'fr' | 'ja' | 'zh'
}
const initialState: Props = {
  // @ts-ignore
  selectedLang: UserStore.selectLang,
}

function getConfig(): Props {
  // const ls = localStorage.getItem(I18N_CONFIG_KEY)
  // if (ls) {
  //   try {
  //     return JSON.parse(ls) as Props
  //   } catch (er) {
  //     console.error(er)
  //   }
  // }
  return {
    // @ts-ignore
    selectedLang: UserStore.selectLang,
  }
}

// Side effect
export async function setLanguage(lang: string) {
  // localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  await UserStore.changeLanguage(lang)
  // window.location.reload()
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const MetronicI18nProvider: FC = observer(({children}) => {
  const lang = getConfig()
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
})

export {MetronicI18nProvider, useLang}
