import {FC} from 'react'
import {observer} from 'mobx-react'
import * as React from 'react'
import {getTranslation} from '../../Helpers/Default'

const SectionTitle: FC<{
  buttons?: any
  description?: any
  className?: any
  showHidden?: any
  titleWidth?: string
}> = observer((props) => {
  return (
    <>
      <div
        className={`w-100 mb-5 d-inline-flex justify-content-between border-bottom align-items-center ${
          props.className ? props.className : ''
        }`}
      >
        <div className={`${props.titleWidth ? props.titleWidth : 'w-50'}`}>
          <h2 className='fw-bold-custom text-dark w-100'>{props.children}</h2>
          <div className={'form-text'}>{props.description}</div>
        </div>
        <div className={'d-inline-flex align-items-center'}>
          {props.showHidden ? props.showHidden : null}
          {props.buttons && props.buttons.length > 0 ? (
            <div className={'d-inline-flex align-items-center justify-content-end pb-2'}>
              {props.buttons}
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
})

export default SectionTitle
