import {SELECT_DEFAULT_STYLE} from '../../Constants/Select'
import Select, {components, GroupHeadingProps} from 'react-select'
import React, {FC} from 'react'
import {observer} from 'mobx-react'
import {getTranslation} from '../../Helpers/Default'
import {useIntl} from 'react-intl'
import CreatableSelect from 'react-select/creatable'

const SelectBase: FC<{
  formik?: any
  className?: string
  required?: boolean
  name: string
  placeholder?: string
  label?: string
  type?: string
  changeValue: any
  availableSearch?: boolean
  multipleSelect?: boolean
  values: any
  data: any
  option: any
  isCreatable?: boolean
  readOnly?: boolean
  no_option_message?: string
  disabled?: boolean
}> = observer((props: any) => {
  let inputClass = ['form-select', 'form-select-lg', 'form-control-solid'] //"form-select-solid"
  const intl = useIntl()
  const isCreatable = props.isCreatable ? props.isCreatable : false
  let value = props.values[props.name]
  const isDisabled = props.disabled ? props.disabled : false
  //
  // if (value && value.translate) {
  //   value.label = getTranslation(value.label, intl)
  // }

  const groupStyles = {
    border: `2px dotted #000`,
    color: 'white',
    background: '#000',
    padding: '5px 0px',
    display: 'flex',
  }

  const GroupHeading = (props: GroupHeadingProps) => (
    <div style={groupStyles}>
      <components.GroupHeading {...props} />
    </div>
  )

  if (props.readOnly) {
    let value = props.values[props.name]
    let values: any = []
    props.data.forEach((b: any) => {
      if (b[props?.option?.value] === value) {
        values.push(props.option.name(b))
      }
    })

    if (values.length === 0) {
      values.push(' - ')
    }

    return <div>{values.map((item: any) => item)}</div>
  }
  const CustomOption = (props: any) => {
    return (
      <components.Option {...props}>
        <div>{props.data.label}</div>
      </components.Option>
    )
  }

  if (isCreatable) {
    return (
      <CreatableSelect
        isClearable
        formatCreateLabel={(inputValue) =>
          `${getTranslation('BASE.SELECT.ADD', intl)} ${inputValue}`
        }
        inputId={props.name}
        key={props.values[props.name]}
        name={props.name}
        isSearchable={props.availableSearch || false}
        defaultValue={value}
        isDisabled={isDisabled}
        placeholder={props.placeholder || getTranslation('BASE.SELECT.SELECT', intl)}
        noOptionsMessage={() =>
          getTranslation(
            props.no_option_message ? props.no_option_message : 'BASE.SELECT.NO_OPTIONS',
            intl
          )
        }
        components={{Option: CustomOption, GroupHeading}}
        styles={SELECT_DEFAULT_STYLE}
        isMulti={props.multipleSelect || false}
        onChange={(e) => {
          props.changeValue({
            target: {
              value: e,
              name: props.name,
            },
          })
        }}
        className={inputClass.join(' ')}
        isOptionDisabled={(option) => option.disabled}
        options={
          props.data.map((item: any) => {
            return {
              ...item,
              value: item[props.option.value],
              label: props.option.name(item),
            }
          }) ?? []
        }
      />
    )
  }

  return (
    <Select
      key={props.values[props.name]}
      name={props.name}
      isSearchable={props.availableSearch || false}
      defaultValue={value}
      value={value}
      isDisabled={isDisabled}
      placeholder={props.placeholder || getTranslation('BASE.SELECT.SELECT', intl)}
      components={{Option: CustomOption}}
      styles={SELECT_DEFAULT_STYLE}
      isMulti={props.multipleSelect || false}
      noOptionsMessage={() =>
        getTranslation(
          props.no_option_message ? props.no_option_message : 'BASE.SELECT.NO_OPTIONS',
          intl
        )
      }
      onChange={(e) => {
        props.changeValue({
          target: {
            value: e,
            name: props.name,
          },
        })
      }}
      className={inputClass.join(' ')}
      isOptionDisabled={(option) => option.disabled}
      options={
        props.data.map((item: any) => {
          return {
            ...item,
            value: item[props.option.value],
            label: props.option.name(item),
          }
        }) ?? []
      }
    />
  )
})

export default SelectBase
