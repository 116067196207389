import {observer} from 'mobx-react'
import {getRandomKey, getTranslation} from '../../../../Helpers/Default'
import SectionTitle from '../../../../Components/SectionTitle'
import * as React from 'react'
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import InputInline from '../../../../Components/InputInline'
import {useFormik} from 'formik'
import SwitchInline from '../../../../Components/SwitchInline'
import {PageTitle} from '../../../../../_metronic/layout/core'
import CardLayout from '../../../../Components/CardLayout'
import OrderStore from '../../../../Stores/Order'
import SelectInline from '../../../../Components/SelectInline'
import {LinkButton} from '../../../../Components/LinkButton'
import {DefaultButton} from '../../../../Components/Buttons/DefaultButton'
import {checkFormErrors, checkValueError} from '../../../../Helpers/Form'
import {isEmpty} from 'lodash'
import {confirmDialog} from '../../../../Helpers/ConfirmDialog'
import {useHistory} from 'react-router-dom'
const AdminOrderForm: FC<{}> = observer(() => {
  const intl = useIntl()
  const history = useHistory()

  const [initialValues, setInitialValues] = React.useState<any>({
    email: '',
    isFree: false,
    careAboutId: null,
    readOnly: false,
  })

  const [employee, setEmployee] = React.useState<any>([])

  useEffect(() => {
    async function getData() {
      const employees = await OrderStore.getEmployee()
      if (employees) {
        const mapEmployees = employees.map((e: any) => ({
          label: e.firstName + ' ' + e.lastName,
          value: e.id,
        }))
        setEmployee(mapEmployees)
        setInitialValues((prev: any) => {
          return {
            ...prev,
            careAboutId: mapEmployees.find((e: any) => e.value.indexOf('Jan') > -1),
          }
        })
      }
    }

    getData()
  }, [])

  const renderLeftButtons = () => {
    return (
      <>
        <LinkButton
          color={'danger'}
          link={`/admin/objednavky`}
          text={getTranslation('ADMIN.ORDER.FORM.BUTTONS.BACK', intl)}
        />
      </>
    )
  }
  const renderRightButtons = () => {
    return (
      <>
        <DefaultButton
          className={'btn btn-success btn-sm'}
          type={'submit'}
          id={'messages_form_submit'}
          loading={OrderStore.isCreating}
          disable={OrderStore.isCreating}
        >
          {getTranslation('ADMIN.ORDER.FORM.BUTTONS.CREATE', intl)}
        </DefaultButton>
      </>
    )
  }

  const changeValue = (e: any) => {
    setInitialValues((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      }
    })
  }

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values, {setSubmitting, setErrors}) => {
      let errors = {}
      let isFocused = false

      isFocused = checkValueError(
        'careAboutId',
        isEmpty(initialValues.careAboutId),
        isFocused,
        'ADMIN.ORDER.FORM.VALIDATION.CARE_ABOUT',
        errors,
        intl
      )

      if (!checkFormErrors(errors, setErrors, setSubmitting)) {
        confirmDialog('', getTranslation('ADMIN.ORDER.FORM.CONFIRM.CREATE', intl), async () => {
          const data = {
            email: initialValues.email,
            careAboutId: initialValues.careAboutId.value,
            isFree: initialValues.isFree,
          }
          if (await OrderStore.create(data)) {
            history.push('/admin/objednavky')
          }
        })
      }
    },
  })

  return (
    <>
      <PageTitle pageButtons={[]} linkBack={`/admin/objednavky`}>
        {getTranslation(
          initialValues.readOnly ? 'MESSAGES.FORM.TITLE_DETAIL' : 'MESSAGES.FORM.TITLE_CREATE',
          intl
        )}
      </PageTitle>
      <CardLayout>
        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='message_form'>
          <div
            className={`mx-auto w-100 mw-600px  pb-10 fv-plugins-bootstrap5 fv-plugins-framework pt-15`}
          >
            <div className='d-flex flex-column mb-8 fv-row fv-plugins-icon-container'>
              <SectionTitle buttons={[]}>
                {getTranslation('ADMIN.ORDER.FORM.TITLE.INFORMATION', intl)}
              </SectionTitle>

              <div className='row'>
                <InputInline
                  id={'email'}
                  name={'email'}
                  type={'email'}
                  inputBold={true}
                  value={initialValues.email}
                  required={true}
                  label={getTranslation('ADMIN.ORDER.FORM.EMAIL', intl)}
                  placeholder={getTranslation('ADMIN.ORDER.FORM.EMAIL_PLACEHOLDER', intl)}
                  formik={formik}
                  changeValue={changeValue}
                  readOnly={initialValues.readOnly}
                />

                <SelectInline
                  key={getRandomKey()}
                  values={initialValues}
                  name={'careAboutId'}
                  placeholder={getTranslation('ADMIN.ORDER.FORM.PLACEHOLDER.CARE_ABOUT', intl)}
                  label={getTranslation('ADMIN.ORDER.FORM.CARE_ABOUT', intl)}
                  formik={formik}
                  changeValue={changeValue}
                  data={employee}
                  readOnly={initialValues.readOnly}
                  option={{
                    value: 'value',
                    name: function (value: any) {
                      return value.label
                    },
                  }}
                />

                <SwitchInline
                  label={getTranslation('ADMIN.ORDER.FORM.IS_FREE', intl)}
                  columnLabel={true}
                  name={'isFree'}
                  value={initialValues.isFree}
                  // label={
                  //   initialValues.readOnly ? getTranslation('MESSAGES.FORM.PIN_MESSAGE', intl) : null
                  // }
                  formik={formik}
                  changeValue={changeValue}
                  readOnly={initialValues.readOnly}
                  texts={{
                    select: getTranslation('BASE.YES', intl),
                    unselect: getTranslation('BASE.NO', intl),
                  }}
                />
              </div>
            </div>
            <div className={'w-100 border-top pt-5'}>
              <div className='d-inline-flex justify-content-between align-content-center w-100 page-buttons-area'>
                <div className='left'>{renderLeftButtons()}</div>
                <div className='right'>{renderRightButtons()}</div>
              </div>
            </div>
          </div>
        </form>
      </CardLayout>
    </>
  )
})

export default AdminOrderForm
