import {FC, useEffect} from 'react'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {getRandomKey, getTranslation, renderFormattedDate} from '../../../Helpers/Default'
import {DefaultModal} from '../../DefaultModal'
import * as React from 'react'
import FolderStore from '../../../Stores/Folder'
import FileStore from '../../../Stores/File'
import {TooltipDefault} from '../../Tooltip'
import {getDocumentIcon} from '../../../Helpers/Document'
import VisibilityIcon from '../../VisibilityIcon'
import {getFullNameSimple, hasCommitteeRole, renderFullNameWithIcon} from '../../../Helpers/User'
import DocumentStore from '../../../Stores/Document'
import EmptyText from '../../EmptyText'

const DocumentsModal: FC<{show?: boolean; closeModal: any; setFiles: any; selectedFiles: any}> =
  observer(({show = false, closeModal, setFiles, selectedFiles}) => {
    const intl = useIntl()
    const [breadcrumbSelectedFolder, setBreadcrumbSelectedFolder] = React.useState<any>([])

    useEffect(() => {
      async function getStructure() {
        let param = null
        // if (urlBreadcrumb) {
        //   const list = urlBreadcrumb.split(',')
        //   const folders: any = []
        //   for (let i in list) {
        //     const id = list[i]
        //     if (id !== 'root') {
        //       let folder = await FolderStore.get(id)
        //       if (folder) {
        //         folders.push({name: folder.name, id: folder.id})
        //       }
        //     }
        //   }
        //
        //   // setBreadcrumbSelectedFolder([...breadcrumbSelectedFolder, ...folders])
        //
        //   if (folders.length > 0) {
        //     param = folders[folders.length - 1].id
        //   }
        //   // DocumentStore.setValue('folder_id', param)
        //   FolderStore.getAll(param)
        //   FileStore.getAll(param)
        // } else {
        FolderStore.getAll()
        FileStore.getAll()
        // }
      }
      getStructure()
    }, [])

    const handleFolderClick = async (data: any) => {
      DocumentStore.setValue('page', 0)
      DocumentStore.setValue('folder_id', data.id)
      const oldBreadcrumb = breadcrumbSelectedFolder
      oldBreadcrumb.push({name: data.name, id: data.id})
      setBreadcrumbSelectedFolder([...oldBreadcrumb])
      await FolderStore.getAll(data.id)
      await FileStore.getAll(data.id)
      // let oldBreadcrumb = breadcrumbSelectedFolder
      // oldBreadcrumb.push({name: data.name, id: data.id})
      // setBreadcrumbSelectedFolder([...oldBreadcrumb])
      // changeUrlBreadcrumb(oldBreadcrumb)
    }

    const getFolders = () => {
      let folders = []
      for (let i in FolderStore.folders) {
        let folder = FolderStore.folders[i]
        folders.push(
          <tr key={getRandomKey()}>
            <td></td>
            <td onClick={() => handleFolderClick(folder)}>
              <div
                className={
                  'text-dark fw-bold-custom text-hover-primary fs-6 cursor-pointer d-inline-flex align-items-center'
                }
              >
                <TooltipDefault tooltip={folder.name} placement={'top'}>
                  <div className={'d-inline-flex align-items-center'}>
                    {getDocumentIcon(folder, 'folder')}
                    <div className={'document-name'}>{folder.name}</div>
                  </div>
                </TooltipDefault>
              </div>
            </td>
            <td className={'min-w-125px'}>{renderFormattedDate(folder.createdAt, true)}</td>
          </tr>
        )
      }

      return folders
    }

    const getFiles = () => {
      const files = []
      let owner: any = ''
      for (let i in FileStore.files) {
        let file: any = FileStore.files[i]
        let tooltip = 'DOCUMENT.UN_VISIBLE'
        // @ts-ignore
        let show = file.isPublic
        if (show) {
          tooltip = 'DOCUMENT.VISIBLE'
        }
        // @ts-ignore
        if (!file.name) {
          continue
        }
        const foundFile = selectedFiles.find((item: any) => item.id === file.id)
        files.push(
          <tr key={getRandomKey()}>
            <td>
              <input
                type={'checkbox'}
                onClick={() => {
                  const foundFile = selectedFiles.find((item: any) => item.id === file.id)

                  if (foundFile) {
                    setFiles(selectedFiles.filter((item: any) => item.id !== file.id))
                  } else {
                    setFiles([...selectedFiles, file])
                  }
                }}
                checked={foundFile}
              />
            </td>
            <td>
              <div
                className={
                  'text-dark fw-bold-custom text-hover-primary fs-6 cursor-pointer d-inline-flex align-items-center'
                }
              >
                {/*// @ts-ignore */}
                <TooltipDefault tooltip={file.name} position='top'>
                  <div className={'d-inline-flex align-items-center'}>
                    {/*// @ts-ignore */}
                    {getDocumentIcon(file, file.type)}
                    {/*// @ts-ignore */}
                    <div className={'document-name'}>{file.name}</div>
                  </div>
                </TooltipDefault>
              </div>
            </td>
            {/*// @ts-ignore */}
            <td className={'min-w-125px'}>{renderFormattedDate(file.createdAt, true)}</td>
            {hasCommitteeRole() ? (
              <td>
                <VisibilityIcon tooltip={getTranslation(tooltip, intl)} isVisible={show} />
              </td>
            ) : null}
          </tr>
        )
      }

      return files
    }

    const goBackInFolder = () => {
      const oldBreadcrumb = breadcrumbSelectedFolder
      oldBreadcrumb.pop()
      setBreadcrumbSelectedFolder([...oldBreadcrumb])
      if (oldBreadcrumb.length === 0) {
        FolderStore.getAll()
        FileStore.getAll()
      } else {
        FolderStore.getAll(oldBreadcrumb[oldBreadcrumb.length - 1].id)
        FileStore.getAll(oldBreadcrumb[oldBreadcrumb.length - 1].id)
      }
    }

    return (
      <>
        <DefaultModal
          class={'modal-xl'}
          title={getTranslation('MESSAGES.FORM.TITLE', intl)}
          show={show}
          onHide={closeModal}
        >
          <div className='modal-header'>
            <h5 className='modal-title' id='exampleModalLabel'>
              {getTranslation('FILE.SELECT.MODAL', intl)}
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={closeModal}
            ></button>
          </div>
          <div className='modal-body'>
            {breadcrumbSelectedFolder.length > 0 ? (
              <div className='row'>
                <div className='col-12'>
                  <button
                    className='btn btn-sm btn-light btn-light-primary me-3'
                    onClick={goBackInFolder}
                  >
                    {getTranslation('FILE_MANAGER.DOCUMENT.FOLDER.BACK', intl)}
                  </button>
                </div>
              </div>
            ) : null}
            <div className='row'>
              <div className='col-12'>
                <table
                  id='kt_file_manager_list'
                  data-kt-filemanager-table='files'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                >
                  <thead>
                    <tr className='text-start text-gray-400 fw-bold-custom fs-7 gs-0'>
                      <th className='sorting_disabled'></th>
                      <th className='sorting_disabled'>
                        {getTranslation('CALENDAR.TABLE.TITLE.NAME', intl)}
                      </th>
                      <th className='sorting_disabled'>
                        {getTranslation('CALENDAR.TABLE.TITLE.DATE_ADD', intl)}
                      </th>
                      {hasCommitteeRole() ? (
                        <th className='sorting_disabled' style={{width: '100px'}}>
                          {getTranslation('BASE.VISIBILITY', intl)}
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody className='fw-semibold text-gray-600'>
                    {FolderStore.folders.length === 0 && FileStore.files.length === 0 ? (
                      <tr>
                        <td colSpan={hasCommitteeRole() ? 5 : 4}>
                          <EmptyText text={getTranslation('DOCUMENT.TABLE.EMPTY', intl)} />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {getFolders()}
                        {getFiles()}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-sm btn-light btn-active-light-primary me-3'
                    onClick={() => {
                      closeModal()
                    }}
                  >
                    {getTranslation('BASE.CANCEL', intl)}
                  </button>
                  <button
                    className='btn btn-sm btn-success'
                    onClick={() => {
                      closeModal()
                    }}
                  >
                    {getTranslation('BASE.SAVE', intl)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DefaultModal>
      </>
    )
  })

export default DocumentsModal
