import React, {FC, useEffect, useState, KeyboardEvent} from 'react'
import {observer} from 'mobx-react'
import {getTranslation} from '../../../../Helpers/Default'
import SectionTitle from '../../../../Components/SectionTitle'
import {useIntl} from 'react-intl'
import ModalHeader from 'react-bootstrap-v5/lib/cjs/ModalHeader'
import {DefaultModal} from '../../../../Components/DefaultModal'
import {ModalBody} from 'react-bootstrap-v5'
import {Input} from '@mui/material'
import {showNotification} from '../../../../Helpers/Notification'
import {NOTIFICATION_TYPE} from '../../../../Constants/Notification'
import {DefaultButton} from '../../../../Components/Buttons/DefaultButton'
import VoteStore from '../../../../Stores/Vote'
const ValidationCodeModal: FC<{
  show: any
  onHide: any
  close: any
  confirm: any
  sendAgain: any
  voteId: string
  votedUsers: any
}> = observer((props) => {
  const intl = useIntl()

  const [code, setCode] = useState(Array(6).fill(''))
  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    if (input.length > 1 && index === 0) {
      const splitCode = input.split('').slice(0, code.length)
      if (splitCode.length < code.length) {
        const diffNumber = code.length - splitCode.length
        for (let i = 0; i < diffNumber; i++) {
          splitCode.push('')
        }
      }
      setCode(splitCode)
      if (input.length === code.length) {
        const lastInput = document.getElementById(`input-${code.length - 1}`)
        if (lastInput) {
          lastInput.focus()
        }
      }
    } else if (input.length === 1) {
      const newCode = [...code]
      newCode[index] = input
      setCode(newCode)

      // Přesunutí fokusu na další pole, pokud existuje
      if (index < code.length - 1) {
        const nextInput = document.getElementById(`input-${index + 1}`)
        if (nextInput) {
          nextInput.focus()
        }
      }
    }
  }

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      props.confirm(code)
    }
  }

  useEffect(() => {
    async function getData() {}

    getData()
  }, [])

  const handleSubmit = () => {
    if (code.includes('')) {
      showNotification(NOTIFICATION_TYPE.danger, 'VOTE.MODAL.CODE.ERROR')
    } else {
      // Call the function to handle the submission
      props.confirm(code)
    }
  }

  return (
    <>
      <DefaultModal show={props.show} onHide={props.onHide} width={'mw-700px'}>
        <ModalHeader>
          <h3>{getTranslation('VOTE.MODAL.CODE.TITLE', intl)}</h3>
        </ModalHeader>
        <ModalBody>
          <div className='w-100 text-center mb-4 fs-5'>
            {getTranslation('VOTE.MODAL.CODE.DESCRIPTION', intl)}
          </div>
          <div className='d-inline-flex w-100 justify-content-center'>
            {code.map((value, index) => (
              <input
                key={index}
                id={`input-${index}`}
                className='form-control form-control-lg border-bottom me-3 fs-2 text-center'
                style={{width: '70px'}}
                type='text'
                value={value}
                onFocus={(event: any) => {
                  event.target.select()
                }}
                onChange={(event) => handleChange(index, event)}
                onKeyPress={handleKeyPress}
                // maxLength={1}
                pattern='\d*'
              />
            ))}
          </div>
          <div className='text-center w-100 mt-5'>
            <span
              className='btn btn-link btn-color-primary'
              onClick={() => {
                props.sendAgain(props.voteId, props.votedUsers)
              }}
            >
              {getTranslation('VOTE.MODAL.CODE.RESEND', intl)}
            </span>
          </div>
          <div className='text-center w-100 mt-5'>
            <DefaultButton
              onClick={(e: any) => {
                e.preventDefault()
                props.close()
              }}
              className={'btn btn-light-danger btn-sm me-3'}
              id={'vote_cancel_code'}
              loading={false}
              disable={false}
            >
              {getTranslation('VOTE.MODAL.CODE.BUTTON.CANCEL', intl)}
            </DefaultButton>
            <DefaultButton
              onClick={(e: any) => {
                e.preventDefault()
                handleSubmit()
              }}
              className={'btn btn-success'}
              id={'vote_code_submit'}
              loading={false}
              disable={false}
            >
              {getTranslation('VOTE.MODAL.CODE.CHECK', intl)}
            </DefaultButton>
          </div>
        </ModalBody>
      </DefaultModal>
    </>
  )
})

export default ValidationCodeModal
