/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {getTranslation} from '../../../../app/Helpers/Default'
import SvjStore from './../../../../app/Stores/Svj'
import {observer} from 'mobx-react'
import ReportStore from './../../../../app/Stores/Reports'
import VoteStore from './../../../../app/Stores/Vote'
import SurveyStore from './../../../../app/Stores/Survey'
import MeetingStore from './../../../../app/Stores/MeetingOfOwner'
import RequirementForApprovalStore from './../../../../app/Stores/RequirementForApproval'
import {userHasModule} from '../../../../app/Helpers/User'
import UserStore from '../../../../app/Stores/User'

import {
  CASH_DESK_MODULE,
  CONTACT_MODULE,
  DOCUMENTS_MODULE,
  EVENTS_MODULE,
  GALLERY_MODULE,
  MEETING_OWNERS_MODULE,
  MESSAGES_MODULE,
  ORDER_MODULE,
  REPORTS_MODULE,
  REQUIREMENT_FOR_APPROVAL_MODULE,
  ROOMS_MODULE,
  SURVEY_MODULE,
  SVJ_FEE_MODULE,
  SVJ_MODULE,
  USERS_MODULE,
  VOTE_MODULE,
} from '../../../../app/Constants/Modules'
import {toJS} from 'mobx'
import {isFreeSVJ} from '../../../../app/Helpers/Svj'

export const AsideMenuMain = observer(() => {
  const intl = useIntl()
  const favoritesModules = toJS(UserStore.user?.settings?.favorite_modules) ?? []
  const baseOrder = [
    EVENTS_MODULE,
    DOCUMENTS_MODULE,
    MESSAGES_MODULE,
    USERS_MODULE,
    ROOMS_MODULE,
    REPORTS_MODULE,
    SURVEY_MODULE,
    VOTE_MODULE,
    MEETING_OWNERS_MODULE,
    GALLERY_MODULE,
    CONTACT_MODULE,
    REQUIREMENT_FOR_APPROVAL_MODULE,
    SVJ_MODULE,
    CASH_DESK_MODULE,
    SVJ_FEE_MODULE,
  ]

  const getMenuItemByModule = (module: string) => {
    switch (module) {
      case EVENTS_MODULE:
        return (
          <AsideMenuItem
            to={`/kalendar-udalosti`}
            title={getTranslation('MENU.CALENDAR', intl)}
            icon='/media/icons/duotune/general/gen014.svg'
            key={SvjStore.data?.url_hash ?? ''}
            visible={userHasModule(EVENTS_MODULE)}
          />
        )
      case DOCUMENTS_MODULE:
        return (
          <AsideMenuItem
            to={`/dokumenty`}
            title={getTranslation('MENU.DOCUMENTS', intl)}
            icon='/media/icons/duotune/files/fil012.svg'
            visible={userHasModule(DOCUMENTS_MODULE)}
          />
        )
      case MESSAGES_MODULE:
        return (
          <AsideMenuItem
            to={`/zpravy`}
            title={getTranslation('MENU.MESSAGES', intl)}
            icon='/media/icons/duotune/communication/com012.svg'
            visible={userHasModule(MESSAGES_MODULE)}
          />
        )
      case USERS_MODULE:
        return (
          <AsideMenuItem
            to='/sousede'
            title={getTranslation('MENU.USERS', intl)}
            icon='/media/icons/duotune/communication/com013.svg'
            visible={userHasModule(USERS_MODULE)}
          />
        )
      case ROOMS_MODULE:
        return (
          <AsideMenuItem
            to='/jednotky'
            title={getTranslation('MENU.ROOMS', intl)}
            icon='/media/icons/duotune/general/gen025.svg'
            visible={userHasModule(ROOMS_MODULE)}
          />
        )
      case REPORTS_MODULE:
        return (
          <AsideMenuItem
            to='/hlaseni'
            title={getTranslation('MENU.REPORTS', intl)}
            icon='/media/icons/duotune/communication/com004.svg'
            count={ReportStore.newCount}
            visible={userHasModule(REPORTS_MODULE)}
          />
        )
      case SURVEY_MODULE:
        return (
          <AsideMenuItem
            to='/ankety'
            title={getTranslation('MENU.SURVEY', intl)}
            icon='/media/icons/duotune/graphs/gra006.svg'
            count={SurveyStore.activeCount}
            visible={userHasModule(SURVEY_MODULE)}
          />
        )
      case VOTE_MODULE:
        return (
          <AsideMenuItem
            to='/hlasovani'
            title={getTranslation('MENU.VOTES', intl)}
            icon='/media/icons/duotune/graphs/gra010.svg'
            count={VoteStore.activeCount}
            visible={userHasModule(VOTE_MODULE)}
          />
        )
      case MEETING_OWNERS_MODULE:
        return (
          <AsideMenuItem
            to='/shromazdeni-vlastniku'
            title={getTranslation('MENU.MEETING_OF_OWNERS', intl)}
            icon='/media/icons/duotune/communication/com014.svg'
            count={MeetingStore.scheduledCount}
            visible={userHasModule(MEETING_OWNERS_MODULE)}
          />
        )
      case GALLERY_MODULE:
        return (
          <AsideMenuItem
            to='/fotogalerie'
            visible={userHasModule(GALLERY_MODULE)}
            title={getTranslation('MENU.GALLERY', intl)}
            icon='/media/icons/duotune/general/gen006.svg'
          />
        )
      case CONTACT_MODULE:
        return (
          <AsideMenuItem
            visible={userHasModule(CONTACT_MODULE)}
            to='/adresar-kontaktu'
            title={getTranslation('MENU.CONTACTS_BOOK', intl)}
            icon='/media/icons/duotune/communication/com005.svg'
          />
        )
      case REQUIREMENT_FOR_APPROVAL_MODULE:
        return (
          <AsideMenuItem
            to='/pozadavky-ke-schvaleni'
            visible={userHasModule(REQUIREMENT_FOR_APPROVAL_MODULE)}
            title={getTranslation('MENU.REQUIREMENT_FOR_APPROVAL', intl)}
            count={RequirementForApprovalStore.waitingCount}
            icon='/media/icons/duotune/files/fil025.svg'
          />
        )
      case SVJ_MODULE:
        return (
          <AsideMenuItem
            to='/detail-svj'
            visible={userHasModule(SVJ_MODULE)}
            title={getTranslation('MENU.SVJ', intl)}
            icon='/media/icons/duotune/maps/map008.svg'
          />
        )
      case CASH_DESK_MODULE:
        return (
          <AsideMenuItem
            to='/pokladna'
            visible={userHasModule(CASH_DESK_MODULE)}
            title={getTranslation('MENU.CASH_DESK', intl)}
            icon='/media/icons/duotune/finance/fin001.svg'
          />
        )
      case SVJ_FEE_MODULE:
        return (
          <AsideMenuItem
            visible={userHasModule(SVJ_FEE_MODULE)}
            to='/prehled-poplatku'
            title={getTranslation('MENU.SVJ_FEES', intl)}
            icon='/media/icons/duotune/finance/fin008.svg'
          />
        )
    }
  }

  return (
    <>
      <AsideMenuItem
        to='/nastenka'
        icon='/media/icons/duotune/maps/map009.svg'
        title={getTranslation('MENU.DASHBOARD', intl)}
        bold={true}
        // fontIcon='fa fa-map-signs'
        // icon='/media/icons/duotune/maps/map009.svg'
      />
      {favoritesModules.length > 0 && !isFreeSVJ() ? (
        <AsideMenuItemWithSub
          to='/crafted/pages'
          title={getTranslation('MENU.FAVORITE_MODULES', intl)}
          bold={true}
          active={true}
          icon={'/media/icons/duotune/general/gen029.svg'}
        >
          {favoritesModules.map((module: any) => getMenuItemByModule(module))}
        </AsideMenuItemWithSub>
      ) : null}
      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-8 pb-2'>*/}
      {/*    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Menu'
        bold={true}
        active={true}
        icon={'/media/icons/duotune/general/gen033.svg'}
      >
        {baseOrder
          .filter((module) => !favoritesModules.includes(module))
          .map((module) => getMenuItemByModule(module))}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Podpora'
        bold={true}
        // fontIcon='bi-archive'
        // icon='/media/icons/duotune/general/gen022.svg'
        icon={'/media/icons/duotune/general/gen046.svg'}
      >
        <AsideMenuItem
          to='https://helpdesk.svjaplikace.cz/'
          external={true}
          title={getTranslation('MENU.FAQ', intl)}
          icon='/media/icons/duotune/abstract/abs035.svg'
        />
        <AsideMenuItem
          external={true}
          to='https://helpdesk.svjaplikace.cz/zpetna-vazba/'
          title={getTranslation('MENU.FEEDBACK', intl)}
          icon='/media/icons/duotune/abstract/abs026.svg'
        />
        <AsideMenuItem
          external={true}
          to=' https://helpdesk.svjaplikace.cz/aktualizace-aplikace/'
          title={getTranslation('MENU.UPDATE_INFO', intl)}
          icon='/media/icons/duotune/arrows/arr029.svg'
        />
      </AsideMenuItemWithSub>

      {/*<AsideMenuItemWithSub*/}
      {/*  to='/crafted/pages'*/}
      {/*  title='Komunita'*/}
      {/*  bold={true}*/}
      {/*  // fontIcon='bi-archive'*/}
      {/*  icon='/media/icons/duotune/communication/com014.svg'*/}
      {/*>*/}
      {/*  <AsideMenuItem*/}
      {/*    to='/crafted/pages/wizards/vertical'*/}
      {/*    title={getTranslation('MENU.SVJ_ONLINE_MAGAZINE', intl)}*/}
      {/*    icon='/media/icons/duotune/electronics/elc004.svg'*/}
      {/*  />*/}
      {/*</AsideMenuItemWithSub>*/}

      {/*<AsideMenuItemWithSub*/}
      {/*  to='/crafted/pages'*/}
      {/*  title='Kontaktní osoba'*/}
      {/*  bold={true}*/}
      {/*  // fontIcon='bi-archive'*/}
      {/*  // icon='/media/icons/duotune/general/gen022.svg'*/}
      {/*>*/}
      {/*  <div className='menu-item'>*/}
      {/*    <div className='menu-content pt-8 pb-2'>*/}
      {/*      <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</AsideMenuItemWithSub>*/}

      {/*<div className='menu-item'>*/}
      {/*  <a*/}
      {/*    target='_blank'*/}
      {/*    className='menu-link'*/}
      {/*    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}*/}
      {/*  >*/}
      {/*    <span className='menu-icon'>*/}
      {/*      <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />*/}
      {/*    </span>*/}
      {/*    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>*/}
      {/*  </a>*/}
      {/*</div>*/}
    </>
  )
})
