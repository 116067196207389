import {observer} from 'mobx-react'
import React, {FC} from 'react'
import clsx from 'clsx'

const InputSimple: FC<{
  id?: string
  value: any
  formik?: any
  className?: string
  required?: boolean
  name: string
  placeholder?: string
  label?: string
  type?: string
  changeValue: any
  readOnly?: boolean
  onBlur?: any
  description?: string
  innerRef?: any
  groupAfterText?: string
  disabled?: boolean
  simpleTextAfter?: string
  inputBold?: boolean
}> = observer((props: any) => {
  let labelClass = []
  let inputClass = ['form-control', 'form-control-lg', 'form-control-solid']
  let disabled = props.disabled ?? false
  let inputBold = props.inputBold ?? false

  if (props.required) {
    labelClass.push('required')
    inputClass.push('required')
  }

  if (props.className) {
    inputClass.push(props.className)
  }

  if (props.formik) {
    inputClass.push(
      clsx(
        '',
        {'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name]},
        {'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name]}
      )
    )
  }

  const getInput = () => {
    return (
      <input
        ref={props.innerRef ?? null}
        id={props.id ?? props.name}
        disabled={disabled}
        type={props.type ?? 'text'}
        className={inputClass.join(' ')}
        placeholder={props.placeholder ?? ''}
        name={props.name}
        defaultValue={props.value}
        onChange={props.changeValue}
        onBlur={(changes) => {
          if (props.onBlur) {
            props.onBlur(changes)
          }
        }}
      />
    )
  }

  if (props.readOnly) {
    return <span className={`${inputBold ? 'fw-bold-custom' : ''} fs-6`}>{props.value ?? '-'}</span>
  }

  return (
    <>
      {props.groupAfterText ? (
        <div className='input-group mb-3'>
          {getInput()}
          <span className='input-group-text' id='basic-addon2'>
            {props.groupAfterText}
          </span>
        </div>
      ) : (
        <>
          {props.simpleTextAfter ? (
            <div className='d-inline-flex align-items-center '>
              {getInput()} <span className='ps-2'>{props.simpleTextAfter}</span>
            </div>
          ) : (
            getInput()
          )}
        </>
      )}

      {props.formik && props.formik.touched[props.name] && props.formik.errors[props.name] && (
        <div className='fv-plugins-message-container invalid-feedback'>
          <div className='fv-help-block'> {props.formik.errors[props.name]}</div>
        </div>
      )}
      {props.description ? <div className={'form-text'}>{props.description}</div> : null}
    </>
  )
})

export default InputSimple
