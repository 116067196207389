import {useState, useEffect, useCallback} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {debounce} from 'lodash'

export const useTableFilters = (initialFilters = {}, initialShowFilter = false) => {
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [filters, setFilters] = useState(
    params.get('filters') ? JSON.parse(params.get('filters') ?? '') : initialFilters
  )
  const [showFilter, setShowFilter] = useState(
    params.get('filter') === 'true' ? true : initialShowFilter
  )

  const updateURL = useCallback(
    (prevState, pomShowFilter) => {
      const filtersString = JSON.stringify(prevState)
      const currentFilters = params.get('filters')
      const currentShowFilter = params.get('filter') === 'true'

      if (filtersString === currentFilters && pomShowFilter === currentShowFilter) {
        return // Žádná změna, nedělej nic
      }

      if (Object.keys(prevState).length === 0) {
        params.delete('filters')
      } else {
        params.set('filters', filtersString)
      }

      params.set('filter', pomShowFilter.toString())
      // history.push({search: params.toString()})
    },
    [history, params]
  )

  const changeFilter = useCallback(
    debounce((value, id) => {
      setFilters((prevFilters: any) => {
        const updatedFilters = {...prevFilters}
        if (value === '') {
          delete updatedFilters[id]
        } else {
          updatedFilters[id] = value
        }
        updateURL(updatedFilters, showFilter)
        return updatedFilters
      })
    }, 300),
    [updateURL, showFilter]
  )

  const clearFilters = useCallback(() => {
    setFilters({})
    updateURL({}, false)
  }, [updateURL])

  const toggleShowFilter = useCallback(
    (e) => {
      setShowFilter(e)
      if (!e) {
        clearFilters()
      } else {
        setFilters((prevFilters: any) => {
          updateURL(prevFilters, e)
          return prevFilters
        })
      }
    },
    [clearFilters, updateURL]
  )

  return {filters, changeFilter, clearFilters, showFilter, toggleShowFilter}
}
