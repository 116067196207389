import {getTranslation, handleFocus} from '../Default'

export const checkFormErrors = (
  errors: any,
  setErrors: any,
  setSubmitting: any,
  formik: any = null
) => {
  if (Object.keys(errors).length > 0) {
    setSubmitting(false)
    setErrors(errors)
    return true
  }
  return false
}

export const checkValueError = (
  valueId: string,
  condition: boolean,
  isFocused: boolean,
  errorMessage: string,
  errors: any,
  intl: any
) => {
  if (condition) {
    // @ts-ignore
    errors[valueId] = getTranslation(errorMessage, intl)
    if (!isFocused) {
      handleFocus(valueId)
      isFocused = true
    }
  }
  return isFocused
}
