import {
  DEFAULT_LUXOR_DATE_FORMAT,
  DEFAULT_LUXOR_DATE_TIME_FORMAT,
  DEFAULT_VISIBILITY_OPTIONS,
  SEX_TYPES,
} from '../../Constants/Defatul'
import React from 'react'
import _ from 'lodash'
import SvjStore from '../../Stores/Svj'
import {toJS} from 'mobx'
const {DateTime} = require('luxon')

export function getRandomKey() {
  return Math.random().toString(36).substring(7)
}

export function renderFormattedDate(date: any, withTime: boolean = false, customFormat?: '') {
  if (withTime) {
    return DateTime.fromISO(date).toFormat(DEFAULT_LUXOR_DATE_TIME_FORMAT)
  }
  return DateTime.fromISO(date).toFormat(DEFAULT_LUXOR_DATE_FORMAT)
}

export function getTranslation(id: string, intl: any, values?: any) {
  if (!id) {
    return '-'
  }
  return intl.formatMessage({id: id, values: values}, {values: values})
}

export function getTranslationMoreValues(id: string, intl: any, values?: any) {
  if (!id) {
    return '-'
  }
  return intl.formatMessage({id: id, ...values}, {...values})
}

export function getDefaultVisibilityOptions(
  intl: any,
  listVisibility: any = DEFAULT_VISIBILITY_OPTIONS
) {
  const options: any = []

  for (let i in listVisibility) {
    const option = listVisibility[i]
    options.push({value: option['value'], label: getTranslation(option['label'], intl)})
  }

  return options
}

export function convertToOptions(intl: any, listVisibility: any) {
  const options: any = []

  for (let i in listVisibility) {
    const option = listVisibility[i]
    options.push({value: option['value'], label: getTranslation(option['label'], intl)})
  }

  return options
}

export function getDefaultSexOptions(intl: any) {
  const options: any = []

  for (let i in SEX_TYPES) {
    const option = SEX_TYPES[i]
    options.push({value: option['value'], label: getTranslation(option['label'], intl)})
  }

  return options
}

export function splitNameByExtend(filename: string) {
  let split_name = filename.split('.')
  let extend = split_name[split_name.length - 1]
  split_name[split_name.length - 1] = ''
  let edit_name = split_name.join('')

  return {
    name: edit_name,
    extend: extend,
  }
}

export function createFormData(data: any) {
  const formData = new FormData()
  for (let i in data) {
    formData.append(i, data[i])
  }
  return formData
}

export function isValidEmail(val: string) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regEmail.test(val)
}

export function getEmptyFileText(intl: any) {
  return (
    <p className='text-center text-muted h3 py-5'>
      {getTranslation('FILE_MANAGER.NO_FILES', intl)}
    </p>
  )
}

export const handleFocus = (fieldName: string, searchBy = 'name') => {
  let field: any =
    searchBy === 'name'
      ? document.querySelector(`[name="${fieldName}"]`)
      : document.querySelector(fieldName)

  if (field && field.type === 'hidden') {
    let containerRegex = /-container$/
    while (field) {
      if (Array.from(field.classList).some((className: any) => containerRegex.test(className))) {
        break
      }
      field = field.parentElement // Pokračování ve vyhledávání u rodičovského prvku
    }
    if (field) {
      // @ts-ignore
      const elementRect = field.getBoundingClientRect()
      const absoluteElementTop = elementRect.top + window.pageYOffset
      const middle = absoluteElementTop - window.innerHeight / 2
      window.scrollTo({
        top: middle,
        behavior: 'smooth',
      })
    }
  }

  if (field) {
    // @ts-ignore
    field.focus()
  }
}

export const scrollToElementById = (id: string) => {
  let field: any = document.getElementById(id)

  if (field) {
    // @ts-ignore
    const elementRect = field.getBoundingClientRect()
    const absoluteElementTop = elementRect.top + window.pageYOffset
    const middle = absoluteElementTop - window.innerHeight / 2
    window.scrollTo({
      top: middle,
      behavior: 'smooth',
    })
  }
}

export const isReadOnlyMode = (ignore = false) => {
  if (ignore) {
    return true
  }
  return (
    window.location.href.indexOf('/upravit') === -1 && window.location.href.indexOf('/novy') === -1
  )
}

export const isNewMode = () => {
  return window.location.href.indexOf('/novy') !== -1
}

export const setEditMode = (
  e: any,
  baseModuleLink: string,
  history: any,
  initialValues: any,
  setInitialValues: any
) => {
  e.preventDefault()
  let initialValuesData = initialValues
  history.push(
    `/${baseModuleLink}/${initialValues.id}${!initialValues.readOnly ? '' : '/upravit'}${
      initialValues.returnBackParams ? initialValues.returnBackParams : ''
    }`
  )
  initialValuesData.readOnly = !initialValuesData.readOnly
  setInitialValues(initialValuesData)
  return false
}

export const isDate = (value: any) => {
  return value instanceof Date
}

export const setTitlePage = (title: string) => {
  // if (title) {
  //   document.title = 'SVJ Aplikace | ' + title
  // } else {
  // }
  document.title = 'SVJ Aplikace'
}

export const formatPhoneNumber = (phoneNumber: any) => {
  if (phoneNumber === '-') {
    return '-'
  }
  if (!phoneNumber) {
    return ''
  }
  // Odstranění všech nečíselných znaků (kromě čísel)
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '')

  // Pokud je telefonní číslo prázdné, vrátíme prázdný řetězec
  if (cleanedPhoneNumber === '') {
    return ''
  }

  if (cleanedPhoneNumber.length === 9) {
    return `+420 ${cleanedPhoneNumber.slice(0, 3)} ${cleanedPhoneNumber.slice(
      3,
      6
    )} ${cleanedPhoneNumber.slice(6)}`
  }

  if (cleanedPhoneNumber.length === 12 && cleanedPhoneNumber.startsWith('420')) {
    return `+${cleanedPhoneNumber.slice(0, 3)} ${cleanedPhoneNumber.slice(
      3,
      6
    )} ${cleanedPhoneNumber.slice(6, 9)} ${cleanedPhoneNumber.slice(9)}`
  }

  // Rozdělení čísel na skupiny (např. +1 (123) 456-7890 -> +1, 123, 456, 7890)
  const matches = cleanedPhoneNumber.match(/^(\+\d{1,2})?(\d{3})(\d{3})(\d{4})$/)

  if (matches) {
    // Sestavení formátovaného čísla (např. +1 123-456-7890)
    const formattedPhoneNumber = `${matches[1] || ''} ${matches[2]}-${matches[3]}-${matches[4]}`
    return formattedPhoneNumber
  } else {
    // Pokud číslo neodpovídá očekávanému vzoru, vrátíme původní nezpracované číslo
    return phoneNumber
  }
}

export const removeHtmlTags = (input: string) => {
  if (!input) {
    return ''
  }
  return input.replace(/<[^>]*>/g, '')
}

export const getBaseTab = (
  intl: any,
  count: any,
  icon: string = 'la la-hourglass-start',
  title = 'BASE.TABLE.FILTER.ALL'
) => {
  return [
    {
      id: 'all',
      title: getTranslation(title, intl),
      icon: icon,
      visible: true,
      count: count,
    },
  ]
}

export const downloadFile = (url: string, filename: string) => {
  const a = document.createElement('a')
  a.href = url
  a.download = filename

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const findDifferences = (files: any[], oldFiles: any[]) => {
  let addedFiles = _.differenceBy(files, oldFiles, 'id') || []
  let removedFiles = _.differenceBy(oldFiles, files, 'id') || []

  return {addedFiles, removedFiles}
}

export function getTranslationOptions(intl: any, list: any) {
  const options: any = []

  for (let i in list) {
    const option = list[i]
    options.push({value: option['value'], label: getTranslation(option['label'], intl)})
  }

  return options
}

export function shortenText(text: string, size = 30, method: 'words' | 'characters' = 'words') {
  if (method === 'words') {
    const words = text.split(' ')
    if (words.length > size) {
      return words.slice(0, size).join(' ') + '...'
    } else {
      return text
    }
  } else if (method === 'characters') {
    if (text.length > size) {
      return text.slice(0, size) + '...'
    } else {
      return text
    }
  }
}

export function base64ToBlob(base64: any, mimeType: any) {
  const byteCharacters = atob(base64)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  return new Blob([byteArray], {type: mimeType})
}

export function downloadBlobPdf(data: any, name: string) {
  const pdfBlob = base64ToBlob(data, 'application/pdf')
  const downloadUrl = window.URL.createObjectURL(pdfBlob)
  const a = document.createElement('a')
  a.href = downloadUrl
  a.download = name
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(downloadUrl)
  a.remove()
}

export function hasModule(module: string) {
  return SvjStore.modules.find((item: any) => item.id === module) !== undefined
}

export const CustomStepIcon = (props: any) => {
  const {completed} = props
  if (completed) {
    return (
      <div>
        <i className='bi bi-check text-white fs-3'></i>
      </div>
    )
  } else {
    return <div className='fs-5 text-white'>{props.icon}</div>
  }
}
