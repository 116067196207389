import React from 'react'
import {observer} from 'mobx-react'
import CalendarForm from '../../../Pages/Calendar/Form'
import {ModalBody} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {getTranslation} from '../../../Helpers/Default'
import {DefaultButton} from '../../Buttons/DefaultButton'
import EventStore from '../../../Stores/Event'
import AcceptReadStore from '../../../Stores/AcceptRead'

const EventAcceptRead = observer((props: any) => {
  const intl = useIntl()

  return (
    <>
      <ModalBody>
        <CalendarForm eventData={props.data} loadData={true} />
        <div className={`mx-auto w-100 pb-10 fv-plugins-bootstrap5 fv-plugins-framework mw-700px`}>
          <div className={'w-100 border-top pt-5'}>
            <div className='d-inline-flex justify-content-between align-content-center w-100'>
              <div className='left'>
                <DefaultButton
                  className={'btn btn-light btn-light-danger btn-sm'}
                  onClick={props.skip}
                  id={'event_postpone'}
                  loading={EventStore.isCreating}
                  disable={EventStore.isCreating}
                >
                  {getTranslation('ACCEPT_READ.POSTPONE', intl)}
                </DefaultButton>
              </div>
              <div className='right'>
                <DefaultButton
                  className={'btn btn-success btn-sm'}
                  onClick={async (e: any) => {
                    e.preventDefault()
                    if (await AcceptReadStore.accept(props.id)) {
                      props.skip()
                    }
                  }}
                  id={'event_accept'}
                  loading={AcceptReadStore.isAccepting}
                  disable={AcceptReadStore.isAccepting}
                >
                  {getTranslation('ACCEPT_READ.ACCEPT', intl)}
                </DefaultButton>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </>
  )
})
export default EventAcceptRead
