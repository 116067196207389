import React, {FC} from 'react'

const CardLayout: FC<{
  header?: any
  header_class?: string
  footer?: any
  card_class?: string
  card_body_class?: string
}> = (props) => {
  return (
    <>
      <div className={`card card-custom ${props.card_class ?? ''}`}>
        {props.header ? (
          <div className={`card-header ${props.header_class ?? ''}`}>{props.header}</div>
        ) : null}
        <div className={`card-body ${props.card_body_class ?? ''}`}>{props.children}</div>
        {props.footer ? <div className='card-footer'>{props.footer}</div> : null}
      </div>
    </>
  )
}

export default CardLayout
