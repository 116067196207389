import {DefaultBadge} from '../../Components/Badge'
import {VOTE_STATUS_MAP} from '../../Constants/Vote'
import Translation from '../../Components/Translation'
import {getTranslation} from '../Default'

export const getLabelStatus = (status: string) => {
  // @ts-ignore
  let text = VOTE_STATUS_MAP[status]
  let color = 'primary'
  switch (status) {
    case 'concept':
      color = 'warning'
      break
    case 'run':
      color = 'success'
      break
    case 'finish':
      color = 'dark'
      break
    case 'closed':
      color = 'danger'
      break
  }
  return (
    <DefaultBadge type={status === 'finish' ? 'light' : ''} color={color}>
      {<Translation text={text.label} />}
    </DefaultBadge>
  )
}

export const getVoteVisibilityOptions = (intl: any) => {
  return [
    {
      value: 'true',
      label: getTranslation('VOTE.FORM.VISIBILITY.PUBLIC', intl),
    },
    {
      value: 'false',
      label: getTranslation('VOTE.FORM.VISIBILITY.PRIVATE', intl),
    },
  ]
}
