import {LinkButton} from '../LinkButton'
import {getTranslation} from '../../Helpers/Default'
import {DefaultButton} from '../Buttons/DefaultButton'
import * as React from 'react'

import {FC} from 'react'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'

const FormButtonFooter: FC<{
  id: any
  readOnly?: boolean
  linkBack: string
  textLinkBack: string
  changeToEdit: any
  editButtonText: string
  changeButtonText: string
  createButtonText: string
  loader: boolean
  conceptButton?: boolean
  changeToConcept?: any
  submit: any
  deleteText?: string
  deleteAction?: any
  leftButtons?: any
  showEditButton?: boolean
  changeReadOnly: any
  extraStartRightButtons?: any
  showCRUDButtons?: boolean
}> = observer((props) => {
  const intl = useIntl()
  const showEditButton = props.showEditButton !== null ? props.showEditButton : true
  let showCRUDButtons = props.showCRUDButtons !== null ? props.showCRUDButtons : false
  const renderLeftButtons = () => {
    if (props.leftButtons) {
      return props.leftButtons.map((button: any, index: number) => {
        if (button.visible !== undefined && !button.visible) {
          return null
        }
        return (
          <DefaultButton
            color={button.color}
            key={`extra_left_button_${index}`}
            size={'sm'}
            className={button.className}
            loading={props.loader}
            disable={props.loader}
            onClick={button.action}
          >
            {getTranslation(button.text, intl)}
          </DefaultButton>
        )
      })
    }
  }

  return (
    <div className={'w-100 border-top pt-5'}>
      <div className='d-inline-flex justify-content-between align-content-center w-100 page-buttons-area'>
        <div className='left'>
          {props.readOnly ? (
            <LinkButton
              color={'danger'}
              link={props.linkBack}
              text={getTranslation(props.textLinkBack, intl)}
            />
          ) : (
            <DefaultButton
              className={'btn btn-danger btn-sm me-3'}
              id={'cancel_form'}
              loading={false}
              disable={false}
              onClick={props.changeReadOnly}
            >
              {getTranslation(props.textLinkBack, intl)}
            </DefaultButton>
          )}
          {renderLeftButtons()}
          {props.id &&
          props.deleteAction &&
          props.deleteText &&
          showCRUDButtons &&
          props.readOnly ? (
            <DefaultButton
              className={'btn btn-light-danger btn-sm ms-5'}
              type={'button'}
              id={'delete_form_submit'}
              loading={props.loader}
              disable={props.loader}
              onClick={props.deleteAction}
            >
              {getTranslation(props.deleteText, intl)}
            </DefaultButton>
          ) : null}
        </div>
        <div className='right'>
          {props.extraStartRightButtons ? props.extraStartRightButtons : null}
          {props.conceptButton && showCRUDButtons ? (
            <DefaultButton
              className={'btn btn-warning btn-sm me-3'}
              type={'submit'}
              id={'messages_form_submit'}
              loading={props.loader}
              disable={props.loader}
              onClick={props.changeToConcept}
            >
              {getTranslation('BASE.BUTTONS.CONCEPT', intl)}
            </DefaultButton>
          ) : null}
          {showCRUDButtons ? (
            <>
              {props.readOnly ? (
                <>
                  {showEditButton ? (
                    <button
                      className={'btn btn-warning btn-sm'}
                      type={'button'}
                      onClick={props.changeToEdit}
                      id={'messages_form_submit'}
                    >
                      {getTranslation(props.editButtonText, intl)}
                    </button>
                  ) : null}
                </>
              ) : (
                <DefaultButton
                  className={'btn btn-success btn-sm'}
                  onClick={props.submit}
                  id={'messages_form_submit'}
                  loading={props.loader}
                  disable={props.loader}
                >
                  {props.id
                    ? getTranslation(props.changeButtonText, intl)
                    : getTranslation(props.createButtonText, intl)}
                </DefaultButton>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
})

export default FormButtonFooter
