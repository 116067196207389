export const SELECT_DEFAULT_STYLE = {
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  container: (provided: any) => ({
    ...provided,
    fontSize: '0 !important',
    borderRadius: '0 !important',
    padding: '0 !important',
    backgroundImage: 'none !important',
    border: 'none !important',
  }),
  input: (provided: any) => ({
    ...provided,
    paddingTop: '0.825rem',
    paddingBottom: '0.825rem',
    paddingLeft: '1.5rem',
    borderRadius: '0.475rem',
    backgroundColor: '#F5F8FA',
    fontSize: '1.15rem !important',
    color: '#5E6278 !important',
    fontWeight: '500',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: '1.15rem !important',
    color: '#5E6278 !important',
    fontWeight: '500',
    marginLeft: '10px',
    zIndex: '98 !important',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    padding: '0.5em 0.85em',
    fontSize: '0.85rem',
    fontWeight: '600',
    lineHeight: '1',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    borderRadius: '0.475rem',
    color: '#009EF7',
    backgroundColor: '#F1FAFF',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: '9999 !important',
    fontSize: '1.15rem !important',
    color: '#5E6278 !important',
    fontWeight: '500',
    padding: '0',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: '#009EF7',
  }),
  control: (provided: any) => ({
    ...provided,
    border: '1px solid #f6f8fa',
    borderRadius: '0.475rem',
    boxShadow: 'none',
    height: '45.88px',
    backgroundColor: '#F5F8FA',
    ':hover': {
      border: '1px solid #eef3f7',
      boxShadow: 'none',
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    color: 'hsl(0, 0%, 66%)',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0 10px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '1.15rem',
    paddingLeft: '1.5rem',
    fontWeight: '500',
    lineHeight: '1.5',
    color: '#a1a5b7',
  }),
  groupHeading: (base: any) => ({
    ...base,
    flex: '1 1',
    color: '#a1a5b7',
    margin: 0,
    padding: '1rem 0.75rem',
    background: '#f6f8fa',
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: '0',
    margin: '0',
  }),
}
