import {observer} from 'mobx-react'
import {Viewer, Worker} from '@react-pdf-viewer/core'
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/thumbnail/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import * as React from 'react'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import FileStore from '../../../Stores/File'
import {getRandomKey} from '../../../Helpers/Default'

const PDFViewV2 = observer((props: any) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const [pdfFile, setPdfFile] = useState()

  const getFile = async () => {
    if (props.file) {
      const file = await FileStore.get(props.file.id)
      if (file) {
        setPdfFile(file)
      }
    }
  }
  useEffect(() => {
    getFile()
  }, [])
  if (!props.file) {
    return null
  }
  const onError = (e: any) => {
    console.log(e)
    return (
      <div>
        Bohužel se nepodařilo pdf načíst
        {/*<img className='file-icon pr-3 w-125' src={'/media/files/pdf.svg'} alt='' />*/}
      </div>
    )
  }

  const RenderPage = (props: any) => (
    <div style={{width: '200px'}}>
      {props.canvasLayer.children}
      {props.annotationLayer.children}
      {props.textLayer.children}
    </div>
  )

  const intl = useIntl()

  if (!pdfFile) {
    return <div className={'text-center'}> Načítání</div>
  }

  if (
    props.file.attachment.type.indexOf('png') > -1 ||
    props.file.attachment.type.indexOf('jpg') > -1 ||
    props.file.attachment.type.indexOf('jpeg') > -1 ||
    props.file.attachment.type.indexOf('gif') > -1
  ) {
    return <img src={pdfFile} alt='Document' style={{width: '100%', height: '100%'}} />
  }

  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
      <div
        style={{
          height: '750px',
          width: '900px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Viewer
          // style={{height: '400px !important'}}
          plugins={[defaultLayoutPluginInstance]}
          renderPage={RenderPage}
          renderError={onError}
          fileUrl={pdfFile}
          renderLoader={(percentages) => <div>Načítání</div>}
        />
      </div>
    </Worker>
  )
})

export default PDFViewV2
