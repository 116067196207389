import * as React from 'react'
import {FC, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {
  findDifferences,
  getRandomKey,
  getTranslation,
  hasModule,
  isNewMode,
  isReadOnlyMode,
} from '../../../Helpers/Default'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import CardLayout from '../../../Components/CardLayout'
import InputInline from '../../../Components/InputInline'
import TextEditor from '../../../Components/TextEditor'
import SwitchColumn from '../../../Components/SwitchColumn'
import FileManager from '../../../Components/FileManager'
import SectionTitle from '../../../Components/SectionTitle'
import DateTimePickerDefault from '../../../Components/DateTimePicker'
import {DEFAULT_DATE_FORMAT, DEFAULT_DB_DATE_FORMAT} from '../../../Constants/Defatul'
import moment from 'moment'
import {cloneDeep, isEmpty} from 'lodash'
import {LinkButton} from '../../../Components/LinkButton'
import {DefaultButton} from '../../../Components/Buttons/DefaultButton'
import {useHistory, useLocation} from 'react-router-dom'
import MessageStore from '../../../Stores/Messages'
import {confirmDialog} from '../../../Helpers/ConfirmDialog'
import SelectInline from '../../../Components/SelectInline'
import {getLabelTranslation} from '../../../Helpers/Messages'
import {DefaultBadge} from '../../../Components/Badge'
import {toJS} from 'mobx'
import {isValid} from 'date-fns'
import {checkFormErrors, checkValueError} from '../../../Helpers/Form'
import Owner from '../../../Components/Owner'
import SwitchInline from '../../../Components/SwitchInline'
import {hasCrudRole} from '../../../Helpers/User'
import {ACCEPT_READ_MODULE, MESSAGES_MODULE} from '../../../Constants/Modules'
import useCheckCrudRole from '../../../Hooks/CheckCrudRole'
import DefaultTableMui from '../../../Components/DefaultTableMui'
import {acceptReadsColumns} from '../../../Helpers/AcceptRead'
import {isFreeSVJ} from '../../../Helpers/Svj'

const MessageForm: FC<{messageData?: any; loadData?: any; match?: any}> = observer((props: any) => {
  const intl = useIntl()
  let id: any = null
  if (props.match && props.match.params && props.match.params.id) {
    id = props.match.params.id
  }
  if (props.messageData) {
    id = props.messageData.id
  }
  const [labels, setLabels] = useState<any>([])

  const initDataStructure = {
    id: null,
    title: '',
    description: '',
    label: MessageStore.defaultLabel
      ? {
          // @ts-ignore
          label: getTranslation(getLabelTranslation('important'), intl),
          // @ts-ignore
          value: MessageStore.defaultLabel.id,
        }
      : {value: null, label: ''},
    selectedLabel: toJS(MessageStore.defaultLabel) as any,
    files: [],
    long_term: true,
    dateFrom: new Date(),
    disabledDate: '',
    archive: false,
    dateTo: '',
    dateToFormat: '',
    isPinned: false,
    concept: false,
    readOnly: isReadOnlyMode(props.loadData),
    isNew: props.loadData ? false : isNewMode(),
    owner: null,
    toPublic: false,
    addedFiles: [],
    removedFiles: [],
    addedAttachments: [],
    removedAttachments: [],
    attachments: [],
    acceptRead: false,
    allAccepts: [],
    key: getRandomKey(),
  }

  const history = useHistory()
  const location = useLocation()
  const [backup, setBackup] = useState<any>(cloneDeep(initDataStructure))
  const [initialValues, setInitialValues] = useState(initDataStructure)

  const [files, setFiles] = useState<any>([])
  const [attachments, setAttachments] = useState<any>([])
  let returnBackParams = ''
  // @ts-ignore
  if (location?.state?.search) {
    // @ts-ignore
    returnBackParams = location.state.search
  }

  if (!props.messageData) {
    useCheckCrudRole(MESSAGES_MODULE, `/zpravy${returnBackParams}`)
  }

  const getData = async () => {
    await MessageStore.getLabels()
    const data = await MessageStore.get(id)

    setLabels(
      toJS(MessageStore.messageLabels).map((item: any) => ({
        value: item.id,
        label: item.name,
        type: item.type,
      })) || []
    )
    if (data) {
      const a: any = MessageStore.messageLabels.find((item: any) => item.id === data.labelId)
      data['dateFrom'] = data['dateFrom'] ? new Date(data['dateFrom']) : null
      data['dateTo'] = data['dateTo']
        ? moment(data['dateTo'], DEFAULT_DB_DATE_FORMAT).format(DEFAULT_DB_DATE_FORMAT)
        : null
      data['dateToFormat'] = moment(data['dateTo']).format(DEFAULT_DB_DATE_FORMAT)
      data['selectedLabel'] = toJS(data.label)
      data['label'] = {
        // @ts-ignore
        label: a.name,
        // @ts-ignore
        value: data.label.id,
      }
      data['long_term'] = !data['long_term']
      data['key'] = getRandomKey()
      if (data.files) {
        setFiles(data.files)
      }
      if (data.attachments) {
        setAttachments(data.attachments)
      }

      const newData = {...initialValues, ...cloneDeep(data)}
      setBackup(newData)
      setInitialValues({...newData})
    } else {
      history.push(`/zpravy${returnBackParams}`)
    }
  }

  const getLabels = async () => {
    await MessageStore.getLabels()
    setLabels(
      toJS(MessageStore.messageLabels).map((item: any) => ({
        value: item.id,
        label: item.name,
        type: item.type,
      })) || []
    )
    const a: any = MessageStore.messageLabels.find((item: any) => item.type === 'important')
    setInitialValues((prevState) => ({
      ...prevState,
      label: a ? {value: a.id, label: a.name, type: a.type} : {value: null, label: '', type: ''},
      selectedLabel: a
        ? {value: a.id, label: a.name, type: a.type}
        : {value: null, label: '', type: ''},
    }))
  }

  useEffect(() => {
    if (id && !initialValues.isNew) {
      getData()
    } else {
      getLabels()
    }
  }, [])

  useEffect(() => {
    setInitialValues((prevState) => ({...prevState, key: getRandomKey()}))
  }, [attachments])

  const changeValue = (e: any) => {
    let data = initialValues //cloneDeep(initialValues)
    let name: string = e.target.name
    // @ts-ignore
    data[name] = e.target.value
    if (name === 'label') {
      // @ts-ignore
      data['selectedLabel'] = toJS(MessageStore.messageLabels).find(
        (item: any) => item.id === e.target.value.value
      )
    }
    if (name === 'dateTo') {
      data['dateToFormat'] = moment(e.target.value).format(DEFAULT_DB_DATE_FORMAT)
      data['dateTo'] = moment(e.target.value).format(DEFAULT_DB_DATE_FORMAT)
    }
    setInitialValues((prevState) => ({...prevState, ...data}))
  }

  const handleDeleteMessage = async () => {
    confirmDialog(
      '',
      getTranslation(
        getTranslation(
          initialValues.acceptRead
            ? 'MESSAGES.TABLE.CONFIRM.ACCEPT_READ.DELETE'
            : 'MESSAGES.TABLE.CONFIRM.DELETE',
          intl
        ),
        intl
      ),
      async () => {
        // @ts-ignore
        if (await MessageStore.remove(initialValues.id)) {
          history.push(`/zpravy${returnBackParams}`)
        }
      }
    )
  }

  const handleArchiveMessage = async () => {
    confirmDialog('', getTranslation('MESSAGES.TABLE.CONFIRM.ARCHIVE', intl), async () => {
      // @ts-ignore
      if (await MessageStore.archive(initialValues.id)) {
        history.push(`/zpravy${returnBackParams}`)
      }
    })
  }

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values, {setSubmitting, setErrors}) => {
      let errors = {}
      let isFocused = false
      if (initialValues.concept && !initialValues.toPublic) {
        if (initialValues.id) {
          confirmDialog(
            '',
            getTranslation('MESSAGES.CONFIRM.QUESTION.UPDATE_CONCEPT', intl),
            update
          )
        } else {
          confirmDialog(
            '',
            getTranslation('MESSAGES.CONFIRM.QUESTION.CREATE_CONCEPT', intl),
            create
          )
        }
      } else {
        isFocused = checkValueError(
          'title',
          isEmpty(initialValues.title),
          isFocused,
          'MESSAGES.FORM.VALIDATION.TITLE',
          errors,
          intl
        )

        const date = initialValues.dateFrom ? new Date(initialValues.dateFrom) : null // 1. května 2023

        if (initialValues.long_term) {
          isFocused = checkValueError(
            'dateFrom',
            !isValid(date) || initialValues.dateFrom === null,
            isFocused,
            'MESSAGES.FORM.VALIDATION.START_DATE',
            errors,
            intl
          )
        }

        isFocused = checkValueError(
          'description',
          isEmpty(initialValues.description),
          isFocused,
          'MESSAGES.FORM.VALIDATION.DESCRIPTION',
          errors,
          intl
        )

        if (!checkFormErrors(errors, setErrors, setSubmitting)) {
          if (initialValues.id) {
            if (!initialValues.concept) {
              confirmDialog('', getTranslation('MESSAGES.CONFIRM.QUESTION.UPDATE', intl), update)
            }
          } else {
            confirmDialog(
              '',
              getTranslation(
                moment(initialValues.dateFrom) > moment(new Date())
                  ? 'MESSAGES.CONFIRM.QUESTION.CREATE_AND_PUBLISH_FUTURE'
                  : 'MESSAGES.CONFIRM.QUESTION.CREATE_AND_PUBLISH',
                intl
              ),
              create
            )
          }
        }
      }
    },
  })

  const prepareData = () => {
    const data = {...cloneDeep(initialValues)}
    data['long_term'] = !data['long_term']
    data['label'] = data['label'].value
    data['files'] = files.map((item: any) => item.id)
    const listAttachments = findDifferences(attachments, initialValues.attachments)
    // @ts-ignore
    data['addedAttachments'] = listAttachments.addedFiles || []
    // @ts-ignore
    data['removedAttachments'] = listAttachments.removedFiles.map((item: any) => item.id) || []
    // @ts-ignore
    data['dateFrom'] = data['dateFrom']
      ? moment(data['dateFrom']).format(DEFAULT_DB_DATE_FORMAT)
      : null
    // @ts-ignore
    data['dateTo'] = data['dateTo'] ? moment(data['dateTo']).format(DEFAULT_DB_DATE_FORMAT) : null
    return data
  }

  const update = async () => {
    const data = prepareData()

    if (await MessageStore.update(data, data.addedAttachments)) {
      // history.push(`/zpravy/${initialValues.id}`)
      // setInitialValues({...initialValues, readOnly: true})
      await getData()
      setInitialValues((prevState) => ({...prevState, readOnly: true, key: getRandomKey()}))
    }
  }

  const create = async () => {
    const data = prepareData()
    if (await MessageStore.create(data, attachments)) {
      history.push(`/zpravy${returnBackParams}`)
    }
  }

  const renderRightButtons = () => {
    if (initialValues.isNew && hasCrudRole(MESSAGES_MODULE)) {
      return (
        <>
          <DefaultButton
            className={'btn btn-warning btn-sm me-2'}
            type={'submit'}
            color={'warning'}
            onClick={(e: any) => {
              changeValue({target: {name: 'concept', value: true}})
            }}
            id={'messages_form_submit_concept'}
            loading={MessageStore.isCreating}
            disable={MessageStore.isCreating}
          >
            {getTranslation('MESSAGES.FORM.BUTTONS.SAVE_CONCEPT', intl)}
          </DefaultButton>
          <DefaultButton
            className={'btn btn-success btn-sm'}
            onClick={(e: any) => {
              changeValue({target: {name: 'toPublic', value: true}})
            }}
            type={'submit'}
            id={'messages_form_submit'}
            loading={MessageStore.isCreating}
            disable={MessageStore.isCreating}
          >
            {getTranslation('MESSAGES.FORM.BUTTONS.CREATE_AND_PUBLIC', intl)}
          </DefaultButton>
        </>
      )
    } else {
      if (initialValues.readOnly && hasCrudRole(MESSAGES_MODULE)) {
        return (
          <>
            <button
              className={'btn btn-warning btn-sm'}
              type={'button'}
              onClick={(e) => {
                setInitialValues({...initialValues, readOnly: false})
                // setEditMode(e, 'zpravy', history, initialValues, setInitialValues)
              }}
              id={'messages_form_submit'}
            >
              {getTranslation('MESSAGES.FORM.BUTTONS.EDIT', intl)}
            </button>
          </>
        )
      } else {
        if (backup.concept && hasCrudRole(MESSAGES_MODULE)) {
          return (
            <>
              <DefaultButton
                className={'btn btn-warning btn-sm me-2'}
                type={'submit'}
                color={'warning'}
                onClick={(e: any) => {
                  changeValue({target: {name: 'concept', value: true}})
                }}
                id={'messages_form_submit_concept'}
                loading={MessageStore.isCreating}
                disable={MessageStore.isCreating}
              >
                {getTranslation('MESSAGES.FORM.BUTTONS.SAVE_CHANGES_CONCEPT', intl)}
              </DefaultButton>
              <DefaultButton
                className={'btn btn-success btn-sm'}
                // type={'submit'}
                onClick={(e: any) => {
                  changeValue({target: {name: 'concept', value: false}})
                  changeValue({target: {name: 'toPublic', value: true}})
                  const a = document.getElementById('message_form')
                  if (a) {
                    const event = new Event('submit', {
                      bubbles: true, // Událost se bude šířit nahoru po DOM
                      cancelable: true, // Událost může být zrušena
                    })
                    a.dispatchEvent(event)
                  }
                }}
                id={'messages_form_submit'}
                loading={MessageStore.isCreating}
                disable={MessageStore.isCreating}
              >
                {getTranslation('MESSAGES.FORM.BUTTONS.SAVE_AND_PUBLIC', intl)}
              </DefaultButton>
            </>
          )
        } else if (hasCrudRole(MESSAGES_MODULE)) {
          return (
            <>
              <DefaultButton
                className={'btn btn-warning btn-sm me-2'}
                type={'submit'}
                color={'warning'}
                onClick={(e: any) => {
                  changeValue({target: {name: 'toPublic', value: true}})
                }}
                id={'messages_form_submit_concept'}
                loading={MessageStore.isCreating}
                disable={MessageStore.isCreating}
              >
                {getTranslation('MESSAGES.FORM.BUTTONS.SAVE_CHANGES', intl)}
              </DefaultButton>
            </>
          )
        }
      }
    }
  }

  const renderLeftButtons = () => {
    if (initialValues.readOnly) {
      return (
        <>
          <LinkButton
            color={'danger'}
            link={`/zpravy${returnBackParams}`}
            text={getTranslation('MESSAGES.FORM.BUTTONS.BACK', intl)}
          />
          {!initialValues.archive &&
          !initialValues.isNew &&
          !initialValues.concept &&
          hasCrudRole(MESSAGES_MODULE) ? (
            <button
              className={'btn btn-light-info btn-sm ms-2'}
              type={'button'}
              onClick={async (e) => {
                await handleArchiveMessage()
                // setEditMode(e, 'zpravy', history, initialValues, setInitialValues)
              }}
              id={'messages_form_archive'}
            >
              {getTranslation('MESSAGES.TABLE.ACTION.ARCHIVE', intl)}
            </button>
          ) : null}
          {hasCrudRole(MESSAGES_MODULE) ? (
            <button
              className={'btn btn-light-danger btn-sm ms-2'}
              type={'button'}
              onClick={async (e) => {
                await handleDeleteMessage()
                // setEditMode(e, 'zpravy', history, initialValues, setInitialValues)
              }}
              id={'messages_form_delete'}
            >
              {getTranslation('MESSAGES.FORM.ACTION.DELETE', intl)}
            </button>
          ) : null}
        </>
      )
    } else {
      return (
        <>
          <button
            className={'btn btn-danger btn-sm'}
            type={'button'}
            onClick={(e) => {
              setInitialValues({...backup, readOnly: true})
              // setEditMode(e, 'zpravy', history, initialValues, setInitialValues)
            }}
            id={'messages_form_submit'}
          >
            {getTranslation('MESSAGES.FORM.BUTTONS.CANCEL', intl)}
          </button>
        </>
      )
    }
  }

  const mainButtons = []
  if (
    initialValues.id &&
    hasCrudRole(MESSAGES_MODULE) &&
    initialValues.readOnly &&
    !initialValues.isNew
  ) {
    mainButtons.push(
      <DefaultButton
        size={'sm'}
        color={'primary'}
        className={''}
        onClick={() => MessageStore.print(initialValues.id, initialValues.title.replace(' ', '_'))}
        key={getRandomKey()}
        loading={MessageStore.isGetting}
        tooltip={getTranslation('BASE.BUTTONS.PRINT', intl)}
        icon={<i className='bi bi-printer pe-0'></i>}
      ></DefaultButton>
    )
  }

  return (
    <div>
      {!props.loadData ? (
        <PageTitle pageButtons={[]} linkBack={`/zpravy${returnBackParams}`}>
          {getTranslation(
            initialValues.readOnly ? 'MESSAGES.FORM.TITLE_DETAIL' : 'MESSAGES.FORM.TITLE_CREATE',
            intl
          )}
        </PageTitle>
      ) : null}
      <CardLayout>
        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='message_form'>
          <div
            className={`mx-auto w-100 mw-600px  pb-10 fv-plugins-bootstrap5 fv-plugins-framework ${
              props.loadData ? '' : 'pt-15'
            }`}
          >
            <div className='d-flex flex-column mb-8 fv-row fv-plugins-icon-container'>
              <SectionTitle buttons={mainButtons}>
                {getTranslation('MESSAGES.FORM.TITLE.INFORMATION', intl)}
              </SectionTitle>
              {/*<div className='mb-5'>*/}
              {/*  <h2 className='fw-bold-custom text-dark border-bottom w-100'>*/}
              {/*    {getTranslation('MESSAGES.FORM.TITLE.INFORMATION', intl)}*/}
              {/*  </h2>*/}
              {/*</div>*/}
              <InputInline
                id={'title'}
                name={'title'}
                inputBold={true}
                value={initialValues.title}
                required={true}
                label={getTranslation('MESSAGES.FORM.TITLE', intl)}
                placeholder={getTranslation('MESSAGES.FORM.TITLE_PLACEHOLDER', intl)}
                formik={formik}
                changeValue={changeValue}
                readOnly={initialValues.readOnly}
              />

              <SelectInline
                key={getRandomKey()}
                values={initialValues}
                name={'label'}
                placeholder={getTranslation('MESSAGES.FORM.PLACEHOLDER.LABEL', intl)}
                label={getTranslation('MESSAGES.FORM.LABEL', intl)}
                formik={formik}
                changeValue={changeValue}
                data={labels}
                readOnly={initialValues.readOnly}
                readOnlyDescription={true}
                option={{
                  value: 'value',
                  name: function (value: any) {
                    return getTranslation(getLabelTranslation(value['type']), intl)
                  },
                }}
                description={
                  <>
                    {initialValues.selectedLabel ? (
                      <DefaultBadge
                        // @ts-ignore
                        color={initialValues.selectedLabel.type}
                      >
                        {getTranslation(
                          // @ts-ignore
                          getLabelTranslation(initialValues.selectedLabel.type),
                          intl
                        )}
                      </DefaultBadge>
                    ) : null}
                  </>
                }
              />

              {!isFreeSVJ() && hasModule(ACCEPT_READ_MODULE) ? (
                <SwitchInline
                  label={getTranslation('CALENDAR.FROM.ACCEPT_READ', intl)}
                  columnLabel={true}
                  name={'acceptRead'}
                  value={initialValues.acceptRead}
                  // label={
                  //   initialValues.readOnly ? getTranslation('MESSAGES.FORM.PIN_MESSAGE', intl) : null
                  // }
                  formik={formik}
                  changeValue={changeValue}
                  readOnly={initialValues.readOnly}
                  texts={{
                    select: getTranslation('BASE.YES', intl),
                    unselect: getTranslation('BASE.NO', intl),
                  }}
                />
              ) : null}

              <SwitchInline
                label={getTranslation('MESSAGES.FORM.PIN_MESSAGE', intl)}
                columnLabel={true}
                name={'isPinned'}
                value={initialValues.isPinned}
                // label={
                //   initialValues.readOnly ? getTranslation('MESSAGES.FORM.PIN_MESSAGE', intl) : null
                // }
                formik={formik}
                changeValue={changeValue}
                readOnly={initialValues.readOnly}
                texts={{
                  select: getTranslation('MESSAGES.FORM.MESSAGE.PIN.YES', intl),
                  unselect: getTranslation('MESSAGES.FORM.MESSAGE.PIN.NO', intl),
                }}
              />

              {!initialValues.isPinned ? (
                <>
                  <SwitchInline
                    columnLabel={true}
                    texts={{
                      select: getTranslation('MESSAGES.FORM.TERM.YES', intl),
                      unselect: getTranslation('MESSAGES.FORM.TERM.NO', intl),
                    }}
                    name={'long_term'}
                    value={initialValues.long_term}
                    label={getTranslation('MESSAGES.FORM.TERM', intl)}
                    formik={formik}
                    changeValue={changeValue}
                    readOnly={initialValues.readOnly}
                  />
                  {initialValues.long_term ? (
                    <>
                      <DateTimePickerDefault
                        name={'dateFrom'}
                        isInline={true}
                        value={initialValues.dateFrom}
                        required={initialValues.long_term}
                        label={getTranslation('MESSAGES.FORM.START_PUBLICATION', intl)}
                        formik={formik}
                        changeValue={changeValue}
                        displayFormat={DEFAULT_DATE_FORMAT}
                        readOnly={initialValues.readOnly}
                        disable={[
                          (date: any) => {
                            return moment(date) <= moment(new Date()).subtract(1, 'day')
                          },
                        ]}
                      />
                      {!initialValues.isPinned ? (
                        <DateTimePickerDefault
                          name={'dateTo'}
                          isInline={true}
                          disable={[
                            (date: any) => {
                              return (
                                moment(date) <= moment(initialValues.dateFrom).subtract(1, 'day')
                              )
                            },
                          ]}
                          value={initialValues.dateTo}
                          label={getTranslation('MESSAGES.FORM.END_PUBLICATION', intl)}
                          formik={formik}
                          changeValue={changeValue}
                          readOnly={initialValues.readOnly}
                        />
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}

              {initialValues.readOnly && !initialValues.isNew ? (
                <Owner
                  label={getTranslation('MESSAGES.FORM.OWNER', intl)}
                  user={initialValues.owner}
                />
              ) : null}
            </div>
            <div className='d-flex flex-column mb-8 fv-row fv-plugins-icon-container'>
              <SectionTitle>{getTranslation('MESSAGES.FORM.MESSAGE', intl)}</SectionTitle>
              <TextEditor
                name={'description'}
                key={initialValues.id}
                isEdit={!initialValues.readOnly}
                value={initialValues.description}
                action={changeValue}
                formik={formik}
                required={true}
              />
            </div>
            {!isFreeSVJ() ? (
              <div className='d-flex flex-column pt-8 mb-8 fv-row fv-plugins-icon-container'>
                <FileManager
                  key={initialValues.key}
                  availableFiles={true}
                  availableAttachments={true}
                  selectedFiles={files}
                  setFiles={setFiles}
                  selectedAttachments={attachments}
                  setAttachments={setAttachments}
                  readOnly={initialValues.readOnly}
                  hasCrudRole={hasCrudRole(MESSAGES_MODULE)}
                />
              </div>
            ) : null}

            {initialValues.readOnly && initialValues.acceptRead && !props.messageData ? (
              <div className={'mb-5 pt-2'}>
                <div className='mb-5 mt-8'>
                  <h2 className='fw-bold-custom text-dark border-bottom w-100'>
                    {getTranslation('CALENDAR.FORM.ACCEPT_READ.TITLE', intl)}
                  </h2>
                </div>
                <div>
                  <DefaultTableMui
                    pagination={false}
                    data={initialValues.allAccepts}
                    columns={acceptReadsColumns(intl)}
                    showColumnFilters={false}
                  />
                </div>
              </div>
            ) : null}
            {props.loadData ? null : (
              <div className={'w-100 border-top pt-5'}>
                <div className='d-inline-flex justify-content-between align-content-center w-100 page-buttons-area'>
                  <div className='left'>{renderLeftButtons()}</div>
                  <div className='right'>{renderRightButtons()}</div>
                </div>
              </div>
            )}
          </div>
        </form>
      </CardLayout>
    </div>
  )
})

export default MessageForm
