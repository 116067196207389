import {action, makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {createFormData, hasModule} from '../../Helpers/Default'
import {DOCUMENTS_MODULE} from '../../Constants/Modules'
import {IsDemo} from '../../Helpers/Demo'

class FolderStore {
  loading: boolean = false
  isCreating: boolean = false
  isSearching: boolean = false
  folders: any = []

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
    })
  }

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async getAll(parentId: string | null = null) {
    const res = await apiRequest(
      `/api/v2/folder${parentId ? '?parentId=' + parentId : ''}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this
    )

    if (res.status === 200) {
      this.setValue('folders', res.data)
      return res.data
    }
  }

  async delete(id: any) {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      '/api/v2/folder/' + id,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this
    )

    return res.status === 200
  }

  async create(data: any) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isCreating', true)
    const res = await apiRequest(
      '/api/v2/folder',
      REQ_TYPE.post,
      // @ts-ignore
      data,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    this.setValue('isCreating', false)

    return res.status === 200
  }

  async update(data: any) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isCreating', true)
    const res = await apiRequest(
      '/api/v2/folder/' + data.id,
      REQ_TYPE.put,
      // @ts-ignore
      data,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )
    this.setValue('isCreating', false)

    return res.status === 200
  }

  async get(id: string) {
    const res = await apiRequest(
      `/api/v2/folder/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    if (res.status === 200) {
      return res.data
    }
    return null
  }

  async createInsolvency(data: any) {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      '/api/v2/insolvency',
      REQ_TYPE.post,
      // @ts-ignore
      data,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    return res.status === 200
  }

  async getFolderTree() {
    const res = await apiRequest(
      `/api/v2/folder/tree`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      'login_request'
    )

    if (res.status === 200) {
      return res.data
    }
    return null
  }

  async search(text: string) {
    if (!hasModule(DOCUMENTS_MODULE)) {
      return []
    }
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/folder/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isSearching', false)
    return res.data ?? []
  }
}
export default new FolderStore()
