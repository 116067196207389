import clsx from 'clsx'
import React, {FC} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {useLayout, usePageData} from '../../../core'
import {TooltipDefault} from '../../../../../app/Components/Tooltip'
import {getTranslation} from '../../../../../app/Helpers/Default'
import UserStore from '../../../../../app/Stores/User'
import {useIntl} from 'react-intl'
import {SectionHelper} from '../../../../../app/Components/Buttons/Helper'
import {isEmpty} from 'lodash'
import {hasCommitteeRole} from '../../../../../app/Helpers/User'
import {observer} from 'mobx-react'
import {IsDemo} from '../../../../../app/Helpers/Demo'
const DefaultTitle: FC = observer(() => {
  const {
    pageTitle,
    pageDescription,
    pageBreadcrumbs,
    pageButtons,
    linkBack,
    helpLink,
    module,
    toFavorite,
  } = usePageData()
  const history = useHistory()
  const {config, classes} = useLayout()
  const intl = useIntl()

  const [isInFavorite, setIsInFavorite] = React.useState(
    UserStore?.user?.settings?.favorite_modules?.includes(module) || false
  )
  const handleFavorite = async () => {
    if (IsDemo()) return
    let type = 'favorite_add'
    const userSettings = UserStore.user.settings

    if (!userSettings.favorite_modules) {
      userSettings.favorite_modules = []
    }

    if (UserStore?.user?.settings?.favorite_modules?.includes(module)) {
      userSettings.favorite_modules = userSettings.favorite_modules.filter(
        (item: any) => item !== module
      )
      type = 'favorite_delete'
    } else {
      userSettings.favorite_modules.push(module)
    }

    if (
      await UserStore.updateSettings(
        {
          id: UserStore?.user?.id,
          settings: userSettings,
        },
        intl,
        type
      )
    ) {
      // setIsInFavorite(!isInFavorite)
    }
  }

  return (
    <div
      id='kt_page_title2'
      // data-kt-swapper='true'
      // data-kt-swapper-mode='prepend'
      // data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx(
        'page-title d-flex w-100 justify-content-between',
        classes.pageTitle.join(' ')
      )}
    >
      {/* begin::Title */}
      {pageTitle && (
        <div>
          <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
            {linkBack ? (
              <TooltipDefault tooltip={getTranslation('BASE.BACK', intl)} position='top'>
                <div
                  onClick={() => {
                    history.push(linkBack)
                  }}
                  className=' me-3'
                >
                  {/*<i className='bi bi-arrow-left-circle-fill'></i>*/}
                  <button className='btn btn-icon btn-active-color-primary text-dark btn-sm px-3'>
                    <i className='bi bi-chevron-left'></i>
                    {/*<i className='fa-solid fa-circle-left'></i>*/}
                    {/*<i className='bi bi-arrow-left-circle-fill'></i>*/}
                  </button>
                </div>
              </TooltipDefault>
            ) : null}
            <div className={'d-inline-flex justify-content-center align-items-center'}>
              {pageTitle}
              {toFavorite && module ? (
                <TooltipDefault
                  tooltip={getTranslation(
                    !UserStore?.user?.settings?.favorite_modules?.includes(module)
                      ? 'FAVORITE.ADD'
                      : 'FAVORITE.DELETE',
                    intl
                  )}
                >
                  <div
                    className={
                      'd-inline-flex justify-content-center align-items-center ms-3 cursor-pointer text-hover-warning'
                    }
                    onClick={handleFavorite}
                  >
                    <i
                      className={
                        UserStore?.user?.settings?.favorite_modules?.includes(module)
                          ? 'fas fa-star text-warning'
                          : 'fas fa-star'
                      }
                    ></i>
                  </div>
                </TooltipDefault>
              ) : null}
            </div>
            {pageDescription && config.pageTitle && config.pageTitle.description && (
              <>
                <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
                <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
              </>
            )}
          </h1>
        </div>
      )}
      {/* end::Title */}

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            {config.pageTitle.direction === 'row' && (
              <span className='h-20px border-gray-200 border-start mx-4'></span>
            )}
            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  key={index}
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                >
                  {!item.isSeparator ? (
                    <Link className='text-muted text-hover-primary' to={item.path}>
                      {item.title}
                    </Link>
                  ) : (
                    <span className='bullet bg-gray-200 w-5px h-2px'></span>
                  )}
                </li>
              ))}
              <li className='breadcrumb-item text-dark'>{pageTitle}</li>
            </ul>
          </>
        )}

      {pageButtons && pageButtons.length > 0 ? (
        <div>
          {!isEmpty(helpLink) && hasCommitteeRole() ? (
            <SectionHelper text={getTranslation('BASE.HELP', intl)} link={helpLink} />
          ) : null}
          {pageButtons}
        </div>
      ) : null}
    </div>
  )
})

export {DefaultTitle}
