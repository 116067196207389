import {makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {hasModule} from '../../Helpers/Default'
import {CONTACT_MODULE} from '../../Constants/Modules'
import {IsDemo} from '../../Helpers/Demo'

class ContactStore {
  constructor() {
    makeAutoObservable(this)
  }
  data: any = []
  pinContacts: any = []
  allCount = 0
  peopleCount = 0
  companyCount = 0
  isLoading = false
  isCreating = false
  isUpdating = false
  isDeleting = false
  isSearching = false

  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async getAll(type: string = 'all') {
    const res = await apiRequest(
      `/api/v2/contact/getAll?type=${type}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res.data && res.data.contacts) {
      this.setValue('data', res.data.contacts)
      this.setValue('allCount', res.data.allCount)
      this.setValue('peopleCount', res.data.peopleCount)
      this.setValue('companyCount', res.data.companyCount)
    }
    this.setValue('isLoading', false)
  }

  async getPin() {
    const res = await apiRequest(
      `/api/v2/contact/getPin`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res.data && res.data.contacts) {
      this.setValue('pinContacts', res.data.contacts)
    }
    this.setValue('isLoading', false)
  }

  async create(data: any) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isCreating', true)
    const res = await apiRequest(
      `/api/v2/contact`,
      REQ_TYPE.post,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isCreating', false)
    return res.status === 200
  }

  async update(data: any, id: string) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isUpdating', true)
    const res = await apiRequest(
      `/api/v2/contact/${id}`,
      REQ_TYPE.put,
      data,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isUpdating', false)
    return res.status === 200
  }

  async get(id: string) {
    this.setValue('isLoading', true)
    const res = await apiRequest(
      `/api/v2/contact/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res.data) {
      return res.data
    }
    this.setValue('isLoading', false)
  }

  async remove(id: string) {
    if (IsDemo()) {
      return false
    }
    this.setValue('isDeleting', true)
    const res = await apiRequest(
      `/api/v2/contact/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isDeleting', false)
    return res.status === 200
  }

  async search(text: string) {
    if (!hasModule(CONTACT_MODULE)) {
      return []
    }
    this.setValue('isSearching', true)
    const res = await apiRequest(
      `/api/v2/contact/search/${encodeURIComponent(text)}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    return res.data ?? []
  }
}
export default new ContactStore()
