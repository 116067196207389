import React from 'react'
import zxcvbn from 'zxcvbn'
import Translation from '../Translation'
import {observer} from 'mobx-react'
export const PasswordStrengthMeter = observer((props: any) => {
  const testResult = zxcvbn(props.password)
  const num = (testResult.score * 100) / 4

  const createPassLabel = () => {
    let label = ''
    switch (testResult.score) {
      case 0:
        label = 'BASE.PASSWORD.VERY_WEAK'
        break
      case 1:
        label = 'BASE.PASSWORD.WEAK'
        break
      case 2:
        label = 'BASE.PASSWORD.FEAR'
        break
      case 3:
        label = 'BASE.PASSWORD.GOOD'
        break
      case 4:
        label = 'BASE.PASSWORD.STRONG'
        break
      default:
        label = ''
        break
    }

    if (props.password === '' && testResult.score === 0) return null
    return <Translation text={label} />
  }

  const funcProgressColor = () => {
    switch (testResult.score) {
      case 0:
        return '#828282'
      case 1:
        return '#EA1111'
      case 2:
        return '#FFAD00'
      case 3:
        return '#9bc158'
      case 4:
        return '#00b500'
      default:
        return 'none'
    }
  }

  const changePasswordColor = () => ({
    width: `${num}%`,
    background: funcProgressColor(),
    height: '7px',
  })

  return (
    <>
      <div className='progress' style={{height: '7px'}}>
        <div className='progress-bar' style={changePasswordColor()}></div>
      </div>
      <div className={'form-text'} style={{color: funcProgressColor()}}>
        {createPassLabel()}
      </div>
    </>
  )
})
