import React from 'react'
import {
  REPORT_CATEGORY,
  REPORT_CATEGORY_TYPE,
  REPORT_CATEGORY_TYPE_MAP,
  REPORT_STATUS,
  REPORT_STATUS_TYPE,
  REPORT_STATUS_TYPE_MAP,
  REPORT_VISIBILITY_TYPE_MAP,
} from '../../Constants/Reports'
import {DefaultBadge} from '../../Components/Badge'
import {getTranslation} from '../Default'

export function renderStatusLabel(status: string, intl: any, type: string = 'light') {
  let colorClass = 'success'
  if (status === REPORT_STATUS_TYPE.solving) {
    colorClass = 'warning'
  } else if (status === REPORT_STATUS_TYPE.new) {
    colorClass = 'primary'
  } else if (status === REPORT_STATUS_TYPE.solved) {
    colorClass = 'success'
  } else if (status === REPORT_STATUS_TYPE.archive) {
    colorClass = 'dark'
  }

  return (
    <DefaultBadge color={colorClass} type={type}>
      {/*@ts-ignore*/}
      {getTranslation(REPORT_STATUS[status], intl)}
    </DefaultBadge>
  )
}

export function renderCategoryLabel(category: string, intl: any, type: string = 'light') {
  let colorClass = '-'
  if (category === REPORT_CATEGORY_TYPE.fault) {
    colorClass = 'warning'
  } else if (category === REPORT_CATEGORY_TYPE.complaint) {
    colorClass = 'info'
  } else if (category === REPORT_CATEGORY_TYPE.other) {
    colorClass = 'success'
  } else if (category === REPORT_CATEGORY_TYPE.repair) {
    colorClass = 'dark'
  } else if (category === REPORT_CATEGORY_TYPE.request) {
    colorClass = 'primary'
  }
  // @ts-ignore
  if (category && REPORT_CATEGORY[category]) {
    return (
      <DefaultBadge type={type} color={colorClass}>
        {/*@ts-ignore*/}
        {getTranslation(REPORT_CATEGORY[category], intl)}
      </DefaultBadge>
    )
  }
  return '-'
}

export const getVisibilityOptions = (intl: any) => {
  return [
    {
      value: false,
      translate: true,
      label: getTranslation(REPORT_VISIBILITY_TYPE_MAP.private.label, intl),
    },
    {
      value: true,
      translate: true,
      label: getTranslation(REPORT_VISIBILITY_TYPE_MAP.public.label, intl),
    },
  ]
}

export const getTypeOptions = () => {
  return [
    {
      value: REPORT_CATEGORY_TYPE_MAP.request.value,
      label: REPORT_CATEGORY_TYPE_MAP.request.label,
    },
    {
      value: REPORT_CATEGORY_TYPE_MAP.fault.value,
      label: REPORT_CATEGORY_TYPE_MAP.fault.label,
    },
    {
      value: REPORT_CATEGORY_TYPE_MAP.complaint.value,
      label: REPORT_CATEGORY_TYPE_MAP.complaint.label,
    },
    {
      value: REPORT_CATEGORY_TYPE_MAP.other.value,
      label: REPORT_CATEGORY_TYPE_MAP.other.label,
    },
    {
      value: REPORT_CATEGORY_TYPE_MAP.repair.value,
      label: REPORT_CATEGORY_TYPE_MAP.repair.label,
    },
  ]
}

export const getStatusOptions = () => {
  return [
    {
      value: REPORT_STATUS_TYPE_MAP.new.value,
      label: REPORT_STATUS_TYPE_MAP.new.label,
    },
    {
      value: REPORT_STATUS_TYPE_MAP.solving.value,
      label: REPORT_STATUS_TYPE_MAP.solving.label,
    },
    {
      value: REPORT_STATUS_TYPE_MAP.solved.value,
      label: REPORT_STATUS_TYPE_MAP.solved.label,
    },
    {
      value: REPORT_STATUS_TYPE_MAP.archive.value,
      label: REPORT_STATUS_TYPE_MAP.archive.label,
    },
  ]
}
