import {action, makeAutoObservable} from 'mobx'
import {IsDemo} from '../../Helpers/Demo'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'

class RequirementForApprovalStore {
  data = []
  isCreating = false
  isLoading = false
  isDeleting = false
  isUpdating = false
  allCount: number = 0
  approvalCount: number = 0
  rejectedCount: number = 0
  waitingCount: number = 0

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
      create: action,
      getAll: action,
      get: action,
    })
  }
  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }
  async create(data: any, attachments: any) {
    if (IsDemo()) {
      return false
    }
    if (this.isCreating) {
      return null
    }

    this.setValue('isCreating', true)

    const formData = new FormData()
    formData.append('data', JSON.stringify(data))

    attachments.forEach((attachment: any, index: any) => {
      formData.append(`files[${index}]`, attachment.file) // Přidání souboru
      formData.append(`names[${index}]`, attachment.name) // Přidání názvu
      formData.append(`extends[${index}]`, attachment.extend) // Přidání přípony
      formData.append(`descriptions[${index}]`, attachment.description) // Přidání popisu
      formData.append(`isPublic[${index}]`, JSON.stringify(attachment.isPublic)) // Přidání visibility
    })

    const res = await apiRequest(
      '/api/v2/requirement-for-approval',
      REQ_TYPE.post,
      // @ts-ignore
      formData,
      // @ts-ignore
      getHeaders(null, {'Content-Type': 'multipart/form-data'}),
      this,
      ''
    )

    this.setValue('isCreating', false)
    return res.status === 200
  }

  async getAll(tab: string) {
    const res = await apiRequest(
      `/api/v2/requirement-for-approval${tab ? `?tab=${tab}` : ''}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res && res.data) {
      this.setValue('allCount', res.data.allCount)
      this.setValue('approvalCount', res.data.approvedCount)
      this.setValue('rejectedCount', res.data.rejectedCount)
      this.setValue('waitingCount', res.data.waitingCount)
      this.setValue('data', res.data.data)
    }
  }

  async get(id: string) {
    const res = await apiRequest(
      `/api/v2/requirement-for-approval/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res && res.status === 200) {
      return res.data
    }
    return null
  }

  async addComment(id: any, comment: any) {
    if (IsDemo()) {
      return false
    }
    const res = await apiRequest(
      `/api/v2/requirement-for-approval/${id}/comment`,
      REQ_TYPE.post,
      // @ts-ignore
      {comment},
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    return res && res.status === 200
  }

  async delete(id: any) {
    if (IsDemo()) {
      return false
    }
    if (this.isDeleting) {
      return null
    }

    this.setValue('isDeleting', true)

    const res = await apiRequest(
      `/api/v2/requirement-for-approval/${id}`,
      REQ_TYPE.delete,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    this.setValue('isDeleting', false)
    return res.status === 200
  }

  async changeUserStatus(id: any, userId: any, status: any) {
    if (IsDemo()) {
      return false
    }

    if (this.isUpdating) {
      return null
    }

    this.setValue('isUpdating', true)

    const res = await apiRequest(
      `/api/v2/requirement-for-approval/${id}/status/user/${userId}`,
      REQ_TYPE.put,
      {status},
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    await this.getAll('all')
    this.setValue('isUpdating', false)

    return res && res.status === 200
  }
}
export default new RequirementForApprovalStore()
