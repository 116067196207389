import {showNotification} from '../Notification'
import {NOTIFICATION_TYPE} from '../../Constants/Notification'
import {getTranslation} from '../Default'
import {FormattedMessage} from 'react-intl'
import React from 'react'

export const IsDemo = () => {
  if (process.env.REACT_APP_DEMO === 'true') {
    showNotification(NOTIFICATION_TYPE.danger, 'DEMO.FUNCTIONALITY_DISABLED')
    return true
  }
  return false
}
