export const CONTACT_CATEGORY_MAP = {
  administrative: {
    value: 'administrative',
    label: 'CONTACT.CATEGORY.ADMINISTRATIVE',
  },
  banks_financial_services: {
    value: 'banks_financial_services',
    label: 'CONTACT.CATEGORY.BANKS_FINANCIAL_SERVICES',
  },
  energy: {
    value: 'energy',
    label: 'CONTACT.CATEGORY.ENERGY',
  },
  it: {
    value: 'it',
    label: 'CONTACT.CATEGORY.IT',
  },
  lawyer: {
    value: 'lawyer',
    label: 'CONTACT.CATEGORY.LAWYER',
  },
  svj: {
    value: 'svj',
    label: 'CONTACT.CATEGORY.SVJ',
  },
  real_estate_management: {
    value: 'real_estate_management',
    label: 'CONTACT.CATEGORY.REAL_ESTATE_MANAGEMENT',
  },
  construction_work: {
    value: 'construction_work',
    label: 'CONTACT.CATEGORY.CONSTRUCTION_WORK',
  },
  accounting_services: {
    value: 'accounting_services',
    label: 'CONTACT.CATEGORY.ACCOUNTING_SERVICES',
  },
  property_maintenance: {
    value: 'property_maintenance',
    label: 'CONTACT.CATEGORY.PROPERTY_MAINTENANCE',
  },
  plant_care: {
    value: 'plant_care',
    label: 'CONTACT.CATEGORY.PLANT_CARE',
  },
  authorities: {
    value: 'authorities',
    label: 'CONTACT.CATEGORY.AUTHORITIES',
  },
  other: {
    value: 'other',
    label: 'CONTACT.CATEGORY.OTHERS',
  },
}

export const CONTACT_TYPE_MAP = {
  person: {
    value: 'person',
    label: 'CONTACT.TYPE.PERSON',
  },
  company: {
    value: 'company',
    label: 'CONTACT.TYPE.COMPANY',
  },
}

export const CONTACT_VISIBILITY_MAP = [
  {value: 'public', label: 'CONTACT.SELECT.VISIBILITY.PUBLIC'},
  {value: 'private', label: 'CONTACT.SELECT.VISIBILITY.PRIVATE'},
]
